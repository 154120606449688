import {LaunchScreen} from 'layouts';
import React, {useEffect} from 'react';
import config from 'services/config';
import rootStore from 'stores';
import {parseSearchParams} from 'utils';

export default function SignOutPage() {
    useEffect(() => {
        const backLink = parseSearchParams(window.location.search).get('return') || config.app.homePage;
        let timer: number;
        if (!rootStore.authStore.isAuthenticated) {
            rootStore.routerStore.redirectBack(backLink);
        } else {
            (async () => {
                try {
                    await rootStore.authStore.signOut(backLink);
                } catch {
                    rootStore.routerStore.redirectBack(backLink);
                } finally {
                    timer = window.setTimeout(() => {
                        rootStore.routerStore.redirectBack(backLink);
                    }, 1000);
                }
            })();
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, []);
    return <LaunchScreen text="Signing out"/>;

}
