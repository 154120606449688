import convict, {Schema} from 'convict';
import {TSConvict} from 'ts-convict';
import env from './env';

interface TSConvictPub<T> {
    schema: Schema<T>;
    client: convict.Config<T>;
}

export class ConfigLoader<T> extends TSConvict<T> {
    constructor(ConfigClass: new (...args: Record<string, string>[]) => T) {
        super(ConfigClass);
        const self: TSConvictPub<T> = this as any;
        self.client = convict<T>(self.schema, {env: env});
    }
}

export function loadConfig<T>(ConfigClass: new (...args: any[]) => T): T {
    const loader = new ConfigLoader<T>(ConfigClass);
    return loader.load({});
}
