import {Box} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {LaunchScreen} from 'layouts';
import {useOperatorStore} from 'layouts/Dashboard/store/operatorStore';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {createLogger} from 'services/logger';
import {AgentsDashboard, DealsDashboard, Main, OperatorDashboard} from './Components';

const logger = createLogger({name: 'Dashboard'});

const Dashboard = observer(() => {
    const store = useOperatorStore();
    let {path} = useRouteMatch();
    useEffect(() => {
        logger.debug(path);
    }, [path]);

    if (store.error) {
        return (
            <Box padding={2}>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Opps, we have run in to an unexpected error {store.error.message}
                </Alert>
            </Box>
        );
    }

    if (store.isFetching) {
        return <LaunchScreen text="Loading company information"/>;
    }

    return (
        <Switch>
            <Route exact path={path}>
                <Main/>
            </Route>
            <Route path={`${path}/operators`} exact={true}>
                <OperatorDashboard/>
            </Route>
            <Route path={`${path}/operators/:operatorId/deals`}>
                <DealsDashboard/>
            </Route>
            <Route path={`${path}/agents/:agentId`}>
                <AgentsDashboard/>
            </Route>
        </Switch>
    );
});

export default Dashboard;
