import {TransformableInfo} from 'logform';
import * as winston from 'winston';
import TransportStream from 'winston-transport';

// enumeration to assign color values to
enum LevelColors {
    DEBUG = 'cyan',
    INFO = 'darkturquoise',
    WARN = 'khaki',
    ERROR = 'tomato',
}

const defaultColor = 'color: inherit';

interface LoggerOptions extends TransportStream.TransportStreamOptions {
    name?: string
}

//! Overriding winston console transporter
class Console extends TransportStream {
    name?: string;
    thing;

    constructor({name, ...options}: LoggerOptions) {
        super(options || {});
        this.name = name;
        this.setMaxListeners(30);
    }

    log(info: TransformableInfo, next: () => void) {
        const name = (this.name ? ` ${this.name}:` : '-');
        const color = LevelColors[info.level.toUpperCase() as keyof LevelColors];
        // styles a console log statement accordingly to the log level
        // log level colors are taken from levelcolors enum
        console.log(
            `%c[%c${info.level.toUpperCase()}%c]%c${name}`,
            defaultColor,
            `color: ${color};`,
            defaultColor,
            `color: ${color};`,
            // message will be included after stylings
            // through this objects and arrays will be expandable
            info.message
        );

        // must call the next function here
        // or otherwise you'll only be able to send one message
        next();
    }
}

export function createLogger(options: LoggerOptions) {
    const ops = options || {};
    if (!ops.level) {
        ops.level = 'debug';
    }
    return winston.createLogger({
        transports: [
            new Console(ops),
        ],
    });

}

// creating silent loggers with according levels
// silent by default to be automatically deactivated
// in production mode
export const logger = winston.createLogger({
    transports: [
        new Console({
            silent: false,
            level: 'debug',
        }),
    ],
});

// don't log anything in production mode
// probably should go further and return non
// working logger function to reduce
// execution time and improve speed results
// on application
if (process.env.NODE_ENV !== 'production') {
    logger.transports.forEach(transport => (transport.silent = false));
}
