import OperatorDetailsForm from 'components/OperatorDetailsForm';
import {useOperatorStore} from 'layouts/Dashboard/store/operatorStore';
import {Observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {FormSpy} from 'react-final-form';
import {OperatorDashboardState} from 'views/Dashboard/Components/OperatorDashboard/OperatorDashboard';

export const formId = 'operator-edit-form';

export default function EditOperatorFrom({state}: { state: OperatorDashboardState }) {
    const {refresh} = useOperatorStore();
    const onSubmit = useCallback(() => {
        state.closeModal();
        return refresh({force: true});
    }, [state, refresh]);

    return <Observer>{() => (<>
        <OperatorDetailsForm id={formId} onSubmit={onSubmit} operator={state.currentOperator}>
            <FormSpy
                subscription={{submitting: true, valid: true, submitError: true, submitFailed: true, hasValidationErrors: true, validating: true, dirty: true}}
                onChange={state.update}
            />
        </OperatorDetailsForm>
    </>)}</Observer>;
}
