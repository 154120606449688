import {Box, Fab} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import EmptyState from 'components/EmptyState/EmptyState';
import {ReactComponent as NotFoundIllustration} from 'illustrations/not-found.svg';
import {LaunchScreen, Minimal as MinimalLayout} from 'layouts';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {RouteProps} from 'react-router';
import {Route} from 'react-router-dom';
import {auth0} from 'services';
import {createLogger} from 'services/logger';
import rootStore from 'stores';

const logger = createLogger({name: 'RouteWithLayout'});

interface RouteWithLayoutProps extends RouteProps {
    layout: React.ComponentType<any>;
    isProtected?: boolean
}

const RouteWithLayout: React.FunctionComponent<RouteWithLayoutProps> = observer(({layout: Layout, component: Component, isProtected, ...rest}) => {
    const allowed = !isProtected || rootStore.authStore.isAuthenticated;
    const [showLoading, setShowLoading] = React.useState(false);
    useEffect(() => {
        if (!allowed) {
            logger.debug('Authenticated route - request login');
            const lock = auth0.lock;

            lock.show({});
            lock.on('signin submit', () => {
                logger.debug('Authenticated route - signing in');
                setShowLoading(false);
            });
            lock.on('authenticated', (authResult) => {
                setShowLoading(true);
                lock.hide();
                rootStore.authStore.setAuth0Result(authResult).then(() => {
                    setTimeout(() => {
                        logger.debug('Authenticated route - show page');
                        setShowLoading(false);
                    }, 1000);
                });
            });
        }
    }, [allowed]);
    logger.debug({
        isAuthenticated: rootStore.authStore.isAuthenticated,
        isProtected: isProtected,
        showLoading
    });

    return (
        <Route
            {...rest}
            render={matchProps => (
                allowed ? (
                    <Layout>
                        {Component && (<Component {...matchProps} />)}
                    </Layout>
                ) : (
                    <MinimalLayout>
                        {showLoading ? (
                            <LaunchScreen text="Signing in"/>
                        ) : (
                            <EmptyState
                                image={<NotFoundIllustration/>}
                                title="Unauthorised"
                                description="The page you’re trying to access requires you to be singed in"
                                button={
                                    <Fab variant="extended" color="primary" onClick={() => {
                                        auth0.lock.show({});
                                    }}>
                                        <Box clone mr={1}>
                                            <LockIcon/>
                                        </Box>
                                        Sign in
                                    </Fab>
                                }
                            />
                        )}
                    </MinimalLayout>
                )
            )}
        />
    );
});

export default RouteWithLayout;
