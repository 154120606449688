import {Mutator,} from 'final-form';
import React from 'react';
import {Form as ReactFinalForm, FormProps} from 'react-final-form';

class Mutators<FormValues extends Record<string, any>> {
    setFieldValue: Mutator<FormValues> = ([fieldName, value], state, tools) => {
        tools.changeValue(state, fieldName, () => value);
    };
    setError: Mutator<FormValues> = ([fieldName, error]: [string, string], state) => {
        const {fields} = state;
        const touched = !!error;
        const field = fields[fieldName];
        field.data = {...field.data};
        field.data.error = error;
        field.touched = touched;
    };
    setFieldState: Mutator<FormValues> = ([fieldName, dataProp, value]: [string, string, string], state, tools) => {
        if (state.fields[fieldName] && state.fields[fieldName][dataProp] !== value) {
            tools.setIn(state.fields[fieldName], dataProp, value);
        } else {
            console.log(`Skip state.fields[${fieldName}]?.data[${dataProp}] is ${value}`);
        }
    };
}

const mutators = new Mutators();
const Form: <FormValues = Record<string, any>>(props: FormProps<FormValues>) => React.ReactElement = <FormValues extends Record<string, any>>(props) => {
    return <ReactFinalForm mutators={mutators as Mutators<FormValues>}   {...props} />;
};

export default Form;
