import {Button, Card, CardActions, CardContent, Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import DealDiscountType from 'data/DealDiscountType';
import Image from 'material-ui-image';
import React from 'react';
import {DealDetails} from 'services/forms';
import {formatDealImageUrl} from 'utils/cdn';

const useStyles = makeStyles((theme) => {
    const badgeSize = 64;
    return ({
        root: {
            width: 380,
            minHeight: '300px',
            flexGrow: 0,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        avatar: {
            borderColor: theme.palette.grey[500],
            padding: theme.spacing(1),
            borderWidth: 1,
            borderStyle: 'solid'
        },
        cardActions: {
            marginTop: 'auto',
            alignItems: 'flex-end',
            display: 'flex',
            position: 'relative'
        },
        cardBadge: {
            marginTop: 'auto',
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            position: 'absolute',
            padding: theme.spacing(1),
            top: theme.spacing(2),
            right: theme.spacing(2),
            width: badgeSize,
            height: badgeSize,
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: 1,
            letterSpacing: 0
        },
        cardBadgeTypography: {
            textAlign: 'center',
            lineHeight: 1,
            letterSpacing: 0
        },
        addressTypography: {
            marginTop: theme.spacing(2)
        },
        titleNoImageBadge: {
            marginRight: badgeSize + theme.spacing(2),
            minHeight: badgeSize
        }
    });
});
export type DealInfoCardProps = {
    deal: DealDetails.Fields
    actions?: React.ReactNode,
    className: string,
};

function DealInfoCard({deal, actions, className}: DealInfoCardProps) {
    const classes = useStyles();
    const imageUrl = formatDealImageUrl(deal.previewUrl);
    const hasImage = Boolean(imageUrl);
    const hasBadge = Boolean(deal.discountType !== DealDiscountType.Other);
    return (
        <Card className={clsx(classes.root, className)}>
            {deal.discountType !== DealDiscountType.Other && (
                <Paper className={classes.cardBadge}>
                    <Typography className={classes.cardBadgeTypography} variant="h5">
                        {deal.discountType === DealDiscountType.AmountOff && '$'}{deal.discountAmount}{deal.discountType === DealDiscountType.PercentOff && '%'}
                        <br/>
                        OFF
                    </Typography>
                </Paper>
            )}
            {hasImage && (
                <Image
                    aspectRatio={1.777}
                    src={imageUrl}
                    disableSpinner={true}
                />
            )}
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={clsx(
                        {
                            [classes.titleNoImageBadge]: !hasImage && hasBadge,
                        },
                    )}
                >
                    {deal.title}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                    {deal.description}
                </Typography>
                {deal.address && deal.address.formatted && (
                    <Typography variant="caption" color="textSecondary" component="p" className={classes.addressTypography}>
                        {deal.address.formatted.split(',').map((part, i) => {
                            return <React.Fragment key={`${part}-${i}`}>{part}<br/></React.Fragment>;
                        })}
                    </Typography>
                )}

            </CardContent>
            {(actions || deal.detailsLink) && (
                <CardActions disableSpacing className={classes.cardActions}>
                    {deal.detailsLink && (<Button href={deal.detailsLink} target="_blank" size="small">Learn More</Button>)}
                    {actions}
                </CardActions>
            )}
        </Card>
    );
}

export default DealInfoCard;
