import {action} from 'mobx';

function getJsonItem(key): config.ConfigItem {
    const item = localStorage.getItem(key);
    try {
        if (item) {
            return JSON.parse(item);
        }
    } catch (e) {
        console.error('failed to parse config storage');
    }
    return {};
}

export default class StorageLayer implements config.IConfigLayer {
    name: string = '';
    key: string = '';
    priority: number = 0;
    configs: config.ConfigItem = {};

    constructor({name, key, priority = 0}: { name: string, key: string, priority: number }) {
        const result = new Proxy(this, this);
        result.name = name;
        result.key = key;
        result.priority = priority;
        result.init();
        return result;

    }

    get mutable() {
        return true;
    }

    public get(target: any, prop: string) {
        if (prop in this) {
            return this[prop];
        }
        return this.configs[prop];
    }

    @action
    init() {
        this.configs = getJsonItem(this.key);
    }

    save() {
        window.localStorage.setItem(this.key, JSON.stringify(this.configs));
    }

    getValue(name) {
        return this.configs[name];
    }

    @action
    setValue(name, value) {
        this.configs[name] = value;
        this.save();
    }
}
