import {Operator} from '@jucy-nasse/types';
import rootStore from 'stores';
import {rffHelper} from 'utils/index';
import * as Yup from 'yup';
import config from '../config';
import * as AddressForm from './Address';

export interface Fields {
    operator: {
        id?: string;
        owners?: string[];
        name: string;
        website: string;
        primaryContact: string;
        type: string;
        emailAddress: string;
        phoneNumber: string;
        address: AddressForm.Fields,
    },
    password: string;
    passwordConfirmation: string;
    captchaToken?: string;
}

export function mapFieldsToOperator(fields: Fields): Operator {
    return {
        ...fields.operator,
        owners: fields.operator.owners || [],
        address: AddressForm.mapFieldsToAddress(fields.operator.address),
    };
}

export function mapOperatorToFields(operator: Operator): Fields {
    return {
        ...initialValues,
        operator: {
            ...initialValues.operator,
            ...operator,
            address: operator ? operator.address : initialValues.operator.address
        },
    };
}

export const {initialValues, validate, required, schema} = rffHelper<Fields>({
    initialValues: {
        operator: {
            name: '',
            website: '',
            type: '',
            primaryContact: '',
            emailAddress: '',
            phoneNumber: '',
            address: AddressForm.initialValues,
        },
        password: '',
        passwordConfirmation: '',
        captchaToken: ''
    },
    schema: {
        operator: Yup.object().shape({
            name: Yup.string().required('Please provide your company name'),
            website: Yup.string().matches(
                /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Please enter a valid URL'
            ).required('Please provide your company website'),
            primaryContact: Yup.string().required('Please provide your name'),
            emailAddress: Yup.string().email('Please provide a valid address').required('Please provide your email address'),
            type: Yup.string().required('Please select your industry'),
            phoneNumber: Yup.string().required('Please provide your company phone number'),
            address: AddressForm.schema.required('Please provide your company address'),
        }).required(),
        password: rootStore.authStore.isAuthenticated ? Yup.string().notRequired() : Yup.string().min(6).required(),
        passwordConfirmation: rootStore.authStore.isAuthenticated ? Yup.string().notRequired() : Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'Password confirmation is not equal to password'
        ).min(6).required(),
        captchaToken: config.app.recaptchaKey ? Yup.string().required('You must complete reCaptcha validation').nullable() : Yup.string().notRequired(),
    }
});
