import {AccountDetails} from '@jucy-nasse/types';
import {computed, observable, reaction} from 'mobx';
import {rentalsApi} from 'services';
import config from 'services/config';
import RootStore from './RootStore';

const accountKeyProp = `${config.auth0.claimsNamespace}/accountKey`;

export default class JucyAgentsStore {
    rootStore: RootStore;
    @observable agentIds: string[] = [];
    @observable accountDetails: AccountDetails[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        reaction(() => rootStore.authStore.credentials?.userInfo, (userInfo) => {
            this.agentIds = userInfo && userInfo[accountKeyProp] ? [userInfo[accountKeyProp]] : [];
        });

        reaction(() => this.agentIds, async (agentIds) => {
            if (agentIds?.length) {
                this.accountDetails = await Promise.all(agentIds.map(id => rentalsApi.getAccountDetails(id)));
            }
        });

    }

    @computed get defaultAgentId() {
        return this.agentIds.length ? this.agentIds[0] : '';
    }

    @computed get defaultAgentDetails(): AccountDetails {
        return this.accountDetails.length ? this.accountDetails[0] : undefined;
    }

}
