import {AccountDetails} from '@jucy-nasse/types';
import config from 'services/config';
import urlJoin from 'url-join';

export class RentalsApi {
    postJson(path: string, init?: RequestInit) {
        return fetch(urlJoin(config.rentalsApi.baseUrl, path), {method: 'POST', ...init})
            .then(r => r.status === 200 ? r.json() : null);
    }

    getAccountDetails(accountId): Promise<AccountDetails> {
        return this.postJson(urlJoin('api/Account/', accountId, '/1.0/GetAccountDetails'))
            .then(r => r.Data);
    }
}

const rentalsApi = new RentalsApi();

export default rentalsApi;
