import {Link, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 'auto',
    }
}));

interface FooterProps extends React.HTMLAttributes<HTMLDivElement> {
}

const Footer: React.FunctionComponent<FooterProps> = ({className, ...rest}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Typography variant="caption" align="right" component="div">
                &copy;{' '}Jucy Group Limited. All rights reserved.{' '}
                <Link href="https://www.jucy.com/terms/">
                    T&amp;Cs
                </Link>{' '}
                •{' '}
                <Link href="https://www.jucy.com/privacy/">
                    Privacy policy
                </Link>
            </Typography>

        </div>
    );
};

export default Footer;
