import {Deal} from '@jucy-nasse/types';
import DealDiscountType from 'data/DealDiscountType';
import firebase from 'firebase';
import {words} from 'lodash';
import moment from 'moment';
import {resolveImageFile} from 'utils/Files';
import {rffHelper} from 'utils/index';
import * as Yup from 'yup';
import {StringSchema} from 'yup';
import * as AddressForm from './Address';

const wordLimit = 100;

function toDate(date?: moment.Moment | firebase.firestore.Timestamp | { toDate?: () => Date } | Date | string, ifNull = null): Date {
    if (date) {
        if (typeof date === 'string') {
            return moment(date).toDate();
        } else if (date instanceof Date) {
            return date;
        } else if (typeof date.toDate === 'function') {
            return date.toDate();
        }
    }
    return ifNull;
}

function toMoment(date?: moment.Moment | firebase.firestore.Timestamp | { toDate?: () => Date } | Date | string, ifNull = null): moment.Moment {
    if (date) {
        if (typeof date === 'string' || date instanceof Date) {
            return moment(date);
        } else if (moment.isMoment(date)) {
            return date;
        } else if (typeof date.toDate === 'function') {
            return moment(date.toDate());
        }
    }
    return ifNull;
}

function wordCount(input: string) {
    return words(input).length;
}

export interface Fields {
    id?: string;
    title: string;
    description: string;
    image?: File[];
    previewUrl?: string;
    discountType: DealDiscountType;
    discountAmount: number | null;
    detailsLink?: string;
    operatorId: string;
    address: AddressForm.Fields,
    expiryDate?: moment.Moment,
    dateCreated?: moment.Moment
    dateUpdated?: moment.Moment
}

export function mapFieldsToDeal(fields: Fields): Deal {
    return {
        id: fields.id || undefined,
        title: fields.title,
        description: fields.description,
        imageUrl: undefined,
        discountType: fields.discountType,
        discountAmount: fields.discountAmount,
        operatorId: fields.operatorId,
        detailsLink: fields.detailsLink,
        address: AddressForm.mapFieldsToAddress(fields.address),
        expiryDate: toDate(fields.expiryDate),
        dateCreated: toDate(fields.dateCreated),
        dateUpdated: toDate(fields.dateUpdated),
    };
}

export const {initialValues, validate, required, schema} = rffHelper<Fields>({
    initialValues: {
        title: '',
        description: '',
        discountType: DealDiscountType.AmountOff,
        discountAmount: null,
        operatorId: '',
        detailsLink: '',
        address: AddressForm.initialValues,
    },
    schema: {
        title: Yup.string().required('Please provide a title for your deal'),
        description: Yup.string().required('Please provide a description for your deal')
            .test('word-limit', 'Description must be 100 or fewer words', value => {
                return wordCount(value) <= wordLimit;
            }),
        image: Yup.mixed(),
        discountType: Yup.string().oneOf(Object.values(DealDiscountType)).required('Please provide a discount type') as any,
        discountAmount: Yup.number().nullable()
            .when('discountType', (discountType: string, schema: StringSchema) => discountType === 'other'
                ? schema
                : schema.required('Please provide a discount amount')
            ) as any,
        operatorId: Yup.string().notRequired(),
        detailsLink: Yup.string().notRequired(),
        address: Yup.object().shape({
            formatted: Yup.string(),
            streetNumber: Yup.string(),
            route: Yup.string(),
            locality: Yup.string(),
            administrativeAreaLevel1: Yup.string().nullable().notRequired(),
            country: Yup.string().nullable().notRequired(),
            postalCode: Yup.string(),
            placeId: Yup.string(),
            latLng: Yup.object().shape({
                lat: Yup.number().notRequired(),
                lng: Yup.number().notRequired()
            }).notRequired()
        }) as any
    }
});

export async function mapDealToFields(deal: Deal): Promise<Fields> {
    return deal ? {
        id: deal.id || undefined,
        title: deal.title,
        description: deal.description,
        image: deal.imageUrl && [await resolveImageFile(deal.imageUrl)],
        previewUrl: deal.imageUrl,
        discountType: deal.discountType,
        discountAmount: deal.discountAmount,
        detailsLink: deal.detailsLink,
        operatorId: deal.operatorId,
        address: AddressForm.mapFieldsToAddress(deal.address),
        expiryDate: toMoment(deal.expiryDate),
        dateCreated: toMoment(deal.dateCreated),
        dateUpdated: toMoment(deal.dateUpdated),
    } : initialValues;
}
