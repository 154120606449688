import {Operator} from '@jucy-nasse/types';
import {Box, Button, FormHelperText,} from '@material-ui/core';
import ButtonCircularProgress from 'components/ButtonCircularProgress/ButtonCircularProgress';
import OperatorDetailsForm from 'components/OperatorDetailsForm';
import {useOperatorStore} from 'layouts/Dashboard/store/operatorStore';
import {action, autorun, runInAction, toJS} from 'mobx';
import {observer, useLocalStore} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {FormSpy, FormSpyRenderProps} from 'react-final-form';
import rootStore from 'stores';
import {formId} from 'views/Dashboard/Components/OperatorDashboard/Components/EditOperatorFrom';
import {EditOperatorModal, OperatorDataTable} from './Components';

export interface OperatorDashboardState extends Partial<FormSpyRenderProps<Operator>> {
    currentOperator?: Operator;
    submitForm: () => void;
    modalOpen: boolean;
    update: (update: Partial<OperatorDashboardState>) => void;
    closeModal: () => void;
}

// function getOperatorsById(ids: string[]): Promise<Operator[]> {
//     return getMany(collection<Operator>('operators'), ids).then(d => d.map(d => d.data));
// }

const OperatorDashboard = observer(() => {
    const {operators, current} = useOperatorStore();
    const state = useLocalStore<OperatorDashboardState>(() => ({
        currentOperator: current,
        submitForm: () => undefined,
        modalOpen: false,
        submitting: false, valid: true, submitError: null, submitFailed: null, hasValidationErrors: false, validating: false, dirty: false,
        closeModal: action(() => {
            state.modalOpen = false;
        }),
        update: (update: Partial<OperatorDashboardState>) => {
            runInAction(() => {
                for (const [prop, value] of Object.entries(update)) {
                    if (state[prop] !== value) {
                        state[prop] = value;
                    }
                }
            });
        }
    }));
    useEffect(autorun(() => {
        state.currentOperator = current;
    }));
    return operators.length > 1 ? (
        <Box padding={0}>
            <OperatorDataTable state={state}/>
            <EditOperatorModal state={state}/>
        </Box>
    ) : (
        <Box padding={2}>
            <OperatorDetailsForm id={formId} onSubmit={() => {
                rootStore.notificationStore.success('Operator saved');
            }} operator={toJS(state.currentOperator)}>
                <FormSpy subscription={{submitting: true, valid: true, submitError: true, submitFailed: true, hasValidationErrors: true, validating: true}}>
                    {({submitting, valid, submitError, submitFailed, hasValidationErrors, validating}) => (<>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" color="primary" type="submit" disabled={submitting || validating || !valid}>
                                Save <ButtonCircularProgress isSubmitting={submitting}/>
                            </Button>
                        </Box>
                        {submitError && (
                            <FormHelperText error={true}>
                                Opps! We were unable to submit your request.<br/>
                                {submitError}
                            </FormHelperText>
                        )}
                        {(submitFailed && hasValidationErrors) && !submitting && (
                            <FormHelperText error={true}>
                                Opps! We were unable to submit your request, please correct any warnings above
                            </FormHelperText>
                        )}

                    </>)}
                </FormSpy>
            </OperatorDetailsForm>
        </Box>
    );

});

export default OperatorDashboard;
