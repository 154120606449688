import {Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UserAvatar from 'components/UserAvatar';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {runInAction} from 'mobx';
import React from 'react';
import {Link} from 'react-router-dom';
import rootStore from 'stores';

const useStyles = makeStyles((theme) => ({
    styledMenuItem: {
        '&:active': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}));
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export default function UserMenu() {
    const classes = useStyles();
    return (
        <PopupState variant="popover" popupId="user-menu">
            {(popupState) => (<>
                <IconButton color="inherit" {...bindTrigger(popupState)} >
                    <UserAvatar profile={rootStore.authStore.user}/>
                </IconButton>
                <StyledMenu {...bindMenu(popupState)} >
                    <MenuItem onClick={() => {
                        popupState.close();
                        runInAction(() => rootStore.appStateStore.profileOpen = true);
                    }} className={classes.styledMenuItem}>
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Account"/>
                    </MenuItem>
                    <Divider/>
                    <MenuItem component={Link} to="/sign-out" className={classes.styledMenuItem}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Sign out"/>
                    </MenuItem>
                </StyledMenu>
            </>)}
        </PopupState>
    );
}
