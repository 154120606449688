import {TextField} from '@material-ui/core';

import {TextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField';
import {AutocompleteProps as MuiAutocompleteProps, default as MuiAutocomplete, RenderInputParams as MuiAutocompleteRenderInputParams,} from '@material-ui/lab/Autocomplete';
import React, {ChangeEvent, ReactNode} from 'react';
import {Field, FieldProps, FieldRenderProps} from 'react-final-form';
import {showError} from 'utils/RFFUtils';

export type AutocompleteData = {
    [key: string]: any | null;
};

export interface AutocompleteProps extends Partial<Omit<MuiAutocompleteProps<any>, 'onChange'>> {
    name: string;
    label: ReactNode;
    helperText?: string;
    required?: boolean;
    multiple?: boolean;
    getOptionValue?: (option: any) => any;
    options: AutocompleteData[];
    fieldProps?: Partial<FieldProps<any, any>>;
    textFieldProps?: Partial<MuiTextFieldProps>;
    onBeforeChange?: (_e: ChangeEvent<{}>, values: any | any[]) => any | PromiseLike<any>;
}

export const Autocomplete = (props: AutocompleteProps) => {
    const {name, fieldProps, ...rest} = props;

    return (
        <Field
            name={name}
            render={(fieldRenderProps) => <AutocompleteWrapper {...fieldRenderProps} {...rest} />}
            {...fieldProps}
        />
    );
};

interface AutocompleteWrapperProps extends FieldRenderProps<MuiTextFieldProps, HTMLElement> {
    label: ReactNode;
    required?: boolean;
    multiple?: boolean;
    textFieldProps?: Partial<MuiTextFieldProps>;
    getOptionValue?: (option: any) => any;
    onBeforeChange?: (_e: ChangeEvent<{}>, values: any | any[]) => any | PromiseLike<any>;
}

const AutocompleteWrapper = (props: AutocompleteWrapperProps) => {
    const {
        input: {name, onChange, value, ...restInput},
        meta,
        options,
        label,
        required,
        multiple,
        textFieldProps,
        getOptionValue,
        onBeforeChange,
        ...rest
    } = props;

    function getValue(values: any) {
        if (!getOptionValue) {
            return values;
        }

        // ternary hell...
        return multiple ? (values ? values.map(getOptionValue) : null) : values ? getOptionValue(values) : null;
    }

    const {helperText, ...lessrest} = rest;
    const {variant, ...restTextFieldProps} = (textFieldProps as any) || {};

    // yuck...
    let defaultValue: any = null;

    if (!getOptionValue) {
        defaultValue = value;
    } else if (value !== null) {
        options.forEach((option: any) => {
            const optionValue = getOptionValue(option);
            if (multiple) {
                if (!defaultValue) defaultValue = [];
                (value as any).forEach((v: any) => {
                    if (v === optionValue) {
                        defaultValue.push(option);
                    }
                });
            } else {
                if (value === optionValue) {
                    defaultValue = option;
                }
            }
        });
    }

    const onChangeFunc = (_e: ChangeEvent<{}>, values: any | any[]) => {
        if (onBeforeChange) {
            const result = onBeforeChange(_e, values);
            if (result.then) {
                result.then(r => onChange(getValue(r)));
            } else {
                onChange(getValue(result));
            }
        } else {
            onChange(getValue(values));
        }
    };

    const {error, submitError} = meta;
    const isError = showError({meta});
    return (
        <MuiAutocomplete
            multiple={multiple}
            onChange={onChangeFunc}
            options={options}
            value={defaultValue || null}
            renderInput={(params: MuiAutocompleteRenderInputParams) => {
                return (
                    <TextField
                        label={label}
                        required={required}
                        fullWidth={true}
                        helperText={isError ? error || submitError : helperText}
                        error={isError}
                        name={name}
                        variant={variant}
                        {...params}
                        {...restTextFieldProps}
                        inputProps={{required, ...restInput, ...params.inputProps, ...(restTextFieldProps && restTextFieldProps.inputProps)}}
                    />
                );
            }}
            {...lessrest}
        />
    );
};
