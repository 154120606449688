import {RouteWithLayout} from 'components';
import {Minimal as MinimalLayout} from 'layouts';
import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {NotFound as NotFoundView} from 'views';
import {Auth0SignOutHandler, SignOutPage} from 'views/SignOut/components';

function SignOut() {
    let {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <SignOutPage/>
            </Route>
            <Route path={`${path}/auth0/callback`}>
                <Auth0SignOutHandler/>
            </Route>
            <RouteWithLayout
                component={NotFoundView}
                layout={MinimalLayout}
            />
        </Switch>

    );
}

export default SignOut;
