import {faHandshake} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Operator} from '@jucy-nasse/types';
import {Box, Button, FormHelperText, Step, StepLabel, Stepper} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import ButtonCircularProgress from 'components/ButtonCircularProgress/ButtonCircularProgress';
import OperatorDetailsForm from 'components/OperatorDetailsForm';
import React from 'react';
import {FormSpy} from 'react-final-form';
import {Redirect} from 'react-router-dom';
// import cloudFunctions from 'services/CloudFunctions';
import rootStore from 'stores';
import IconTitle from './IconTitle';
import ThankYouPage from './ThankYouPage';

const useStyles = makeStyles((theme) => {
    return createStyles({
        stepper: {
            width: '100%'
        },
        panel: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },
    });
});

function getSteps() {
    return ['Your details', 'Your deals', 'All done!'];
}

type SignUpPageProps = {
    operator: Operator,
    setOperator: (operator: Operator) => void
};

export default function SignUpStepper({operator, setOperator}: SignUpPageProps) {
    const classes = useStyles();
    // const [operator, setCurrentOperator] = useState<Operator>(operator);
    const [activeStep, setActiveStep] = React.useState(operator ? 1 : 0);

    // useEffect(() => {
    //     if ((operator && !operator) || get(operator, 'id') !== get(operator, 'id')) {
    //         //         setCurrentOperator(operator);
    //     }
    // }, [operator]);

    const onNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
    };

    // const onBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //     window.scrollTo(0, 0);
    // };

    async function handlePartnerFormSubmit(operator: Operator) {
        rootStore.appStateStore.addBackgroundAction();
        setOperator(operator);
        onNext();
        rootStore.appStateStore.removeBackgroundAction();
    }

    // async function handleDealsFormSubmit() {
    //     rootStore.appStateStore.addBackgroundAction();
    //     await cloudFunctions.operatorSignupComplete(operator.id);
    //     onNext();
    //     rootStore.appStateStore.removeBackgroundAction();
    // }

    const steps = getSteps();
    return <>
        {(activeStep !== 2) && (<>
            <IconTitle icon={<FontAwesomeIcon icon={faHandshake}/>} title="Join the JUCY network" subtitle="Connecting adventurers with great local deals!"/>
            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </>)}
        {(activeStep === 0) && (
            <OperatorDetailsForm className={classes.panel} onSubmit={handlePartnerFormSubmit} operator={operator}>
                <FormSpy subscription={{submitting: true, valid: true, submitError: true, submitFailed: true, hasValidationErrors: true, validating: true}}>
                    {({submitting, valid, submitError, submitFailed, hasValidationErrors, validating}) => (<>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" color="primary" type="submit" disabled={!submitError && (submitting || validating || !valid)}>
                                Next <ButtonCircularProgress isSubmitting={submitting}/>
                            </Button>
                        </Box>
                        {submitError && (
                            <FormHelperText error={true}>
                                Opps! We were unable to submit your request.<br/>
                                {submitError}
                            </FormHelperText>
                        )}
                        {(submitFailed && hasValidationErrors) && !submitting && (
                            <FormHelperText error={true}>
                                Opps! We were unable to submit your request, please correct any warnings above
                            </FormHelperText>
                        )}

                    </>)}
                </FormSpy>
            </OperatorDetailsForm>
        )}
        {(activeStep === 1) && (
            <Redirect to="/dashboard"/>
            // <OperatorDealGrid operatorId={operator.id} onSubmit={handleDealsFormSubmit}>
            //     <FormSpy subscription={{submitting: true, valid: true}}>
            //         {({submitting, valid}) => (
            //             <Box display="flex" justifyContent="space-between">
            //                 <Button onClick={onBack}>
            //                     Back
            //                 </Button>
            //                 <Button variant="contained" color="primary" type="submit" disabled={submitting || !valid}>
            //                     Next <ButtonCircularProgress isSubmitting={submitting}/>
            //                 </Button>
            //             </Box>
            //         )}
            //     </FormSpy>
            // </OperatorDealGrid>
        )}
        {(activeStep === 2) && (
            <ThankYouPage/>
        )}
    </>;
}
