import {makeStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import React from 'react';
import {DealDetails} from 'services/forms';

const useStyles = makeStyles((theme) => ({
    dealSpeedDial: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing(1),
    }
}));

export interface DealEditActionsProps {
    onEditClick: (deal: DealDetails.Fields, index: number) => void;
    onDeleteClick: (deal: DealDetails.Fields, index: number) => void;
    deal: DealDetails.Fields,
    index: number
}

export default function DealEditActions({onEditClick, onDeleteClick, deal, index}: DealEditActionsProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return <SpeedDial
        FabProps={{
            size: 'small'
        }}
        ariaLabel="Edit deal"
        className={classes.dealSpeedDial}
        icon={<SpeedDialIcon openIcon={<EditIcon/>} icon={<MoreVertIcon/>}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
    >
        <SpeedDialAction
            icon={<DeleteIcon/>}
            tooltipTitle="Delete"
            onClick={() => {
                handleClose();
                onDeleteClick(deal, index);
            }}
            title=""

        />
        <SpeedDialAction
            icon={<EditIcon/>}
            tooltipTitle="Edit"
            onClick={() => {
                handleClose();
                onEditClick(deal, index);
            }}
            title=""
        />
    </SpeedDial>;
}
