import {createStyles, makeStyles,} from '@material-ui/core/styles';
import clsx from 'clsx';
import React, {useEffect, useRef, useState} from 'react';
import {auth0} from 'services';

const useStyles = makeStyles(() => {
    return createStyles({
        background: {
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
        },
        backgroundImage: {
            backgroundImage: 'url(https://content.jucy.com/assets/Uploads/background.jpg?vid=2)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
        },
        backgroundOverlay: {
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.55)',
        },
        outer: {
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& a': {
                color: 'purple'
            }
        }
    });
});

const SignInPageAuth0 = () => {
    const containerRef = useRef();
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const [email, setEmail] = useState('');
    useEffect(() => {
        if (containerRef && containerRef.current) {
            const observer = new MutationObserver((mutations) => {
                let input: HTMLInputElement;
                mutations.filter(m => m.addedNodes && m.addedNodes.length && m.addedNodes[0]).some((mutation) => {
                    return Array.from(mutation.addedNodes).filter((node: HTMLElement) => node.classList).some((node: HTMLElement) => {
                        input = node.querySelector('.auth0-lock-input');
                        if (input) {
                            observer.disconnect();
                            return true;
                        }
                        return false;
                    });
                });
                if (input) {
                    input.addEventListener('keyup', (e) => {
                        setEmail((e.target as HTMLInputElement).value);
                    });
                }
            });
            observer.observe(containerRef.current, {childList: true, subtree: true, attributes: false, characterData: false});
            const lock = auth0.createLock({container: 'sign-in-container'});
            lock.show({
                auth: {
                    redirect: true,
                },
                allowLogin: false,
                allowSignUp: false,
                initialScreen: 'forgotPassword',
                languageDictionary: {
                    emailInputPlaceholder: 'user@jucyworld.com',
                    success: {
                        forgotPassword: 'Thanks! Check your inbox for instructions from us on how to set your account password.',
                    },
                    forgotPasswordInstructions: 'Please enter your email address. We will send you an email to set your password.<br/><hr/>Need help? <a title="marketing@jucyworld.com" href="mailto:marketing@jucyworld.com?subject=Jucy%20Deals%20Support%20Request">Email us</a></span>',
                    forgotPasswordTitle: 'Set your account password'
                },
            });
        }
    }, [containerRef]);
    return (
        <div>
            <div className={classes.background} role="presentation">
                <div className={clsx(classes.backgroundImage, 'animated fadeIn')}></div>
                <div className={classes.backgroundOverlay}></div>
            </div>
            <div className={classes.outer}>
                <div ref={containerRef} id="sign-in-container"/>
            </div>
        </div>
    );
};

export default SignInPageAuth0;
