import {UserProfile} from '@jucy-nasse/types';
import {resolveImageFile} from 'utils/Files';
import * as Yup from 'yup';
import {rffHelper} from '../../utils';

export interface Fields extends Omit<UserProfile, 'emailVerified' | 'initials' | 'name' | 'displayName' | 'toAuthUser0' | 'lastUpdated' | 'lastPasswordChange' | 'lastSignIn'> {
    // picture: File,
    pictureFile: File,
    password: string,
    passwordConfirmation: string,
}

export const {initialValues, validate, required, schema, labels, getLabel} = rffHelper<Fields>({
    initialValues: {
        firstName: '',
        lastName: '',
        nickname: '',
        picture: '',
        pictureFile: null,
        password: '',
        passwordConfirmation: '',
        userId: '',
        email: '',
        theme: {
            primaryColor: '#860171',
            secondaryColor: '#84c430',
            dark: false,
        }
    },
    schema: {
        firstName: Yup.string().label('First name'),
        lastName: Yup.string().required('Last name is required').label('Last name'),
        nickname: Yup.string().optional().label('Display name'),
        picture: Yup.string().optional().label('Picture'),
        pictureFile: Yup.mixed<File>().optional().label('Picture'),
        email: Yup.string().email().label('Email address'),
        userId: Yup.string().required().label('User Id'),
        password: Yup.string().trim().transform(value => value === '' ? undefined : value).optional().min(6).label('Password'),
        passwordConfirmation: Yup.string().trim().transform(value => value === '' ? undefined : value).optional().oneOf(
            [Yup.ref('password'), undefined],
            'Password confirmation is not equal to password'
        ).min(6).label('Password confirmation'),
        theme: Yup.object().shape({
            primaryColor: Yup.string().optional(),
            secondaryColor: Yup.string().optional(),
            dark: Yup.boolean().optional(),
        }).optional().label('Theme'),
    }
});

export async function mapUserProfileToFields(profile: UserProfile): Promise<Fields> {
    return {
        firstName: profile.firstName,
        lastName: profile.lastName,
        nickname: profile.nickname,
        picture: profile.picture,
        pictureFile: profile.picture && await resolveImageFile(profile.picture),
        password: '',
        passwordConfirmation: '',
        userId: profile.userId,
        email: profile.email,
        theme: {
            primaryColor: profile.theme.primaryColor,
            secondaryColor: profile.theme.secondaryColor,
            dark: profile.theme.dark,
        }
    };
}

// export async function labelFor(fields: Fields): Promise<Partial<UserProfile>> {
//     return {
//         firstName: fields.firstName,
//         lastName: fields.lastName,
//         nickname: fields.nickname,
//         picture: fields.picture,
//         userId: fields.userId,
//         email: fields.email,
//         theme: {
//             primaryColor: fields.theme.primaryColor,
//             secondaryColor: fields.theme.secondaryColor,
//             dark: fields.theme.dark,
//         }
//     };
// }
export async function mapFieldsToUserProfile(fields: Fields): Promise<Partial<UserProfile>> {
    return {
        firstName: fields.firstName,
        lastName: fields.lastName,
        nickname: fields.nickname,
        picture: fields.picture,
        userId: fields.userId,
        email: fields.email,
        theme: {
            primaryColor: fields.theme.primaryColor,
            secondaryColor: fields.theme.secondaryColor,
            dark: fields.theme.dark,
        }
    };
}
