import * as Yup from 'yup';
import {rffHelper} from '../../utils';

export interface Fields {
    firstName: string,
    lastName: string,
    username: string,
    emailAddress: string,
    emailAddressConfirmation: string,
    password: string,
    passwordConfirmation: string,
}

export const {initialValues, validate, required, schema} = rffHelper<Fields>({
    initialValues: {
        firstName: '',
        lastName: '',
        username: '',
        emailAddress: '',
        emailAddressConfirmation: '',
        password: '',
        passwordConfirmation: '',
    },
    schema: {
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        username: Yup.string().required(),
        emailAddress: Yup.string().email().required(),
        emailAddressConfirmation: Yup.string().oneOf(
            [Yup.ref('emailAddress'), undefined],
            'E-mail address confirmation is not equal to e-mail address'
        ).email().required(),
        password: Yup.string().min(6).required(),
        passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'Password confirmation is not equal to password'
        ).min(6).required()
    }
});
