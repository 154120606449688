import {Box, Button, CircularProgress, Container as MuiContainer, Grid, Link, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {ButtonCircularProgress} from 'components';
import {FORM_ERROR} from 'final-form';
import {observer} from 'mobx-react-lite';
import {TextField,} from 'mui-rff';
import {useSnackbar} from 'notistack';
import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import {Link as RouterLink} from 'react-router-dom';
import {SignIn} from 'services/forms';
import {Actions} from 'services/forms/SignIn';
import rootStore from 'stores';
import {parseSearchParams} from 'utils';

const useFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(4),
        },
        paperInner: {
            marginLeft: theme.spacing(3),
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        buttons: {
            '& + button': {
                marginLeft: theme.spacing(3),
            }
        },
        divider: {
            margin: 'auto',
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        providerColumn: {
            order: 1,
            [theme.breakpoints.down('xs')]: {
                order: 3,
            }
        },
        dividerColumn: {
            order: 2,
        },
        formColumn: {
            order: 3,
            [theme.breakpoints.down('xs')]: {
                order: 1,
            }
        },
    }),
);

function Container({title, children}: { title: string, children: ReactNode }) {
    const classes = useFormStyles();
    return (
        <MuiContainer maxWidth="sm" disableGutters className={classes.container}>
            <Typography variant="h5" component="h6" gutterBottom={true}>{title}</Typography>
            {children}
        </MuiContainer>
    );
}

const SignInPage = observer(function SignInPageFormComponent() {
    const {enqueueSnackbar} = useSnackbar();
    const classes = useFormStyles();
    const [submittedValues, setSubmittedValues] = useState<SignIn.Fields | undefined>(undefined);
    const [showSignEmailSent, setShowSignEmailSent] = useState(false);
    const [showSigningIn, setShowSigningIn] = useState(false);
    const [signingInError, setSigningInError] = useState<Error>();
    useEffect(() => {
        const params = parseSearchParams(window.location.search);
        const email = params && params.get('email');
        if (email) {
            setSubmittedValues({
                ...SignIn.initialValues,
                emailAddress: email,
            });
        }
    }, []);
    // useEffect(() => {
    //     const doLoginWithEmailLink = async () => {
    //         let emailAddress = localStorage.getItem('emailAddress');
    //         if (!emailAddress) {
    //             rootStore.routerStore.history.replace('/sign-in');
    //             return;
    //         }
    //         setShowSigningIn(true);
    //         try {
    //             const user = await authentication.signInWithEmailLink(emailAddress, emailLink);
    //             await Looper(async () => (rootStore.authStore.user && rootStore.authStore.user.email === user.email));
    //             enqueueSnackbar(`Signed in as ${user.displayName || user.email}`, {variant: 'success'});
    //             rootStore.routerStore.redirectBack('/dashboard');
    //         } catch (e) {
    //             setSigningInError(e);
    //             setShowSigningIn(false);
    //         }
    //     };
    //     const emailLink = window.location.href;
    //     if (!showSigningIn && !rootStore.authStore.isAuthenticated && emailLink && auth.isSignInWithEmailLink(emailLink)) {
    //         doLoginWithEmailLink();
    //     }
    // }, [enqueueSnackbar, showSigningIn]);

    const sendSignInLinkToEmail = useCallback(async ({emailAddress}: SignIn.Fields) => {
        try {
            await rootStore.authStore.passwordlessLogin(emailAddress);
            setShowSignEmailSent(true);
            enqueueSnackbar(`Sent sign-in e-mail to ${emailAddress}`, {variant: 'success'});
        } catch (e) {
            return {[FORM_ERROR]: e.message};
        }
    }, [enqueueSnackbar]);

    const onSubmit = useCallback(async (values: SignIn.Fields) => {
        setSubmittedValues(values);
        return await sendSignInLinkToEmail(values);
    }, [sendSignInLinkToEmail]);

    const handleTryAgainClick = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        rootStore.routerStore.history.replace('/sign-in');
        setSubmittedValues(undefined);
        setShowSignEmailSent(false);
        setShowSigningIn(false);
        setSigningInError(undefined);
    }, []);

    if (showSigningIn) {
        return (
            <Container title="Signing in">
                {signingInError ? (<>
                    <Alert severity="error">{signingInError.message}</Alert>
                    <Link href="#" onClick={handleTryAgainClick}>Try again</Link>
                </>) : (<>
                    <Box display="flex" justifyContent="center">
                        <CircularProgress/>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        Signing in to your account...
                    </Box>
                </>)}
            </Container>
        );
    }
    if (showSignEmailSent) {
        return (
            <Container title="Check your email!">
                We’ve emailed a special link to {submittedValues?.emailAddress}. Click the link to sign in.<br/>
                Wrong email? Please <Link href="#" onClick={handleTryAgainClick}> re-enter your address </Link>.
            </Container>
        );
    }

    if (rootStore.authStore.isAuthenticated) {
        return (
            <Container title="Sign in to your account">
                <Alert severity="warning">
                    <AlertTitle>Already signed in</AlertTitle>
                    <Typography>
                        You are currently sign in as <strong>{rootStore.authStore.user!.email}</strong><br/>
                        <Link component={RouterLink} to={`/sign-out?return=${window.location.pathname}`}>Sign out</Link> to sign in using a different account,
                        or continue to the <Link component={RouterLink} to="/home">home page</Link>
                    </Typography>
                </Alert>
            </Container>
        );
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={submittedValues ? submittedValues : {...SignIn.initialValues, action: Actions.sendSignInLink}}
            validate={SignIn.validate}
            subscription={{submitting: true, submitError: true}}
            render={({handleSubmit, submitting, form, submitError}) => (
                <form onSubmit={handleSubmit} noValidate={true}>
                    <Container title="Sign in to your account">
                        <Grid container direction="row">
                            <Grid className={classes.formColumn} item xs={12} sm={12}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <TextField
                                            autoComplete="email"
                                            fullWidth={true}
                                            disabled={submitting}
                                            label="E-mail address"
                                            placeholder="john@doe.com"
                                            required={SignIn.required.emailAddress}
                                            name="emailAddress"
                                            type="email"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    {submitError && <Grid item xs><Alert severity="error">{submitError}</Alert></Grid>}
                                    <Grid item xs className={classes.actions}>
                                        <Button
                                            className={classes.buttons}
                                            disabled={submitting}
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                        >
                                            Send sign-in link
                                            <ButtonCircularProgress isSubmitting={true}/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            )}
        />
    );
});

export default SignInPage;
