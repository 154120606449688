import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import 'regenerator-runtime/runtime';
import 'typeface-roboto';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import rootStore from './stores';

if (process.env.NODE_ENV !== 'development') {
    const Sentry = require('@sentry/browser');
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    });
}
ReactDOM.render(<App rootStore={rootStore}/>, document.getElementById('root'));
serviceWorker.unregister();
