import {UserProfile} from '@jucy-nasse/types';

import {DialogContent, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField, Tooltip,} from '@material-ui/core';

import {Edit as EditIcon, Lock as LockIcon} from '@material-ui/icons';
import moment from 'moment';
import React, {Component} from 'react';
import {Profile} from 'services/forms';
import rootStore from 'stores';
import * as Yup from 'yup';
import {ValidationError} from 'yup';

interface SecurityTabProps {
    profile: UserProfile;
}

interface SecurityTabState {
    showingField: string,
    password: string,
    passwordConfirmation: string,
    performingAction: boolean,
    errors?: {
        password?: string[],
        passwordConfirmation?: string[],
    }
}

class SecurityTab extends Component<SecurityTabProps, SecurityTabState> {
    state: Readonly<SecurityTabState> = {
        showingField: '',
        password: '',
        passwordConfirmation: '',
        performingAction: false,
        errors: null,
    };

    showField = (fieldId) => {
        if (!fieldId) {
            return;
        }

        this.setState({
            showingField: fieldId,
        });
    };

    hideFields = (callback?) => {
        this.setState(
            {
                showingField: '',
                password: '',
                passwordConfirmation: '',
                errors: null,
            },
            () => {
                if (callback && typeof callback === 'function') {
                    callback();
                }
            }
        );
    };

    changeField = (fieldId) => {
        const {password, errors} = this.state;
        switch (fieldId) {
            case 'password':
                try {
                    this.validate({password});
                } catch (e) {
                    this.setState({
                        errors: {...errors, password: (e as ValidationError).errors},
                    });
                    return;
                }

                this.setState(
                    {
                        errors: null,
                    },
                    () => {
                        this.showField('password-confirmation');
                    }
                );
                return;

            case 'password-confirmation':
                this.changePassword();
                return;

            default:
                return;
        }
    };

    validate = (values) => {
        const schema = Yup.object().shape({
            password: Profile.schema.fields.password,
            passwordConfirmation: Profile.schema.fields.passwordConfirmation,
        });
        schema.validateSync(values);

    };
    changePassword = () => {
        const {password, passwordConfirmation, errors} = this.state;
        try {
            this.validate({password, passwordConfirmation});
        } catch (e) {
            this.setState({
                errors: {...errors, passwordConfirmation: (e as ValidationError).errors},
            });
            console.error(errors);
            return;
        }

        this.setState(
            {
                errors: null,
            },
            () => {
                this.setState(
                    {
                        performingAction: true,
                    },
                    () => {
                        rootStore.authStore
                            .changePassword({password, confirmPassword: passwordConfirmation})
                            .then(() => {
                                this.hideFields(() => {
                                    rootStore.notificationStore.info('Changed password');
                                });
                            })
                            .catch((reason) => {
                                const code = reason.code;
                                const message = reason.message;

                                switch (code) {
                                    default:
                                        rootStore.notificationStore.error(message);
                                        return;
                                }
                            })
                            .finally(() => {
                                this.setState({
                                    performingAction: false,
                                });
                            });
                    }
                );
            }
        );
    };

    handleKeyDown = (event, fieldId) => {
        if (!event || !fieldId) {
            return;
        }

        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
            return;
        }

        const key = event.key;

        if (!key) {
            return;
        }

        if (key === 'Escape') {
            this.hideFields();
        } else if (key === 'Enter') {
            this.changeField(fieldId);
        }
    };

    handlePasswordChange = (event) => {
        if (!event) {
            return;
        }

        const password = event.target.value;

        this.setState({
            password: password,
        });
    };

    handlePasswordConfirmationChange = (event) => {
        if (!event) {
            return;
        }

        const passwordConfirmation = event.target.value;

        this.setState({
            passwordConfirmation: passwordConfirmation,
        });
    };

    render() {
        const {profile} = this.props;

        const {
            showingField,
            password,
            passwordConfirmation,
            performingAction,
            errors,
        } = this.state;

        const hasChangedPassword = profile && profile.lastPasswordChange;

        return (
            <DialogContent>
                <List disablePadding>
                    <ListItem>
                        <Hidden xsDown>
                            <ListItemIcon>
                                <LockIcon/>
                            </ListItemIcon>
                        </Hidden>

                        {showingField === 'password' && (
                            <TextField
                                autoComplete="new-password"
                                autoFocus
                                disabled={performingAction}
                                error={!!(errors && errors.password)}
                                fullWidth
                                helperText={
                                    errors && errors.password
                                        ? errors.password[0]
                                        : 'Press Enter to change your password'
                                }
                                label="Password"
                                required
                                type="password"
                                value={password}
                                variant="filled"
                                InputLabelProps={{required: false}}
                                onBlur={this.hideFields}
                                onKeyDown={(event) => this.handleKeyDown(event, 'password')}
                                onChange={this.handlePasswordChange}
                                inputProps={{
                                    autoComplete: 'new-password'
                                }}
                            />
                        )}

                        {showingField === 'password-confirmation' && (
                            <TextField
                                autoComplete="new-password"
                                autoFocus
                                disabled={performingAction}
                                error={!!(errors && errors.passwordConfirmation)}
                                fullWidth
                                helperText={
                                    errors && errors.passwordConfirmation
                                        ? errors.passwordConfirmation[0]
                                        : 'Press Enter to change your password'
                                }
                                label="Password confirmation"
                                required
                                type="password"
                                value={passwordConfirmation}
                                variant="filled"
                                InputLabelProps={{required: false}}
                                onBlur={this.hideFields}
                                onKeyDown={(event) =>
                                    this.handleKeyDown(event, 'password-confirmation')
                                }
                                onChange={this.handlePasswordConfirmationChange}
                                inputProps={{
                                    autoComplete: 'new-password'
                                }}
                            />
                        )}

                        {showingField !== 'password' &&
                        showingField !== 'password-confirmation' && (
                            <>
                                <Hidden xsDown>
                                    <ListItemText
                                        primary="Password"
                                        secondary={
                                            hasChangedPassword
                                                ? `Last changed ${moment(profile.lastPasswordChange).format('LL')}`
                                                : 'Never changed'
                                        }
                                    />
                                </Hidden>

                                <Hidden smUp>
                                    <ListItemText
                                        primary="Password"
                                        secondary={
                                            hasChangedPassword
                                                ? `Last changed ${moment(profile.lastPasswordChange).format('ll')}`
                                                : 'Never changed'
                                        }
                                    />
                                </Hidden>

                                <ListItemSecondaryAction>
                                    <Tooltip title="Change">
                                        <div>
                                            <IconButton
                                                disabled={performingAction}
                                                onClick={() => this.showField('password')}
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </>
                        )}
                    </ListItem>
                </List>
            </DialogContent>
        );
    }
}

// SecurityTab.propTypes = {
//     // Properties
//     userData: PropTypes.object,
//
//     // Functions
//     openSnackbar: PropTypes.func.isRequired,
// };

export default SecurityTab;
