class LocalStorage {

    clear = (): void => localStorage.clear();

    /**
     * Returns the current value associated with the given key, or null if the given key does not exist in the list associated with the object.
     */
    // getItem(key: string): string | null;
    // eslint-disable-next-line no-dupe-class-members
    getItem<T>(key: string): T {
        let data: any = localStorage.getItem(key);
        let obj: T;

        if (data && (data.charAt(0) === '{' || data.charAt(0) === '[')) {
            try {
                obj = JSON.parse(data);
            } catch (error) {
                obj = null;
            }
        }

        return obj || data as T;
    }

    /**
     * Returns the name of the nth key in the list, or null if n is greater than or equal to the number of key/value pairs in the object.
     */
    key = (index: number): string | null => localStorage.key(index);
    /**
     * Removes the key/value pair with the given key from the list associated with the object, if a key/value pair with the given key exists.
     */
    removeItem = (key: string): void => localStorage.removeItem(key);

    /**
     * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
     *
     * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set. (Setting could fail if, e.g., the user has disabled storage for the site, or if the quota has been exceeded.)
     */
    // setItem(key: string, value: string): void;
    // eslint-disable-next-line no-dupe-class-members
    setItem<T>(key: string, item: T): void {
        return localStorage.setItem(key, typeof item === 'string' ? item : JSON.stringify(item));
    }

}

export default new LocalStorage();
