import {looper} from 'promise-timeout-and-looper';

const Looper = async <T>(callback: (tick?: number) => Promise<T>, ms: number = 500) => {
    let result: T = null;
    let tick = 0;
    await looper.loopStart({
        ms: ms,
        loop_tick_callback: async () => {
            result = await callback(tick);
            tick++;
            return !result;
        },
    });
    return result;
};

export default Looper;
