import {Box} from '@material-ui/core';
import {LaunchScreen} from 'layouts';
import React, {useEffect} from 'react';
import {createLogger} from 'services/logger';
import rootStore from 'stores';
import {parseSearchParams} from 'utils';

const logger = createLogger({name: 'SignInCallBack'});

const Auth0SignInHandler = () => {
    useEffect(() => {
        (async () => {
            logger.debug('Handler');
            const params = parseSearchParams(window.location.search);
            if (params.get('type') === 'passwordless') {
                logger.debug('passwordless');
                await rootStore.authStore.parseHash();
            } else {
                logger.debug('checkSession');
                await rootStore.authStore.checkSession();
            }
            if (rootStore.authStore.isAuthenticated && rootStore.operatorStore.defaultOperatorId) {
                logger.debug(`redirect /dashboard/operators/${rootStore.operatorStore.defaultOperatorId}/deals`);
                rootStore.routerStore.history.replace(`/dashboard/operators/${rootStore.operatorStore.defaultOperatorId}/deals`);
            } else {
                logger.debug('redirectBack');
                rootStore.routerStore.redirectBack();
            }
        })();
    });
    return (
        <Box pt={5}>
            <LaunchScreen text="Signing in"/>
        </Box>
    );
};

export default Auth0SignInHandler;
