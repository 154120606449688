import {IconButton, PropTypes, Snackbar} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import NotchedOutlineContainer from 'components/NotchedOutlineContainer';
import {DropzoneAreaBase, DropzoneAreaBaseProps} from 'material-ui-dropzone';
import React, {useEffect, useState} from 'react';
import {useField, useForm} from 'react-final-form';
import {convertBytesToMbsOrKbs, readFile} from 'utils/Files';
import {showError} from 'utils/RFFUtils';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            minHeight: 100,
            border: 'none'
        },
        noFocus: {
            '&:focus': {
                outline: 'none'
            }
        },
        disabled: {
            color: theme.palette.text.disabled,
            cursor: 'default',
        },
        gridContainer: {
            paddingBottom: theme.spacing(2)
        },
        textContainer: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    }),
);

interface ImageUploadFieldProps extends Omit<DropzoneAreaBaseProps, 'fileObjects' | 'onDelete' | 'acceptedFiles'> {
    name: string
    label?: string
    margin?: PropTypes.Margin;
    helperText?: React.ReactNode;
    onChange?: (files: File[]) => void;
    disabled?: boolean
}

const acceptedFiles = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
export default function ImageUploadField({name, label, margin, helperText, onChange, disabled, ...props}: ImageUploadFieldProps) {
    const classes = useStyles();
    const {input, meta} = useField<File[]>(name);
    const form = useForm();
    const isError = showError({meta});
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const [fileObjects, setFileObjects] = useState([]);
    useEffect(() => {
        const resolveFileObjects = async () => {
            if (input.value && input.value.map) {
                setFileObjects(await Promise.all(input.value.map(async (file) => ({
                    file,
                    data: await readFile(file)
                }))));
            }
        };
        resolveFileObjects();
    }, [input.value]);
    return (<>
        <NotchedOutlineContainer className={classes.noFocus} disabled={disabled} label={label} margin={margin} error={isError} dense={true}
            helperText={isError ? meta.error || meta.data.error || meta.submitError : helperText}>
            <DropzoneAreaBase
                fileObjects={fileObjects}
                dropzoneProps={{disabled: disabled}}
                dropzoneClass={classes.noFocus}
                classes={{
                    root: clsx(classes.root, {[classes.disabled]: disabled}),
                    icon: clsx({[classes.disabled]: disabled}),
                    text: classes.textContainer
                }}
                previewGridProps={{
                    container: {
                        classes: {root: classes.gridContainer},
                        spacing: 2
                    }
                }}
                acceptedFiles={acceptedFiles}
                inputProps={{max: props.filesLimit}}
                onDelete={(fileObject, index) => {
                    if (input.value) {
                        const updatedFiles = input.value.slice(0, index).concat(input.value.slice(index + 1, input.value.length));
                        input.onChange(updatedFiles);
                        if (onChange) {
                            onChange(updatedFiles);
                        }
                    }
                }}
                onAdd={(fileObjects) => {
                    const numUploads = (input.value ? input.value.length : 0) + fileObjects.length;
                    if (props.filesLimit && numUploads > props.filesLimit) {
                        setMessage(`${numUploads} files exceeds maximum of ${props.filesLimit}`);
                        setOpen(true);
                    } else {
                        form.mutators.setError(name, null);
                        const updatedFiles = [...fileObjects.map(f => f.file), ...input.value];
                        input.onChange(updatedFiles);
                        if (onChange) {
                            onChange(updatedFiles);
                        }
                    }
                }}
                showAlerts={false}
                onDropRejected={(files: File[]) => {
                    const errors = [];
                    const numUploads = (files.length);
                    if (props.filesLimit && numUploads > props.filesLimit) {
                        errors.push(`${numUploads} files exceeds maximum of ${props.filesLimit}`);
                    } else {
                        files.forEach(rejectedFile => {
                            errors.push(`File ${rejectedFile.name} was rejected.`);
                            if (!acceptedFiles.includes(rejectedFile.type)) {
                                errors.push('File type not supported. ');
                            }
                            if (props.maxFileSize) {
                                if (rejectedFile.size > props.maxFileSize) {
                                    errors.push('File is too big. Size limit is ' + convertBytesToMbsOrKbs(props.maxFileSize) + '. ');
                                }
                            }
                        });
                    }
                    form.mutators.setError(name, errors);
                }}
                {...props}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                }
            >
                <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
                    {message}
                </Alert>
            </Snackbar>
        </NotchedOutlineContainer>

    </>);
}
