import {rffHelper} from 'utils/index';
import * as Yup from 'yup';

export enum Actions {
    signIn,
    sendSignInLink,
    resetPassword,
}

export interface Fields {
    emailAddress: string;
    password: string;
    action: Actions;
}

export const {initialValues, validate, required, schema} = rffHelper<Fields>({
    initialValues: {
        emailAddress: '',
        password: '',
        action: Actions.signIn
    },
    schema: {
        emailAddress: Yup.string().email('Invalid email address').required('Email address is required'),
        password: Yup.string().optional(),
        action: Yup.mixed<keyof typeof Actions>().required() as any,
    }
});
