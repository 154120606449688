import {UserProfile} from '@jucy-nasse/types';
import {DialogProps} from '@material-ui/core/Dialog/Dialog';
import {AccountSettingsTabs} from 'components/AccountSettingsModal/components';
import {AccountSettingsModalStoreProvider} from 'components/AccountSettingsModal/store/accountSettingsModalStore';
import TitledDialog from 'components/TitledDialog';
import React from 'react';

export interface EditDealModalProps extends Omit<DialogProps, 'onSubmit' | 'onClose'> {
    profile: UserProfile;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function AccountSettingsModal({profile, open, setOpen, ...props}: EditDealModalProps) {
    return (
        <AccountSettingsModalStoreProvider>
            <TitledDialog title="Settings" open={open} onClose={() => setOpen(false)} {...props}>
                <AccountSettingsTabs profile={profile}/>
            </TitledDialog>
        </AccountSettingsModalStoreProvider>
    );
}
