import {attempt, isError} from 'lodash';
import firebase, {storage} from 'services/firebase';

function resolveRef(pathOrRef: string | firebase.storage.Reference): firebase.storage.Reference {
    return typeof pathOrRef === 'string' ? storage.ref().child(pathOrRef) : pathOrRef;
}

async function fileExists(reference: firebase.storage.Reference): Promise<boolean>;
async function fileExists(path: string): Promise<boolean>;
async function fileExists(pathOrRef: string | firebase.storage.Reference): Promise<boolean> {
    const ref = resolveRef(pathOrRef);
    const result = await attempt(async () => await ref.getDownloadURL().catch(e => e));
    return !isError(result);
}

async function uploadFile(file: File, reference: firebase.storage.Reference): Promise<firebase.storage.Reference>;
async function uploadFile(file: File, path: string): Promise<firebase.storage.Reference>;
async function uploadFile(file: File, pathOrRef: string | firebase.storage.Reference): Promise<firebase.storage.Reference> {
    const ref = resolveRef(pathOrRef);
    await ref.put(file);
    return ref;
}

export {fileExists, uploadFile};
