import {Button, DialogActions, DialogContent,} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {ButtonCircularProgress} from 'components';
import TitledDialog from 'components/TitledDialog';
import {Observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import EditOperatorFrom, {formId} from 'views/Dashboard/Components/OperatorDashboard/Components/EditOperatorFrom';
import {OperatorDashboardState} from 'views/Dashboard/Components/OperatorDashboard/OperatorDashboard';

const useStyles = makeStyles(() =>
    createStyles({
        actions: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
    }),
);
export default function EditDealModal({state}: { state: OperatorDashboardState }) {
    const classes = useStyles();
    const submitForm = useCallback(() => {
        const formEl = document.getElementById(formId);
        formEl.dispatchEvent(new Event('submit', {cancelable: true}));
    }, []);
    return <Observer>{() => (<>
        <TitledDialog title={state.currentOperator?.id ? 'Edit operator' : 'Create operator'} open={state.modalOpen} onClose={state.closeModal}>
            <DialogContent dividers={true}>
                <EditOperatorFrom state={state}/>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={state.closeModal} color="default" disabled={state.submitting}>
                    {!state.dirty ? 'Close' : 'Cancel'}
                </Button>
                <Button color={'primary'} type="submit" onClick={submitForm} disabled={!state.dirty || state.submitting}>
                    {state.currentOperator?.id ? 'Update' : 'Save'}
                    <ButtonCircularProgress isSubmitting={state.submitting}/>
                </Button>
            </DialogActions>
        </TitledDialog>
    </>)}</Observer>;
}
