import {createStyles, makeStyles,} from '@material-ui/core/styles';
import clsx from 'clsx';
import React, {useEffect, useRef} from 'react';
import {auth0} from 'services';

const useStyles = makeStyles(() => {
    return createStyles({
        background: {
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
        },
        backgroundImage: {
            backgroundImage: 'url(https://content.jucy.com/assets/Uploads/background.jpg?vid=2)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
        },
        backgroundOverlay: {
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.55)',
        },
        outer: {
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    });
});

const SignInPageAuth0 = () => {
    const containerRef = useRef();
    const classes = useStyles();
    useEffect(() => {
        if (containerRef && containerRef.current) {
            const lock = auth0.createLock({container: 'sign-in-container'});
            lock.show({
                allowSignUp: true,
                initialScreen: 'signUp'
            });
        }
    }, [containerRef]);
    return (
        <div>
            <div className={classes.background} role="presentation">
                <div className={clsx(classes.backgroundImage, 'animated fadeIn')}></div>
                <div className={classes.backgroundOverlay}></div>
            </div>
            <div className={classes.outer}>
                <div ref={containerRef} id="sign-in-container"/>
            </div>
        </div>
    );
};

export default SignInPageAuth0;
