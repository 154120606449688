import FatalError from 'components/FatalError';
import {LaunchScreen} from 'layouts';
import {autorun} from 'mobx';
import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router';
import rootStore from 'stores';
import urljoin from 'url-join';

export default function Main() {
    let {url} = useRouteMatch();
    const [showNotFound, setShowNotFound] = useState(false);
    useEffect(() => autorun(() => {
        if (rootStore.operatorStore.defaultOperatorId) {
            rootStore.routerStore.history.replace(urljoin(url, `/operators/${rootStore.operatorStore.defaultOperatorId}/deals`));
        } else if (rootStore.agentsStore.defaultAgentId) {
            rootStore.routerStore.history.replace(urljoin(url, `/agents/${rootStore.agentsStore.defaultAgentId}`));
        } else {
            setShowNotFound(true);
        }
    }));

    return showNotFound ? (
        <FatalError title="Whoops" message="We were unable to find any companies for your account"/>
    ) : (
        <LaunchScreen text="Loading company details"/>
    );
}
