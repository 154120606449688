/// <reference types="@types/googlemaps" />

import GmapUtils from 'utils/GmapUtils';

export class Geocoder {
    private service: google.maps.Geocoder;

    constructor() {
        this.service = new google.maps.Geocoder();
    }

    geocodeLatLng(latLng: google.maps.LatLng | google.maps.LatLngLiteral): Promise<google.maps.GeocoderResult[]> {
        return this.geocode({location: latLng});
    }

    geocodePlaceId(placeId: string): Promise<google.maps.GeocoderResult[]> {
        return this.geocode({placeId: placeId});
    }

    geocode(latLng: google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult[]> {
        return new Promise((resolve, reject) => {
            this.service.geocode(latLng, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve(results);
                } else {
                    reject({
                        message: `Geocoder failed due to: ${status}`,
                        status,
                        results,
                    });
                }
            });
        });
    }
}

export class DirectionsService {
    private service: google.maps.DirectionsService;

    constructor() {
        this.service = new google.maps.DirectionsService();
    }

    route(request: google.maps.DirectionsRequest): Promise<google.maps.DirectionsResult> {
        return new Promise((resolve, reject) => {
            this.service.route(request, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    resolve(result);
                } else {
                    reject({
                        message: `Direction service failed due to: ${status}`,
                        status,
                        result,
                    });
                }
            });
        });
    }
}

export class AutocompleteService {
    private service: google.maps.places.AutocompleteService;

    constructor() {
        this.service = new google.maps.places.AutocompleteService();
    }

    getPlacePredictions(request: google.maps.places.AutocompletionRequest): Promise<google.maps.places.AutocompletePrediction[]> {
        return new Promise((resolve, reject) => {
            this.service.getPlacePredictions(request, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject({
                        message: `Get details failed due to: ${status}`,
                        status,
                        place,
                    });
                }
            });
        });
    }
}

export class PlacesService {
    private service: google.maps.places.PlacesService;

    constructor(map: HTMLDivElement | google.maps.Map) {
        this.service = new google.maps.places.PlacesService(map);
    }

    getDetailsById(placeId: string): Promise<google.maps.places.PlaceResult> {
        return this.getDetails({placeId: placeId});
    }

    findByString(query: string, locationBias?: google.maps.places.LocationBias, fields = ['ALL']): Promise<google.maps.places.PlaceResult[]> {
        return this.findPlaceFromQuery({query: query, locationBias, fields});
    }

    findByPhone(phone: string, locationBias?: google.maps.places.LocationBias, fields = ['ALL']): Promise<google.maps.places.PlaceResult[]> {
        return this.findPlaceFromPhoneNumber({phoneNumber: phone, locationBias, fields});
    }

    findByQuery(query: string, locationBias?: google.maps.places.LocationBias, fields = ['ALL']): Promise<google.maps.places.PlaceResult[]> {
        return this.findPlaceFromQuery({query: query, locationBias, fields});
    }

    getDetails(request: google.maps.places.PlaceDetailsRequest): Promise<google.maps.places.PlaceResult> {
        return new Promise((resolve, reject) => {
            this.service.getDetails(request, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject({
                        message: `Get details failed due to: ${status}`,
                        status,
                        place,
                    });
                }
            });
        });
    }

    findPlaceFromQuery(request: Partial<google.maps.places.FindPlaceFromQueryRequest>): Promise<google.maps.places.PlaceResult[]> {
        return new Promise((resolve, reject) => {
            this.service.findPlaceFromQuery(request as google.maps.places.FindPlaceFromQueryRequest, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject({
                        message: `Get details failed due to: ${status}`,
                        status,
                        place,
                    });
                }
            });
        });
    }

    findPlaceFromPhoneNumber(request: Partial<google.maps.places.FindPlaceFromPhoneNumberRequest>): Promise<google.maps.places.PlaceResult[]> {
        return new Promise((resolve, reject) => {
            this.service.findPlaceFromPhoneNumber(request as google.maps.places.FindPlaceFromPhoneNumberRequest, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject({
                        message: `Get details failed due to: ${status}`,
                        status,
                        place,
                    });
                }
            });
        });
    }

}

export class MapServiceInstance {
    map: google.maps.Map;
    geocoder: Geocoder;
    directionsService: DirectionsService;
    placesService: PlacesService;
    autocompleteService: AutocompleteService;

    init(map?: google.maps.Map) {
        this.map = map;
        this.geocoder = new Geocoder();
        this.directionsService = new DirectionsService();
        this.placesService = new PlacesService(map || document.createElement('div'));
        this.autocompleteService = new AutocompleteService();
        return this;
    }

    async findPlace(query: google.maps.LatLng | google.maps.LatLngLiteral | string | { latLng?: google.maps.LatLng, placeId?: string }, scope: 'city' | 'absolute' = 'city') {
        let placeId;
        if (typeof (query as google.maps.LatLng).lat === 'function' || typeof (query as google.maps.LatLng).lat === 'number' || (query as any).latLng) {
            let latLng = (query as any).latLng || query;
            const geoCode = await this.geocoder.geocodeLatLng(latLng);
            if (geoCode && geoCode.length && geoCode[0].place_id) {
                placeId = geoCode[0].place_id;
            }
        } else if (typeof query === 'string' || (query as any).placeId) {
            placeId = (query as any).placeId || query;
        }

        let result;
        if (placeId) {
            const place = await this.placesService.getDetailsById(placeId);
            const address = GmapUtils.placeToAddress(place);
            const searchString = `${address.city} ${address.state}`;
            const place2 = scope === 'city' ? await this.placesService.findByString(searchString, place.geometry.location) : null;
            result = (place2 && place2[0]) || place;
        }
        return result;
    }
}

const MapService = new MapServiceInstance();
export default MapService;

