import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import clsx from 'clsx';
import {OperatorStoreProvider} from 'layouts/Dashboard/store/operatorStore';
import React, {useState} from 'react';
import {ExtendedTheme} from 'services/appearance';
import {Footer, Sidebar, Topbar} from './components';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    root: {
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.appBarHeight,
        }
    },
    shiftContent: {
        paddingLeft: 240
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
}));

interface MainProps extends React.HTMLAttributes<HTMLDivElement> {
}

const Main: React.FunctionComponent<MainProps> = ({children, className}) => {

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
        <OperatorStoreProvider>
            <div className={clsx({[classes.root]: true, [classes.shiftContent]: isDesktop}, className)}>
                <Topbar onSidebarOpen={handleSidebarOpen} showUserActions={true}/>
                <Sidebar
                    onClose={handleSidebarClose}
                    open={shouldOpenSidebar}
                    variant={isDesktop ? 'persistent' : 'temporary'}
                />
                <main className={classes.content}>
                    {children}
                    <Footer/>
                </main>
            </div>
        </OperatorStoreProvider>
    );
};

export default Main;
