const envConfig = {
    AUTH0_CLAIMS_NAMESPACE: process.env.AUTH0_CLAIMS_NAMESPACE,
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_CONNECTION: process.env.AUTH0_CONNECTION,
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    CONTENT_API_BASE: process.env.CONTENT_API_BASE,
    FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
    FIREBASE_APP_ID: process.env.FIREBASE_APP_ID,
    FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN,
    FIREBASE_DATABASE_URL: process.env.FIREBASE_DATABASE_URL,
    FIREBASE_MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID,
    FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
    FIRESTORE_EMULATOR_HOST: process.env.FIRESTORE_EMULATOR_HOST,
    FIREBASE_EMULATOR_FUNCTIONS: process.env.FIREBASE_EMULATOR_FUNCTIONS,
    FIREBASE_EMULATOR_FIRESTORE: process.env.FIREBASE_EMULATOR_FIRESTORE,
    FIREBASE_EMULATOR_HOSTING: process.env.FIREBASE_EMULATOR_HOSTING,
    FIREBASE_EMULATOR_AUTH: process.env.FIREBASE_EMULATOR_AUTH,
    NODE_ENV: process.env.NODE_ENV,
    PUBLIC_URL: process.env.PUBLIC_URL,
    REACT_APP_DESCRIPTION: process.env.REACT_APP_DESCRIPTION,
    REACT_APP_GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    REACT_APP_HOMEPAGE: process.env.REACT_APP_HOMEPAGE,
    REACT_APP_NAME: process.env.REACT_APP_NAME,
    REACT_APP_RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    REACT_APP_THEME_DARK: process.env.REACT_APP_THEME_DARK,
    REACT_APP_THEME_PRIMARY_COLOR: process.env.REACT_APP_THEME_PRIMARY_COLOR,
    REACT_APP_THEME_SECONDARY_COLOR: process.env.REACT_APP_THEME_SECONDARY_COLOR,
    REACT_APP_TITLE: process.env.REACT_APP_TITLE,
    REACT_APP_VERSION: process.env.REACT_APP_VERSION,
    SENTRY_DSN: process.env.SENTRY_DSN,
    RENTALS_API_BASE: process.env.RENTALS_API_BASE,
};

export default envConfig;
