import {action, computed, IObservableArray, observable, reaction} from 'mobx';
import RootStore from './RootStore';

export default class JucyOperatorsStore {
    rootStore: RootStore;
    @observable operatorIds: string[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        reaction(() => this.rootStore.authStore.credentials?.claims, (claims) => {
            if (claims?.operators) {
                this.mergeIds(claims.operators);
            }
        });
    }

    @computed get defaultOperatorId() {
        return this.operatorIds.length ? this.operatorIds[0] : '';
    }

    @action mergeIds(id: string[]) {
        (this.operatorIds as IObservableArray).replace([
            ...this.operatorIds,
            ...id
        ]);
    }

    async loadOperators(force?: boolean): Promise<string[]> {
        if (this.rootStore.authStore.isAuthenticated) {
            const claims = await this.rootStore.authStore.credentials.refreshClaims(force);
            if (claims?.operators) {
                this.mergeIds(claims.operators);
            }
        }
        return this.operatorIds;
    }

}
