import {AccountTabFields, AccountTabHeader} from 'components/AccountSettingsModal/components/index';
import {useAccountTabStore} from 'components/AccountSettingsModal/store/accountTabStore';
import Form from 'components/Form';
import {Observer} from 'mobx-react-lite';
import React from 'react';
import {FormSpy} from 'react-final-form';
import {Profile} from 'services/forms';

function AccountTabForm() {
    const store = useAccountTabStore();
    return <Observer>{() => (<div>
        <Form
            onSubmit={() => undefined}
            initialValues={store.fields}
            validate={Profile.validate}
            subscription={{errors: true}}
        >
            {() => (<div className="animated fadeIn">
                <FormSpy subscription={{errors: true}} onChange={({errors}) => store.errors = errors}/>
                <AccountTabHeader/>
                <AccountTabFields/>
            </div>)}
        </Form>
    </div>)}</Observer>;
}

export default AccountTabForm;
