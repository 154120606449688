import {Box, Container} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {useObserver} from 'mobx-react-lite';
import React from 'react';
import {useParams} from 'react-router-dom';
import rootStore from 'stores';

const AgentsDashboard = () => {
    let {agentId} = useParams();
    return useObserver(() => (
        <div>
            <Container maxWidth="sm">
                <Box pt={5}>
                    <Alert severity="info">
                        <AlertTitle> Coming soon </AlertTitle>
                        {rootStore.agentsStore.defaultAgentDetails?.Name}<br/>
                        <pre>{agentId}</pre>
                    </Alert>
                </Box>
            </Container>
        </div>
    ));
};
export default AgentsDashboard;
