import {makeStyles} from '@material-ui/core/styles';
import {MTableToolbar} from 'material-table';
import React from 'react';

const useStyles = makeStyles(() => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%;'
    },
}));

function TableToolbar(props: any) {
    const classes = useStyles();
    return <MTableToolbar {...props} classes={{title: classes.titleContainer}}/>;

}

export default TableToolbar;
