import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';
import config from './config';
import firebase from 'firebase/app';
// import { initFirestorter } from 'firestorter';

firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    databaseURL: config.firebase.databaseUrl,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    appId: config.firebase.appId,
    measurementId: config.firebase.measurementId,
});
export default firebase;
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const performance = firebase.performance();
export const database = firebase.database();
export const functions = firebase.app().functions('asia-northeast1');

if (config.firebase.firestoreEmulator) {
    const [host, port] = config.firebase.firestoreEmulator.split(':');
    firestore.useEmulator(host, Number(port));
}
if (config.firebase.authEmulator) {
    auth.useEmulator(`http://${config.firebase.authEmulator}`);
}
if (config.firebase.functionEmulator) {
    const [host, port] = config.firebase.functionEmulator.split(':');
    functions.useEmulator(host, Number(port));
}
