import {Property} from 'ts-convict';

export default class ContentConfig implements config.ContentConfig {
    @Property({
        default: null,
        format: String,
        env: 'CONTENT_API_BASE'
    })
    baseUrl: string;

}
