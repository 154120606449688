import {Card, CardContent, Typography, useMediaQuery} from '@material-ui/core';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import ContentPage from 'components/ContentPage/ContentPage';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        infoBlock: {
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
            top: theme.spacing(4),
            width: 400,
            maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {
                left: 0,
                top: 0,
                borderLeft: 'none',
                borderRight: 'none',
                width: '100%',
                position: 'relative',
                transform: 'translate(0, 0)',
            }
        }
    });
});

export default function WelcomeCard() {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    return (
        <Card className={classes.infoBlock} variant="outlined" square={isSm}>
            <CardContent>
                <ContentPage page="/partners/welcome">
                    {(page) => (
                        <div className="animated fadeIn">
                            <Typography variant="h5" component="h1" gutterBottom={true}>
                                {page.title}
                            </Typography>
                            <Typography variant="body2" component="div" dangerouslySetInnerHTML={{__html: page.content}}/>
                        </div>
                    )}
                </ContentPage>
            </CardContent>
        </Card>
    );
}
