import {action, observable} from 'mobx';

export class AccountSettingsModalTabStore {
    @observable modalOpen = false;
    @observable selectedTab = 0;
    @observable showProgressIndicator = false;

    @action closeModal() {
        this.modalOpen = false;
    }
}

export function createSettingsModalTabStore() {
    return new AccountSettingsModalTabStore();
}

export type TStore = ReturnType<typeof createSettingsModalTabStore>;
