import _ from 'lodash';
import {action, observable} from 'mobx';

export default class WindowLayer implements config.IConfigLayer {

    @observable config = {};
    name: string;
    path: string;
    priority: number;

    constructor({name, path, priority = 0}: { name: string, priority: number, path: string }) {
        this.name = name;
        this.path = path;
        this.priority = priority;
        this.load();
    }

    @action
    load() {
        this.config = _.get(window, this.path);
    }

    getValue(name) {
        return this.config && this.config[name];
    }

}
