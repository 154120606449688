import {UserProfile} from '@jucy-nasse/types';
import {AccountSettingsModalTabStore} from 'components/AccountSettingsModal/store/createSettingsModalTabStore';
import {ValidationErrors} from 'final-form';
import {action, computed, observable, observe, runInAction} from 'mobx';
import {IValueDidChange} from 'mobx/lib/internal';
import React, {ChangeEvent, FocusEvent} from 'react';
import {storage} from 'services/firebase';
import {Profile} from 'services/forms';
import rootStore from 'stores';

export class AccountTabStore {
    // @observable profileCompletion = 0;
    @observable securityRating = 0;
    @observable showingField = '';
    @observable showSuccess = false;
    @observable pictureFile: File = null;
    @observable picture = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable nickname = '';
    @observable email = '';
    @observable lastSignIn = '';
    @observable emailVerified = false;
    @observable performingAction = false;
    @observable loadingPicture = false;
    @observable sentVerificationEmail = false;
    @observable userId = '';
    @observable fields?: Profile.Fields;
    @observable errors?: ValidationErrors;
    completenessProps: Partial<keyof this>[] = ['picture', 'firstName', 'lastName', 'nickname', 'email', 'emailVerified'];

    constructor(settingsStore: AccountSettingsModalTabStore) {
        observe(this, 'performingAction', (change: IValueDidChange<boolean>) => {
            if (change.type === 'update' || change.newValue !== settingsStore.showProgressIndicator) {
                runInAction(() => {
                    settingsStore.showProgressIndicator = change.newValue;
                });
            }
        });
        observe(rootStore.authStore, 'user', (change: IValueDidChange<UserProfile>) => {
            if (change.type === 'update') {
                Object.assign(this, change.newValue);
                Profile.mapUserProfileToFields(change.newValue as UserProfile).then(r => this.fields = r);
            }
        }, false);
        if (rootStore.authStore.user) {
            Object.assign(this, rootStore.authStore.user);
            Profile.mapUserProfileToFields(rootStore.authStore.user).then(r => this.fields = r);
        }
    }

    @computed get profileCompletion() {
        let result = 0;
        const step = (100 / this.completenessProps.length);
        for (const prop of this.completenessProps) {
            if (this[prop]) {
                result += step;
            }
        }
        return Math.round(result);
    }

    @computed get hasFirstName() {
        return this.firstName && this.firstName.trim() !== '';
    }

    @computed get hasLastName() {
        return this.lastName && this.lastName.trim() !== '';
    }

    @computed get hasNickname() {
        return this.nickname && this.nickname.trim() !== '';
    }

    has(fieldName: string) {
        return this[fieldName] && this[fieldName].trim() !== '';
    }

    getLabelFor(field: string) {
        return field === 'emailVerified' ? 'Email verified' : Profile.getLabel(field as keyof Profile.Fields) || field;
    }

    uploadAvatar = async () => {
        runInAction(() => {
            this.loadingPicture = true;
            this.performingAction = true;
        });
        try {
            const avatarReference = storage
                .ref()
                .child('images')
                .child('avatars')
                .child(this.userId);
            await avatarReference.put(this.pictureFile);
            await this.changeField('picture', await avatarReference.getDownloadURL());
        } catch (e) {
            rootStore.notificationStore.error(e.message);
        }
        runInAction(() => {
            this.loadingPicture = false;
            this.performingAction = false;
        });
    };

    handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        if (file) {
            // readFile(file).then(action((r: string) => {
            this.picture = URL.createObjectURL(file);
            this.pictureFile = file;
            // }));
        }
    };

    removeAvatar = async () => {
        if (this.pictureFile) {
            runInAction(() => {
                this.picture = '';
                this.pictureFile = null;
            });
        } else {
            await this.changeField('picture', '');
        }

    };

    @action hideFields = (callback?) => {
        this.showingField = '';
        this.showSuccess = false;
        if (callback && typeof callback === 'function') {
            callback();
        }
    };

    onFieldBlur = (e: FocusEvent<HTMLInputElement>) => {
        const relatedTarget = e.relatedTarget as HTMLButtonElement;
        if (relatedTarget?.id === 'save-field-button') {
            e.preventDefault();
        }
    };

    async changeField(fieldName: string, value: string) {
        const title = this.getLabelFor(fieldName);
        if (this.errors[fieldName]) {
            return;
        } else if (this[fieldName] !== value) {
            try {
                const response = await rootStore.authStore.updateProfile({[fieldName as keyof UserProfile]: value});
                runInAction(() => {
                    rootStore.authStore.user = response;
                    this.showSuccess = true;
                    setTimeout(this.hideFields, 800);
                });
                rootStore.notificationStore.success(`${title} updated`);
            } catch (e) {
                console.error(e);
                rootStore.notificationStore.error(e.message, `Failed to update ${title}`);
            }
        } else {
            this.hideFields();

        }
    }

    @action showField = (fieldName) => {
        if (!fieldName) {
            return;
        }
        this.showingField = fieldName;
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        const fieldName = input?.name;
        const value = input?.value;
        if (!event || !fieldName) {
            return;
        }

        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
            return;
        }

        const key = event.key;

        if (!key) {
            return;
        }

        if (key === 'Escape') {
            this.hideFields();
        } else if (key === 'Enter') {
            this.onFieldChanged(fieldName, value);
        }
    };

    onFieldChanged = (fieldName: string, value: string) => {
        runInAction(() => this.performingAction = true);
        this.changeField(fieldName, value).then(() => {
            runInAction(() => this.performingAction = false);
        });
    };

}

export function createAccountTabStore(settingsStore?: AccountSettingsModalTabStore) {
    return new AccountTabStore(settingsStore);
}

export type TStore = ReturnType<typeof createAccountTabStore>;
