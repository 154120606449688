import {Address} from '@jucy-nasse/types';
import {get} from 'lodash';
import {rffHelper} from 'utils/index';
import * as Yup from 'yup';

export interface Fields {
    formatted: string;
    streetAddress: string;
    streetNumber?: string;
    route?: string;
    locality: string;
    administrativeAreaLevel1?: string;
    postalCode: string;
    country: string;
    placeId?: string;
    latLng?: {
        lat?: number;
        lng?: number;
    }
}

function notEmpty(s: string) {
    return s && s.trim().length > 0;
}

function addComma(s: string) {
    return notEmpty(s) ? `${s}, ` : s;
}

export function mapFieldsToAddress(fields: Fields): Address {
    return {
        ...fields,
        streetNumber: fields.streetNumber || '',
        route: fields.route || '',
        administrativeAreaLevel1: fields.administrativeAreaLevel1 || '',
        latLng: {
            lat: (fields.latLng && fields.latLng.lat) || 0,
            lng: (fields.latLng && fields.latLng.lng) || 0
        }
    };
}

export function mapAddressToFields(address: Address): Fields {
    return {
        ...address,

    };
}

export function formatAddress(address: Fields) {
    let formattedResult = '';

    const validFields = {
        streetAddress: notEmpty(get(address, 'streetAddress')),
        streetNumber: notEmpty(get(address, 'streetNumber')),
        route: notEmpty(get(address, 'route')),
        locality: notEmpty(get(address, 'locality')),
        administrativeAreaLevel1: notEmpty(get(address, 'administrativeAreaLevel1')),
        postalCode: notEmpty(get(address, 'postalCode')),
        country: notEmpty(get(address, 'country')),
    };

    if (validFields.route || validFields.administrativeAreaLevel1 || validFields.locality) {
        if (validFields.streetAddress) {
            formattedResult += `${address.streetAddress}`;
        }
        if (validFields.locality) {
            formattedResult = `${addComma(formattedResult)}${address.locality}`;
        }
        if (validFields.administrativeAreaLevel1) {
            formattedResult = `${addComma(formattedResult)}${address.administrativeAreaLevel1}${validFields.postalCode ? ` ${address.postalCode}` : ''}`;
        } else if (validFields.postalCode) {
            formattedResult = `${addComma(formattedResult)}${address.postalCode}`;
        }
        if (validFields.country) {
            formattedResult = `${addComma(formattedResult)}${address.country}`;
        }
    }
    return formattedResult;
}

export function mapGooglePlaceResult(place: google.maps.places.PlaceResult): Fields {
    const result = {...initialValues};

    if (place && place.address_components) {
        place.address_components.forEach(component => {
            component.types.forEach(type => {
                if (type === 'street_number') {
                    result.streetNumber = component.long_name;
                }
                if (type === 'route') {
                    result.route = component.long_name;
                }
                if (type === 'locality') {
                    result.locality = component.long_name;
                }
                if (type === 'administrative_area_level_1') {
                    result.administrativeAreaLevel1 = component.long_name;
                }
                if (type === 'country') {
                    result.country = component.long_name;
                }
                if (type === 'postal_code') {
                    result.postalCode = component.long_name;
                }
            });

        });
        result.streetAddress = `${result.streetNumber ? `${result.streetNumber} ` : ''}${result.route}`;
        result.formatted = formatAddress(result);
        result.placeId = place.place_id;
        result.latLng.lat = place.geometry.location.lat();
        result.latLng.lng = place.geometry.location.lng();
    }
    return result;
}

export const {initialValues, validate, required, schema} = rffHelper<Fields>({
    initialValues: {
        formatted: '',
        streetAddress: '',
        streetNumber: '',
        locality: '',
        administrativeAreaLevel1: null,
        country: 'New Zealand',
        postalCode: '',
        placeId: '',
        latLng: {
            lat: 0,
            lng: 0,
        },
    },
    schema: {
        formatted: Yup.string().required('Please provide an address'),
        streetAddress: Yup.string().required('Please provide a street address'),
        streetNumber: Yup.string().notRequired(),
        route: Yup.string().notRequired(),
        locality: Yup.string().required('Please provide a City'),
        administrativeAreaLevel1: Yup.string().nullable().notRequired(),
        country: Yup.string().required('Please provide a Country'),
        postalCode: Yup.string().required('Please provide a post code'),
        placeId: Yup.string(),
        latLng: Yup.object().notRequired().shape({
            lat: Yup.number().notRequired(),
            lng: Yup.number().notRequired()
        })
    }
});
