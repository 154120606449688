import {Color} from '@material-ui/core';

import {
    amber, blue, blueGrey as blueGray, brown, cyan, deepOrange, deepPurple, green, grey as gray, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow,
} from '@material-ui/core/colors';

import {createMuiTheme, Theme, ThemeOptions} from '@material-ui/core/styles';
import {PaletteColorOptions} from '@material-ui/core/styles/createPalette';
import {Overrides} from '@material-ui/core/styles/overrides';
import camelCase from 'camelcase';

import firebase, {analytics, auth, firestore} from './firebase';

export const jucyGreen: Color = {
    50: '#f0f8e6',
    100: '#daedc1',
    200: '#c2e298',
    300: '#a9d66e',
    400: '#96cd4f',
    500: '#84c430',
    600: '#7cbe2b',
    700: '#71b624',
    800: '#67af1e',
    900: '#54a213',
    A100: '#e6ffd4',
    A200: '#c8ffa1',
    A400: '#aaff6e',
    A700: '#9cff54',
};
const jucyGreenPalette: PaletteColorOptions = {
    light: '#b7f763',
    main: jucyGreen[500],
    dark: '#519300',
    contrastText: '#fff'
};
const jucyPurple: Color = {
    50: '#f0e1ee',
    100: '#dbb3d4',
    200: '#c380b8',
    300: '#aa4d9c',
    400: '#982786',
    500: '#860171',
    600: '#7e0169',
    700: '#73015e',
    800: '#690154',
    900: '#560042',
    A100: '#ff88dd',
    A200: '#ff55ce',
    A400: '#ff22c0',
    A700: '#ff08b9',
};
const jucyPurplePalette: PaletteColorOptions = {
    light: '#b943a0',
    main: jucyPurple[500],
    dark: '#550045',
    contrastText: '#fff'
};
export const jucyOrange: Color = {
    50: '#ffebe1',
    100: '#feceb3',
    200: '#fdad81',
    300: '#fc8c4e',
    400: '#fc7328',
    500: '#fb5a02',
    600: '#fa5202',
    700: '#fa4801',
    800: '#f93f01',
    900: '#f82e01',
    A100: '#ffffff',
    A200: '#ffeeeb',
    A400: '#ffc1b8',
    A700: '#ffab9f',
};
const jucyOrangePalette: PaletteColorOptions = {
    light: '#ff8c3e',
    main: jucyOrange[500],
    dark: '#c02300',
    contrastText: '#fff'
};
type colorDef = {
    id: string,
    name: string,
    import: Color,
};
type colorDefs = {
    [key: string]: colorDef
};
const colors: colorDefs = {
    red: {
        id: 'red',
        name: 'Red',
        import: red,
    },

    pink: {
        id: 'pink',
        name: 'Pink',
        import: pink,
    },

    purple: {
        id: 'purple',
        name: 'Purple',
        import: purple,
    },

    deepPurple: {
        id: 'deep-purple',
        name: 'Deep Purple',
        import: deepPurple,
    },

    indigo: {
        id: 'indigo',
        name: 'Indigo',
        import: indigo,
    },

    blue: {
        id: 'blue',
        name: 'Blue',
        import: blue,
    },

    lightBlue: {
        id: 'light-blue',
        name: 'Light Blue',
        import: lightBlue,
    },

    cyan: {
        id: 'cyan',
        name: 'Cyan',
        import: cyan,
    },

    teal: {
        id: 'teal',
        name: 'Teal',
        import: teal,
    },

    green: {
        id: 'green',
        name: 'Green',
        import: green,
    },

    lightGreen: {
        id: 'light-green',
        name: 'Light Green',
        import: lightGreen,
    },

    lime: {
        id: 'lime',
        name: 'Lime',
        import: lime,
    },

    yellow: {
        id: 'yellow',
        name: 'Yellow',
        import: yellow,
    },

    amber: {
        id: 'amber',
        name: 'Amber',
        import: amber,
    },

    orange: {
        id: 'orange',
        name: 'Orange',
        import: orange,
    },

    deepOrange: {
        id: 'deep-orange',
        name: 'Deep Orange',
        import: deepOrange,
    },

    brown: {
        id: 'brown',
        name: 'Brown',
        import: brown,
    },

    gray: {
        id: 'gray',
        name: 'Gray',
        import: gray,
    },

    blueGray: {
        id: 'blue-gray',
        name: 'Blue Gray',
        import: blueGray,
    },
    jucyGreen: {
        id: 'jucy-green',
        name: 'Jucy Green',
        import: jucyGreen,
    },

    jucyPurple: {
        id: 'jucy-purple',
        name: 'Jucy Purple',
        import: jucyPurple,
    },
    jucyOrange: {
        id: 'jucy-orange',
        name: 'Jucy Orange',
        import: jucyOrange,

    }
};

const getColor = (colorId: string): colorDef => {
    if (!colorId) {
        return null;
    }

    colorId = camelCase(colorId);

    return colors[colorId];
};
const appBarHeight = 75;
export const defaultPrimaryColor = getColor('jucy-purple');
export const defaultSecondaryColor = getColor('jucy-green');
const defaultDark = process.env.REACT_APP_THEME_DARK === 'true';
const muiDefaultTheme = createMuiTheme();
const overrides: Overrides = {
    MuiAppBar: {
        colorDefault: {
            zIndex: muiDefaultTheme.zIndex.drawer + 1,
            backgroundColor: muiDefaultTheme.palette.common.white,
            borderTop: `7px solid ${jucyGreenPalette.main}`,
            height: appBarHeight,
            boxShadow: muiDefaultTheme.shadows[4]
        },
        root: {
            background: '#fff',
        },
    },
};
const defaultTheme = createMuiTheme({
    overrides: overrides,
    appBarHeight,
    palette: {
        common: {
            black: '#000',
            white: '#fff'
        },
        background: {
            paper: '#fff',
            default: '#fafafa'
        },
        primary: jucyPurplePalette,
        secondary: jucyGreenPalette,
        error: jucyOrangePalette,
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)'
        }
    },
    primaryColor: defaultPrimaryColor,
    secondaryColor: defaultSecondaryColor,
    dark: defaultDark,
} as ThemeOptions) as ExtendedTheme;

export interface ExtendedTheme extends Theme {
    appBarHeight: number,
    primaryColor: colorDef,
    secondaryColor: colorDef,
    dark: boolean,
}

export interface AppTheme {
    primaryColor: string;
    secondaryColor: string;
    dark: boolean;
}

class Appearance {

    colors = colors;

    defaultPrimaryColor = defaultPrimaryColor;
    defaultSecondaryColor = defaultSecondaryColor;
    defaultDark = defaultDark;

    defaultTheme = defaultTheme;

    isDefaultTheme = (theme: ExtendedTheme) => {
        if (!theme) {
            return false;
        }

        return theme.primaryColor.id === defaultPrimaryColor.id &&
            theme.secondaryColor.id === defaultSecondaryColor.id &&
            theme.dark === defaultDark;

    };

    createTheme = (theme: AppTheme): ExtendedTheme => {
        if (!theme) {
            return null;
        }

        if (!theme.primaryColor || !theme.secondaryColor) {
            return null;
        }

        let dark = theme.dark;
        let primaryColor = getColor(theme.primaryColor);
        let secondaryColor = getColor(theme.secondaryColor);

        if (!primaryColor || !secondaryColor) {
            return null;
        }

        return createMuiTheme({
            overrides,
            appBarHeight,
            palette: {
                primary: primaryColor.import,
                secondary: secondaryColor.import,
                type: dark ? 'dark' : 'light',
            },
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            dark: dark,
        } as any) as ExtendedTheme;
    };

    changeTheme = (theme: AppTheme) => {
        return new Promise((resolve, reject) => {
            if (!theme) {
                reject();

                return;
            }

            if (!theme.primaryColor || !theme.secondaryColor) {
                return null;
            }

            let dark = theme.dark;
            let primaryColor = getColor(theme.primaryColor);
            let secondaryColor = getColor(theme.secondaryColor);

            if (!primaryColor || !secondaryColor) {
                reject();

                return;
            }

            const currentUser = auth.currentUser;

            if (!currentUser) {
                reject();

                return;
            }

            const uid = currentUser.uid;

            if (!uid) {
                reject();

                return;
            }

            const userDocumentReference = firestore.collection('users').doc(uid);

            userDocumentReference
                .update({
                    theme: {
                        primaryColor: primaryColor.id,
                        secondaryColor: secondaryColor.id,
                        dark: dark,
                    },
                })
                .then((value) => {
                    analytics.logEvent('change_theme', {
                        theme: theme,
                    });

                    resolve(value);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    };

    changePrimaryColor = (option: string) => {
        return new Promise((resolve, reject) => {
            if (!option) {
                reject();

                return;
            }

            const primaryColor = getColor(option);

            if (!primaryColor) {
                reject();

                return;
            }

            const currentUser = auth.currentUser;

            if (!currentUser) {
                reject();

                return;
            }

            const uid = currentUser.uid;

            if (!uid) {
                reject();

                return;
            }

            const userDocumentReference = firestore.collection('users').doc(uid);

            userDocumentReference
                .update({
                    'theme.primaryColor': primaryColor.id,
                })
                .then((value) => {
                    analytics.logEvent('change_primary_color', {
                        primaryColor: primaryColor.id,
                    });

                    resolve(value);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    };

    changeSecondaryColor = (option: string) => {
        return new Promise((resolve, reject) => {
            if (!option) {
                reject();

                return;
            }

            const secondaryColor = getColor(option);

            if (!secondaryColor) {
                reject();

                return;
            }

            const currentUser = auth.currentUser;

            if (!currentUser) {
                reject();

                return;
            }

            const uid = currentUser.uid;

            if (!uid) {
                reject();

                return;
            }

            const userDocumentReference = firestore.collection('users').doc(uid);

            userDocumentReference
                .update({
                    'theme.secondaryColor': secondaryColor.id,
                })
                .then((value) => {
                    analytics.logEvent('change_secondary_color', {
                        secondaryColor: secondaryColor.id,
                    });

                    resolve(value);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    };

    changeDark = (dark: boolean) => {
        return new Promise((resolve, reject) => {
            const currentUser = auth.currentUser;

            if (!currentUser) {
                reject();

                return;
            }

            const uid = currentUser.uid;

            if (!uid) {
                reject();

                return;
            }

            const userDocumentReference = firestore.collection('users').doc(uid);

            userDocumentReference
                .update({
                    'theme.dark': dark,
                })
                .then((value) => {
                    analytics.logEvent('change_dark', {
                        dark: dark,
                    });

                    resolve(value);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    };

    changeSyncAppearance = (syncAppearance: boolean) => {
        return new Promise((resolve, reject) => {
            const currentUser = auth.currentUser;

            if (!currentUser) {
                reject();

                return;
            }

            const uid = currentUser.uid;

            if (!uid) {
                reject();

                return;
            }

            const userDocumentReference = firestore.collection('users').doc(uid);

            userDocumentReference
                .update({
                    'theme.syncAppearance': syncAppearance,
                })
                .then((value) => {
                    analytics.logEvent('change_sync_appearance', {
                        syncAppearance: syncAppearance,
                    });

                    resolve(value);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    };

    resetTheme = () => {
        return new Promise((resolve, reject) => {
            const currentUser = auth.currentUser;

            if (!currentUser) {
                reject();

                return;
            }

            const uid = currentUser.uid;

            if (!uid) {
                reject();

                return;
            }

            const userDocumentReference = firestore.collection('users').doc(uid);

            userDocumentReference
                .update({
                    theme: firebase.firestore.FieldValue.delete(),
                })
                .then((value) => {
                    analytics.logEvent('reset_theme');

                    resolve(value);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    };
}

const appearance = new Appearance();
export default appearance;
