import {EditDealModal as GridEditDealModal} from 'components/DealGridForm/components';
import {toJS} from 'mobx';
import {Observer} from 'mobx-react-lite';
import React from 'react';
import EditDealActionButtons from 'views/Dashboard/Components/DealsDashboard/Components/EditDealActionButtons';
import {useDealDashboardStore} from 'views/Dashboard/Components/DealsDashboard/store/operatorStore';

export default function EditDealModal() {
    const store = useDealDashboardStore();
    return <Observer>{() => (<>
        <GridEditDealModal
            onSubmit={store.onDealSaved}
            deal={toJS(store.currentDeal)}
            open={store.modalOpen}
            onClose={store.closeModal}
            renderActions={({onClose, submitting, success, dirty, submit, btnEndIcons,}) => (
                <EditDealActionButtons submitting={submitting} dirty={dirty} success={success} submit={submit} btnEndIcons={btnEndIcons} onClose={onClose}/>
            )}
            progressProps={{
                calculateProgress: (progress) => store.calculateSubmitProgress(progress, store),
                renderProgressItems: store.renderProgressItems
            }}
        />
    </>)}</Observer>;
}
