import {Operator} from '@jucy-nasse/types';
import {FormControl, Link, MenuItem, PropTypes} from '@material-ui/core';
import AddressInput from 'components/AddressInput';
import Form from 'components/Form';
import ReCAPTCHAField from 'components/ReCAPTCHAField';
import industries from 'data/industries.json';
import regionData from 'data/regions.json';
import {FORM_ERROR} from 'final-form';
import {memoize, omit} from 'lodash';
import {runInAction} from 'mobx';
import {Select, TextField} from 'mui-rff';
import React, {useEffect} from 'react';
import {FormProps} from 'react-final-form';
import {Link as RouterLink} from 'react-router-dom';
import {authentication} from 'services';
import {analytics} from 'services/firebase';
import firestoreService, {operatorCollection} from 'services/firestoreService';
import {OperatorDetails} from 'services/forms';
import {createLogger} from 'services/logger';

import rootStore from 'stores';
import {generatePassword, Looper} from 'utils/index';
import * as Yup from 'yup';

const logger = createLogger({name: 'SignUp'});

export interface OperatorDetailsFormProps extends Omit<FormProps<OperatorDetails.Fields>, 'onSubmit' | 'initialValues' | 'validate' | 'render'> {
    onSubmit: (operator: Operator) => void;
    operator?: Operator;
    children?: React.ReactNode;
    className?: string
    id?: string
}

async function waitForLogIn(email: string) {
    return await Looper(async () => {
        return rootStore.authStore.user?.email === email;
    });
}

async function waitForOperatorAttached(operator: Operator) {
    return await Looper(async () => {
        const userOperators = await rootStore.operatorStore.loadOperators(true);
        return (userOperators && userOperators.includes(operator.id));
    });
}

async function upsertOperator(operator: Operator, {password}: { password?: string }) {
    let userId = rootStore.authStore.user && rootStore.authStore.user.userId;
    if (!rootStore.authStore.isAuthenticated) {
        logger.debug('Creating new user');
        analytics.logEvent('operator_add_user',);
        const nameArray = operator.primaryContact.split(' ').map(v => v.trim());
        let firstName = '';
        let lastName = '';
        if (nameArray.length) {
            firstName = nameArray[0];
        }
        if (nameArray.length > 1) {
            lastName = nameArray[nameArray.length - 1];
        }
        const credentials = await rootStore.authStore.signupAndAuthorize({
            email: operator.emailAddress,
            password: password || generatePassword(),
            name: operator.primaryContact,
            firstName,
            lastName
        });
        logger.debug('Logging in');
        await waitForLogIn(operator.emailAddress);
        userId = credentials.userInfo.sub;
        logger.debug(`User ${userId} created`);
    }
    if (!operator.owners.includes(userId)) {
        operator.owners.push(userId);
    }
    if (operator.id) {
        logger.debug('Updating operator');
        analytics.logEvent('operator_update',);
        await firestoreService.update(operatorCollection, operator.id, operator);
    } else {
        logger.debug('Creating operator');
        analytics.logEvent('operator_create',);
        const saveResult = await firestoreService.add(operatorCollection, operator);
        operator.id = saveResult.id;
    }
    if (!rootStore.operatorStore.operatorIds.includes(operator.id)) {
        runInAction(() => rootStore.operatorStore.operatorIds.push(operator.id));
    }

    logger.debug('Awaiting operator save');
    await waitForOperatorAttached(operator);
    logger.debug('Operator sign up complete');
    return operator;
}

const lastToken = {cancel: () => undefined};
const emailExists = memoize((emailAddress) => {
    lastToken.cancel();
    return new Promise((resolve, reject) => {
        lastToken.cancel = () => {  // SPECIFY CANCELLATION
            reject(new Error('Cancelled')); // reject the promise
        };
        authentication.emailExists(emailAddress).then(resolve).catch(reject);
    });
});

function OperatorDetailsForm({onSubmit, operator, children, className, id, ...props}: OperatorDetailsFormProps) {
    const [submitting, setSubmitting] = React.useState(false);
    const [currentOperator, setCurrentOperator] = React.useState({...OperatorDetails.initialValues, ...OperatorDetails.mapOperatorToFields(operator)});
    const handleSubmit = React.useCallback(async (values) => {
        setSubmitting(true);
        try {
            setCurrentOperator(values);
            const result = await upsertOperator(OperatorDetails.mapFieldsToOperator(values), {password: values.password});
            setSubmitting(false);
            return onSubmit(result);
        } catch (e) {
            console.error('upsertOperator error');
            logger.error(e);
            setSubmitting(false);
            let message = 'We are unable to process your request at this time';
            if (typeof e.message === 'string') {
                message = e.message;
            } else if (typeof e.description === 'string') {
                message = e.description;
            } else if (typeof e.policy === 'string') {
                message = e.policy;
            }
            return {[FORM_ERROR]: message};
        }
    }, [onSubmit]);
    OperatorDetails.schema.fields.password = rootStore.authStore.isAuthenticated ? Yup.string().notRequired() : Yup.string().min(6).required();
    OperatorDetails.schema.fields.passwordConfirmation = rootStore.authStore.isAuthenticated ? Yup.string().notRequired() : Yup.string().oneOf(
        [Yup.ref('password'), undefined],
        'Password confirmation is not equal to password'
    ).min(6).required();
    useEffect(() => {
        if (operator) {
            setCurrentOperator({...OperatorDetails.initialValues, ...OperatorDetails.mapOperatorToFields(operator)});
        }
    }, [operator]);
    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            initialValues={currentOperator}
            validate={async (values) => {
                const errors = await OperatorDetails.validate(values) as any;
                if (!submitting) {
                    const emailAddress = values.operator?.emailAddress;
                    const emailAddressValid = (OperatorDetails.schema.fields.operator as any).fields.emailAddress.isValidSync(emailAddress);
                    try {

                        const exists = emailAddressValid && rootStore.authStore.user?.email !== emailAddress
                            && await emailExists(emailAddress);
                        if (exists) {
                            analytics.logEvent('duplicate_sign_up', {
                                email: emailAddress,
                            });
                            errors.operator = {
                                ...errors.operator,
                                emailAddress: <>
                                    Email address already in use. <Link component={RouterLink} to={`/sign-in?email=${emailAddress}`}>Sign-in</Link> if this is your email.
                                </>
                            };
                        }
                    } catch (e) {
                        if (e.message !== 'Cancelled') {
                            console.error(e);
                        }
                    }

                }
                return errors;
            }}
            subscription={{submitting: true}}
            render={({handleSubmit, submitting}) => {
                const sharedFieldProps: {
                    fullWidth: boolean,
                    variant: 'filled' | 'outlined' | 'standard'
                    margin: PropTypes.Margin,
                    disabled: boolean,
                    fieldProps: {
                        validateFields: string[]
                    }
                } = {
                    fullWidth: true,
                    variant: 'outlined',
                    margin: 'normal' as PropTypes.Margin,
                    disabled: submitting,
                    fieldProps: {
                        validateFields: []
                    }
                };
                return (
                    <form id={id} className={className} onSubmit={handleSubmit} noValidate={true}>

                        <TextField
                            {...sharedFieldProps}
                            autoComplete="email"
                            label="Email address"
                            required={OperatorDetails.required.operator.emailAddress}
                            name="operator.emailAddress"
                            type="email"
                        />
                        {!rootStore.authStore.isAuthenticated && (<>
                            <TextField
                                {...sharedFieldProps}
                                autoComplete="new-password"
                                label="Password"
                                required={OperatorDetails.required.password}
                                name="password"
                                type="password"
                            />
                            <TextField
                                {...sharedFieldProps}
                                autoComplete="new-password"
                                label="Confirm password"
                                required={OperatorDetails.required.passwordConfirmation}
                                name="passwordConfirmation"
                                type="password"
                            />
                        </>)}
                        <TextField
                            {...sharedFieldProps}
                            autoComplete="organization"
                            label="Company name"
                            required={OperatorDetails.required.operator.name}
                            name="operator.name"
                            type="text"
                        />
                        <TextField
                            {...sharedFieldProps}
                            autoComplete="name"
                            label="Your name"
                            required={OperatorDetails.required.operator.primaryContact}
                            name="operator.primaryContact"
                            type="text"
                        />

                        {/*<TextField*/}
                        {/*    {...sharedFieldProps}*/}
                        {/*    fieldProps={{*/}
                        {/*        validateFields: [],*/}
                        {/*        subscription: {error: true, value: true, invalid: true},*/}
                        {/*        validate: async (emailAddress) => {*/}
                        {/*            if (isFieldValid(operatorValidator.emailAddress, emailAddress) && rootStore.authStore.user?.email !== emailAddress) {*/}
                        {/*                setCheckingEmail(true);*/}
                        {/*                const exists = await emailExists(emailAddress);*/}
                        {/*                setCheckingEmail(false);*/}
                        {/*                if (exists) {*/}
                        {/*                    analytics.logEvent('duplicate_sign_up', {*/}
                        {/*                        email: emailAddress,*/}
                        {/*                    });*/}
                        {/*                    console.log('Invalid');*/}
                        {/*                    return (*/}
                        {/*                        <>Email address already in use. <Link component={RouterLink} to={`/sign-in?email=${emailAddress}`}>Sign-in</Link> if this is your email.</>*/}
                        {/*                    );*/}
                        {/*                }*/}
                        {/*            }*/}
                        {/*        },*/}
                        {/*        render: ({input, meta, ...rest}) => {*/}
                        {/*            console.log({input, meta, ...rest});*/}
                        {/*            return (*/}
                        {/*                <TextFieldWrapper*/}
                        {/*                    input={input}*/}
                        {/*                    meta={meta}*/}
                        {/*                    autoComplete="email"*/}
                        {/*                    label="Email address"*/}
                        {/*                    required={OperatorDetails.required.operator.emailAddress}*/}
                        {/*                    name="operator.emailAddress"*/}
                        {/*                    type="email"*/}

                        {/*                />*/}
                        {/*            );*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*    // autoComplete="email"*/}
                        {/*    name="operator.emailAddress"*/}
                        {/*    // required={OperatorDetails.required.operator.emailAddress}*/}
                        {/*    // name="operator.emailAddress"*/}
                        {/*    // type="email"*/}
                        {/*    // InputProps={{*/}
                        {/*    //     endAdornment: checkingEmail ? (<>*/}
                        {/*    //         <InputAdornment position="end">*/}
                        {/*    //             <CircularProgress size={20}/>*/}
                        {/*    //         </InputAdornment>*/}
                        {/*    //     </>) : null*/}
                        {/*    // }}*/}

                        {/*/>*/}
                        <TextField
                            {...sharedFieldProps}
                            autoComplete="tel"
                            label="Phone number"
                            required={OperatorDetails.required.operator.phoneNumber}
                            name="operator.phoneNumber"
                            type="tel"
                        />
                        <TextField
                            {...sharedFieldProps}
                            label="Website"
                            required={OperatorDetails.required.operator.website}
                            name="operator.website"
                            type="url"
                        />
                        <AddressInput
                            {...sharedFieldProps}
                            fullWidth={true}
                            required={true}
                            label="Address"
                            requiredFields={OperatorDetails.required.operator.address}
                            name="operator.address"
                            requestOptions={{
                                componentRestrictions: {
                                    country: regionData.map(c => c.code)
                                }
                            }}
                        />
                        <FormControl margin="normal" fullWidth={true}>
                            <Select
                                {...omit(sharedFieldProps, ['margin'])}
                                label="Your industry"
                                required={OperatorDetails.required.operator.type}
                                name="operator.type"
                            >
                                {industries.map(industry => (
                                    <MenuItem key={industry} value={industry}>{industry}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <ReCAPTCHAField
                            {...sharedFieldProps}
                            name="captchaToken"
                        />
                        {children}

                    </form>
                );
            }}
        />
    );
}

export default OperatorDetailsForm;
