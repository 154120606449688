import {Property} from 'ts-convict';

export default class Auth0Config implements config.Auth0Config {
    @Property({
        default: null,
        format: String,
        env: 'AUTH0_DOMAIN'
    })
    domain: string;

    @Property({
        default: null,
        format: String,
        env: 'AUTH0_CLIENT_ID'
    })
    clientId: string;

    @Property({
        default: null,
        format: String,
        env: 'AUTH0_CLAIMS_NAMESPACE'
    })
    claimsNamespace: string;

    @Property({
        default: null,
        format: String,
        env: 'AUTH0_CONNECTION'
    })
    connection: string;

}
