import {createBrowserHistory} from 'history';
import {RouterStore as MobxRouterStore, syncHistoryWithStore} from 'mobx-react-router';
import rootStore from 'stores/index';
import {parseSearchParams} from 'utils';
import RootStore from './RootStore';

const browserHistory = createBrowserHistory();

export default class RouterStore extends MobxRouterStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        this.rootStore = rootStore;
        syncHistoryWithStore(browserHistory, this);
    }

    redirectBack(fallback = '/') {
        const backLink = localStorage.getItem('backLink') || parseSearchParams(window.location.search).get('return') || fallback;
        localStorage.removeItem('backLink');
        rootStore.routerStore.history.push(backLink);
    }

}
