import {Authentication, WebAuth} from 'auth0-js';
import Auth0Lock from 'auth0-lock';
import {reaction} from 'mobx';
import appearance from 'services/appearance';
import config from 'services/config';
import {createLogger} from 'services/logger';
import rootStore from 'stores';
import urlJoin from 'url-join';

const logger = createLogger({name: 'AuthClient'});

const redirectUri = urlJoin(config.app.homePage, 'sign-in/auth0/callback');

const auth0Config = {
    redirect: true,//window.location.hostname === 'localhost',
    domain: config.auth0.domain,
    audience: `https://${config.auth0.domain}/userinfo`,
    clientID: config.auth0.clientId,
    redirectUri: redirectUri,
    responseType: 'token id_token',
    scope: 'openid profile email'
};

const addSignUp = () => {
    const signUpEl = document.querySelector('.sign-up');
    if (!signUpEl) {
        const node = document.querySelector('.auth0-lock-alternative');
        const container = node && node.parentNode;
        if (container) {
            let a = document.createElement('a');
            let p = document.createElement('p');
            a.setAttribute('href', config.app.homePage);
            a.innerText = 'Don\'t have an account? Sign up';
            a.classList.add('auth0-lock-alternative-link');
            p.classList.add('auth0-lock-alternative');
            p.classList.add('sign-up');
            p.appendChild(a);
            container.appendChild(p);
        }
    }
};

function postAuthenticated() {
    if (rootStore.operatorStore.defaultOperatorId) {
        logger.debug(`redirect /dashboard/operators/${rootStore.operatorStore.defaultOperatorId}/deals`);
        rootStore.routerStore.history.replace(`/dashboard/operators/${rootStore.operatorStore.defaultOperatorId}/deals`);
    } else {
        logger.debug('redirectBack');
        rootStore.routerStore.redirectBack();
    }
}

const createLock = (options?: Partial<Auth0LockConstructorOptions>) => {
    const lock = new Auth0Lock(
        config.auth0.clientId,
        config.auth0.domain,
        {
            autofocus: true,
            allowSignUp: false,
            oidcConformant: true,
            signupLink: urlJoin(config.app.homePage, `?returnTo=${encodeURI(window.location.href)}`),
            loginAfterSignUp: true,
            auth: {
                redirect: auth0Config.redirect,
                responseType: 'token id_token',
                redirectUrl: auth0Config.redirectUri
            },
            languageDictionary: {
                emailInputPlaceholder: 'user@jucyworld.com',
                title: 'Jucy Dashboard'
            },
            theme: {
                logo: 'https://upload.wikimedia.org/wikipedia/en/f/fd/Jucy_group_limited_logo.png',
                primaryColor: appearance.defaultPrimaryColor.import['500']
            },
            ...options
        }
    );
    lock.on('authenticated', () => {
        if (rootStore.authStore.isAuthenticated) {
            postAuthenticated();
        } else {
            reaction(() => rootStore.authStore.isAuthenticated, (isAuthenticated, reaction) => {
                if (isAuthenticated) {
                    reaction.dispose();
                    postAuthenticated();
                }
            });
        }
    });
    lock.on('signin ready', addSignUp);
    lock.on('show', () => {
        addSignUp();
        localStorage.setItem('backLink', window.location.pathname);
    });

    return lock;
};
const auth0 = {
    webAuth: new WebAuth(auth0Config),
    authentication: new Authentication(auth0Config),
    lock: createLock(),
    createLock,
};

export default auth0;
