import {get} from 'lodash';
import {observable, observe, toJS} from 'mobx';
import {IValueDidChange} from 'mobx/lib/internal';
import {DefaultsLayer, StorageLayer, WindowLayer} from 'services/config';
import RootStore from 'stores/RootStore';

class Config {

    layers: config.IConfigLayer[] = [];

    constructor(layers: config.IConfigLayer[]) {
        const result = new Proxy(this, this);
        result.layers = layers;
        return result;

    }

    public get(target: any, prop: string) {
        if (prop in this) {
            return this[prop];
        }
        const firstValuedLayer = this.layers.sort((a, b) => b.priority - a.priority)
            .find(layer => {
                return typeof layer[prop] !== 'undefined';
            });

        const nestedHandler = {
            get: (target1: any, prop1: string) => {
                const altLayer = this.layers
                    .find(layer => typeof get(layer, `${prop}.${prop1}`) !== 'undefined');
                return altLayer ? get(altLayer, `${prop}.${prop1}`) : target1[prop1];
            },
        };
        return new Proxy(firstValuedLayer[prop], nestedHandler);
    }

}

class ConfigStore {
    rootStore: RootStore;
    @observable layers: config.IConfigLayer[] = [];
    config: config.MainConfig;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        observe(this, 'layers', (change: IValueDidChange<config.IConfigLayer[]>) => {
            if (change.type === 'update') {
                this.config = new Config(toJS(this.layers)) as any;
            }
        });
        this.init();
    }

    init() {
        this.layers = [
            new StorageLayer({name: 'browser', key: 'jucy-config', priority: 50}),
            /* TODO Account settings layer goes here */
            /* TODO JWT claims layer goes here */
            new WindowLayer({name: 'env', path: 'jucy.config', priority: 25}),
            new DefaultsLayer({name: 'default', priority: 0}),
        ];
    }
}

export default ConfigStore;
