import {Box, Container} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import React from 'react';

export default function FatalError({title, message}: { title?: React.ReactNode, message: React.ReactNode }) {
    return (
        <Container maxWidth="sm">
            <Box pt={5}>
                <Alert severity="error">
                    {title && (<AlertTitle>{title}</AlertTitle>)}
                    {message}
                </Alert>
            </Box>
        </Container>
    );
}
