import {Property} from 'ts-convict';

export default class RentalsAPIConfig {
    @Property({
        default: null,
        format: String,

        env: 'RENTALS_API_BASE'
    })
    baseUrl: string;
}
