import {FormControl, FormControlProps, FormHelperText} from '@material-ui/core';
import React from 'react';
import {useField} from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import config from 'services/config';
import {showError} from 'utils/RFFUtils';

export interface ReCAPTCHAFieldProps extends FormControlProps<any, any> {
    name: string;
    helperText?: string;
}

export default function ReCAPTCHAField({helperText, ...props}: ReCAPTCHAFieldProps) {
    const {meta, input} = useField(props.name);
    const {error, submitError} = meta;
    const isError = showError({meta});
    return config.app.recaptchaKey ? (
        <FormControl component="fieldset"  {...props}>
            <ReCAPTCHA
                sitekey={config.app.recaptchaKey}
                onChange={input.onChange}
            />
            <FormHelperText error={isError}>
                {isError ? error || submitError : helperText}
            </FormHelperText>
        </FormControl>
    ) : null;
}
