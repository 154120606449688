import {Property} from 'ts-convict';

export default class FirebaseConfig implements config.FirebaseConfig {
    @Property({
        default: null,
        format: String,

        env: 'FIREBASE_API_KEY'
    })
    apiKey: string;

    @Property({
        default: null,
        format: String,

        env: 'FIREBASE_AUTH_DOMAIN'
    })
    authDomain: number;

    @Property({
        default: null,
        format: String,

        env: 'FIREBASE_DATABASE_URL'
    })
    databaseUrl: string;

    @Property({
        default: null,
        format: String,

        env: 'FIREBASE_PROJECT_ID'
    })
    projectId: string;

    @Property({
        default: null,
        format: String,

        env: 'FIREBASE_STORAGE_BUCKET'
    })
    storageBucket: string;

    @Property({
        default: null,
        format: String,

        env: 'FIREBASE_MESSAGING_SENDER_ID'
    })
    messagingSenderId: string;

    @Property({
        default: null,
        format: String,

        env: 'FIREBASE_APP_ID'
    })
    appId: string;

    @Property({
        default: null,
        format: String,

        env: 'FIREBASE_MEASUREMENT_ID'
    })
    measurementId: string;

    @Property({
        default: null,
        format: '*',

        env: 'FIREBASE_EMULATOR_FUNCTIONS'
    })
    functionEmulator: string;

    @Property({
        default: null,
        format: '*',

        env: 'FIREBASE_EMULATOR_FIRESTORE'
    })
    firestoreEmulator: string;

    @Property({
        default: null,
        format: '*',

        env: 'FIREBASE_EMULATOR_HOSTING'
    })
    hostingEmulator: string;

    @Property({
        default: null,
        format: '*',

        env: 'FIREBASE_EMULATOR_AUTH'
    })
    authEmulator: string;

}
