import {Button} from '@material-ui/core';
import {RenderActionsProps} from 'components/DealGridForm/components/EditDealModal';
import {runInAction} from 'mobx';
import React from 'react';
import {useDealDashboardStore} from 'views/Dashboard/Components/DealsDashboard/store/operatorStore';

interface EditDealActionButtons extends Pick<RenderActionsProps, 'onClose' | 'submitting' | 'success' | 'dirty' | 'submit' | 'btnEndIcons'> {

}

export default function EditDealActionButtons({onClose, submitting, success, dirty, submit, btnEndIcons,}: EditDealActionButtons) {
    const store = useDealDashboardStore();
    return (<>
        <Button onClick={onClose} color="default" disabled={submitting}>
            {success && !dirty ? 'Close' : 'Cancel'}
        </Button>
        <Button color={!store.isSendingPublishRequest && success ? 'secondary' : 'primary'} type="submit" onClick={submit} endIcon={store.isSendingPublishRequest ? null : btnEndIcons}
            disabled={!dirty || submitting}>
            Save draft
        </Button>
        <Button color={store.isSendingPublishRequest && success ? 'secondary' : 'primary'} type="submit" onClick={() => {
            runInAction(() => store.requestPublish = true);
            return submit();
        }} endIcon={store.isSendingPublishRequest ? btnEndIcons : null} disabled={submitting}>
            Submit for publishing
        </Button>
    </>);
}
