import {makeRequired, makeValidate} from 'mui-rff';
import {ValidationError} from 'mui-rff/dist/Validation';
import * as Yup from 'yup';
import {ObjectSchema, ObjectSchemaDefinition, SchemaDescription, Shape} from 'yup';

export type RequiredFields<T extends object | null | undefined> = ({
    [P in keyof T]: T[P] extends object ? RequiredFields<T[P]> : boolean
});

export type FieldLabels<T extends object | null | undefined> = ({
    [P in keyof T]: string
});

export type RffHelperArgs<T extends object> = {
    initialValues: T,
    schema: ObjectSchemaDefinition<T>,
    labels?: FieldLabels<T>,
};

export type RffHelperResult<T extends object> = {
    initialValues: T,
    schema: ObjectSchema<Shape<T | undefined, object>>,
    validate: (values: Shape<T, object>) => Promise<ValidationError>,
    required: RequiredFields<T>;
    labels: FieldLabels<T>;
    getLabel: (field: keyof T) => string;
    description: SchemaDescription;
};

function rffHelper<FormData extends object>({initialValues, schema,}: RffHelperArgs<FormData>): RffHelperResult<FormData> {
    const finalSchema = Yup.object().shape<FormData>(schema) as ObjectSchema<Shape<FormData | undefined, object>>;
    const description = finalSchema.describe();
    const labels: FieldLabels<FormData> = Object.entries(description.fields).reduce((res, [name, description]) => {
        return {
            ...res,
            [name]: (description as SchemaDescription).label || name
        };
    }, {} as FieldLabels<FormData>);
    return {
        initialValues,
        getLabel: (field) => (labels ? labels[field] : '') as string,
        labels,
        description,
        schema: finalSchema,
        validate: makeValidate(finalSchema),
        required: makeRequired(finalSchema)
    };
}

export default rffHelper;
