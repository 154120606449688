import {UserProfile} from '@jucy-nasse/types';
import {Auth0Result} from 'auth0-js';
import firebase from 'firebase';
import {action, computed, observable, runInAction, toJS} from 'mobx';
import {authentication} from 'services';
import Credentials from 'services/authentication/Credentials';
import {createLogger} from 'services/logger';

import RootStore from './RootStore';

const logger = createLogger({name: 'AuthStore'});

export default class AuthStore {

    rootStore: RootStore;
    @observable isLoading = true;
    @observable isSigningIn = true;
    @observable userCredential: firebase.auth.UserCredential;
    @observable user: UserProfile = new UserProfile();

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        authentication.checkSession().then((credentials) => {
            runInAction(() => {
                logger.debug('signedIn');
                this.credentials = credentials;
                this.isSigningIn = false;
                this.isLoading = false;
            });
        }).catch(result => {
            logger.debug('signedOut');
            runInAction(() => {
                this.isSigningIn = false;
                this.isLoading = false;
            });
        });
    }

    @observable _credentials?: Credentials;

    @computed get credentials() {
        return this._credentials;
    }

    set credentials(credentials) {
        this._credentials = credentials;
        this.user = UserProfile.fromAuth0User(credentials.userInfo);
    }

    @computed get isAuthenticated() {
        logger.debug({
            isLoading: this.isLoading !== undefined && toJS(this.isLoading),
            userCredential: this.userCredential !== undefined && toJS(this.userCredential),
            user: this.user !== undefined && toJS(this.user),
        });
        return Boolean(this.credentials?.valid);
    }

    // @action setCredentials(credentials: Credentials) {
    //     logger.database('setCredentials');
    //     this.credentials = credentials;
    //     this.user = UserProfile.fromAuth0User(credentials.userInfo);
    // }

    @action
    async changePassword(data: { password?: string, confirmPassword?: string }): Promise<UserProfile> {
        this.user = await authentication.changePassword(data);
        return this.user;
    }

    @action
    async setAuth0Result(auth0Result: Auth0Result): Promise<Credentials> {
        this.credentials = await authentication.setAuth0Result(auth0Result);
        return this.credentials;
    }

    passwordlessLogin(email: string): Promise<void> {
        return authentication.passwordlessLogin(email);
    }

    @action
    async updateProfile(data: Partial<UserProfile> | { password?: string, confirmPassword?: string }): Promise<UserProfile> {
        this.user = await authentication.updateProfile(data);
        return this.user;
    }

    @action
    async parseHash(): Promise<Credentials> {
        this.credentials = await authentication.parseHash();
        return this.credentials;
    }

    @action
    async checkSession(): Promise<Credentials> {
        this.credentials = await authentication.checkSession();
        return this.credentials;
    }

    signOut(backLink?: string) {
        return authentication.signOut(backLink);
    }

    @action
    async signupAndAuthorize(data: {
        email: string; password: string; name?: string, firstName?: string,
        lastName?: string,
    }): Promise<Credentials> {
        runInAction(() => {
            this.isSigningIn = true;
        });
        this.credentials = await authentication.signupAndAuthorize(data);
        runInAction(() => {
            this.isSigningIn = false;
        });
        return this.credentials;
    }

}
