import {Operator} from '@jucy-nasse/types';
import {runInAction} from 'mobx';
import React, {useEffect} from 'react';
import {useQuery} from 'react-query';
import rootStore from 'stores';
import {collection, getMany} from 'typesaurus';
import {createStore, OperatorStore} from './createStore';

const StoreContext = React.createContext<OperatorStore | null>(null);

function getOperatorsById(ids: string[]): Promise<Operator[]> {
    return getMany(collection<Operator>('operators'), ids).then(d => d.map(d => d.data)).then(d => d.filter((v, i) => i === 0));
}

export const OperatorStoreProvider = ({children}) => {
    const store = React.useMemo(() => createStore(), []);
    const query = useQuery(
        rootStore.operatorStore.operatorIds.join('-'),
        () => getOperatorsById(rootStore.operatorStore.operatorIds),
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
    useEffect(() => {
        runInAction(() => {
            if (store.refresh !== query.refetch) {
                store.refresh = query.refetch;
            }
            if (store.isFetching !== query.isFetching) {
                store.isFetching = query.isFetching;
            }
            if (!query.isFetching && query.data) {
                store.operators = query.data;
            }
            if (query.error && !store.error) {
                store.error = query.error;
            }
        });
    }, [query, store]);

    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

export const useOperatorStore = () => {
    const store = React.useContext(StoreContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useOperatorStore must be used within a OperatorStoreProvider.');
    }
    return store;
};
