import firebase from 'firebase';
import {attempt, isError} from 'lodash';
import {storage} from 'services/firebase';

export function convertBytesToMbsOrKbs(filesize) {
    let size = '';
    if (filesize >= 1000000) {
        size = (filesize / 1000000) + ' megabytes';
    } else if (filesize >= 1000) {
        size = (filesize / 1000) + ' kilobytes';
    } else {
        size = filesize + ' bytes';
    }
    return size;
}

export async function resolveImageFile(url: string) {
    let result: File | Error;
    if (url) {
        const ref = attempt(() => storage.ref(url));
        if (isError(ref)) {
            result = await attempt(() => createFileFromUrl(url));
        } else {
            result = await attempt(() => createFileFromRef(ref));
        }
    }
    return isError(result) ? null : result;
}

export async function createFileFromUrl(url: string) {
    let result: File = null;
    const response = await attempt(async () => await fetch(url).catch(e => e));
    if (!isError(response)) {
        const data = await response.blob();
        const metadata = {type: data.type};
        const filename = url.replace(/\?.+/, '').split('/').pop();
        result = new File([data], filename, metadata);
    }
    return result;
}

export async function createFileFromRef(ref: firebase.storage.Reference) {
    let result: File = null;
    const downloadURL = await attempt(async () => await ref.getDownloadURL().catch(e => e));
    const response = isError(downloadURL) ? downloadURL : await attempt(async () => fetch(downloadURL).catch(e => e));
    if (!isError(response)) {
        const data = await response.blob();
        const metadata = {type: data.type};
        const filename = ref.name;
        result = new File([data], filename, metadata);
    }
    return result;
}

export function readFile(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event?.target?.result);
        };
        reader.onerror = (event) => {
            reader.abort();
            reject(event);
        };
        reader.readAsDataURL(file);
    });
}
