import AccountSettingsModal from 'components/AccountSettingsModal/AccountSettingsModal';
import AppStateProvider from 'components/AppStateProvider';
import {action} from 'mobx';
import {Observer} from 'mobx-react-lite';
import 'mobx-react-lite/batchingForReactDom';
import moment from 'moment';
import React from 'react';
import Routes from './Routes';
import {RootStore} from './stores';

moment.locale('en-nz');

function App({rootStore}: { rootStore: RootStore }) {
    return (
        <AppStateProvider
            rootStore={rootStore}
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
            googleMapsApiLibraries={['places']}
        >
            <Routes/>
            <Observer>{() => (<>
                {rootStore.authStore.isAuthenticated && (
                    <AccountSettingsModal
                        profile={rootStore.authStore.user}
                        open={rootStore.appStateStore.profileOpen}
                        setOpen={action((val) => rootStore.appStateStore.profileOpen = val)}
                    />
                )}
            </>)}</Observer>
        </AppStateProvider>
    );
}

export default App;
