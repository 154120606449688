import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import {ExtendedTheme} from 'services/appearance';
import {Topbar} from './components';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    root: {
        paddingTop: theme.appBarHeight,
    },
    content: {
        height: '100%'
    }
}));

interface MinimalProps extends React.HTMLAttributes<HTMLDivElement> {
}

const Minimal: React.FunctionComponent<MinimalProps> = ({children, className, ...rest}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Topbar/>
            <main className={classes.content}>{children}</main>
        </div>
    );
};

export default Minimal;
