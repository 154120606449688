import {Alert, AlertTitle} from '@material-ui/lab';
import DataTable from 'components/DataTable/DataTable';
import {Observer} from 'mobx-react-lite'; // 6.x or mobx-react-lite@1.4.0
import React from 'react';
import {useDealDashboardStore} from 'views/Dashboard/Components/DealsDashboard/store/operatorStore';

const tableStyle = {
    padding: 0
};
export default function DealDataTable() {
    const store = useDealDashboardStore();
    return <Observer>{() => (<>
        {store.dealsError && (<Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Opps, we have run in to an unexpected error {store.dealsError.message}
        </Alert>)}
        {store && <DataTable
            style={tableStyle}
            columns={store.dealTableColumns}
            data={store.deals}
            isLoading={store.dealsLoading}
            title={store.titleComponent}
            editable={store.editable}
            components={store.components}
            actions={store.actions}/>}
    </>)}</Observer>;
}
