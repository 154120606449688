import {Operator} from '@jucy-nasse/types';
import EditIcon from '@material-ui/icons/Edit';
import DataTable from 'components/DataTable/DataTable';
import {useOperatorStore} from 'layouts/Dashboard/store/operatorStore';
import {Column} from 'material-table';
import {action} from 'mobx';
import {Observer} from 'mobx-react-lite';
import React from 'react';
import {OperatorDashboardState} from '../OperatorDashboard';

const operatorGridColumns: Column<Operator>[] = [
    {
        field: 'id',
        title: 'id',
        hidden: true,
    },
    {
        field: 'owners',
        title: 'owners',
        hidden: true,
    },
    {
        field: 'name',
        title: 'Name',
    },
    {
        field: 'type',
        title: 'Industry',
    },
    {
        field: 'primaryContact',
        title: 'Primary Contact',
    },
    {
        field: 'emailAddress',
        title: 'Email',
    },
    {
        field: 'phoneNumber',
        title: 'Phone',
    },
    {
        field: 'address.formatted',
        title: 'Address',
    }
];

export default function OperatorDataTable({state}: { state: OperatorDashboardState }) {
    const {operators, isFetching} = useOperatorStore();
    return <Observer>{() => (<>
        {<DataTable
            columns={operatorGridColumns}
            data={operators}
            isLoading={isFetching}
            title="Operators"
            actions={[
                {
                    icon: EditIcon,
                    tooltip: 'Edit operator',
                    onClick: action((event, rowData: Operator) => {
                        state.currentOperator = rowData;
                        state.modalOpen = true;
                    })
                }
            ]}
        />}
    </>)}</Observer>;
}
