import {FormHelperText, Grid, InputLabel, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {Autocomplete} from 'components/Autocomplete/Autocomplete';
import GoogleAddressAutoComplete from 'components/GoogleAddressAutoComplete/GoogleAddressAutoComplete';
import NotchedOutlineContainer from 'components/NotchedOutlineContainer';
import regionData from 'data/regions.json';
import {get, omit} from 'lodash';
import {TextField} from 'mui-rff';
import React, {MouseEvent, useMemo} from 'react';
import {useField, UseFieldConfig, useFormState} from 'react-final-form';
import {Address} from 'services/forms';
import {showError} from 'utils/RFFUtils';

const useStyles = makeStyles((theme) => ({
    root: {},
    toggleAddressModeButton: {
        marginTop: -theme.spacing(2)
    },
    gridRow: {
        marginBottom: theme.spacing(1),
    },
    disabled: {
        color: theme.palette.text.disabled,
        cursor: 'default',
        '&:hover': {
            textDecoration: 'none'
        }
    }
}));

interface AddressInputProps {
    name: string;
    label: string;
    variant?: 'filled' | 'outlined' | 'standard';
    disabled?: boolean;
    required?: boolean;
    fullWidth?: boolean;
    requestOptions?: Partial<google.maps.places.AutocompletionRequest>;
    hiddenFields?: string[];
    requiredFields: typeof Address.required
    fieldProps?: UseFieldConfig<any>
}

function AddressInput({requiredFields, hiddenFields = [], fieldProps, ...props}: AddressInputProps) {
    const classes = useStyles();
    const [addressAutoComplete, setAddressAutoComplete] = React.useState(true);
    const sharedProps = useMemo(() => ({...omit(props, ['label', 'hiddenFields', 'name', 'requestOptions']), fieldProps}), [props, fieldProps]);
    const field = useField(props.name);
    const disabled = props.disabled;
    const form = useFormState({
        onChange: (formState) => {
            const newAddress = get(formState.values, props.name) as Address.Fields;
            const newFormattedAddress = Address.formatAddress(newAddress);
            if (newAddress.formatted !== newFormattedAddress) {
                field.input.onChange({
                    ...newAddress,
                    formatted: newFormattedAddress,
                });
            }
        },
        subscription: {
            values: true
        }
    });
    const helperText = get(form.values, `${props.name}.formatted`);
    const isError = showError({meta: field.meta});
    const regions = useMemo(() => regionData.reduce((out, country) => [
        ...out,
        ...country.regions.map(region => (
            {
                title: region,
                country: country.name
            }
        ))
    ], []), []);
    return <div className={classes.root}>
        {addressAutoComplete ? (
            <GoogleAddressAutoComplete
                {...props}
                textFieldProps={{
                    ...omit(sharedProps, 'fieldProps'),
                    helperText: isError ? `${props.label} is required` : '',
                    error: isError,
                    inputProps: {
                        autoComplete: 'none',
                    }
                }}
            />
        ) : (
            <NotchedOutlineContainer>
                <InputLabel shrink={true}>{props.label || 'Address'}</InputLabel>
                <Grid container spacing={3} className={classes.gridRow}>
                    {!hiddenFields.includes(`${props.name}.streetAddress`) && (
                        <Grid item xs={12}>
                            <TextField
                                {...sharedProps}
                                required={requiredFields.streetAddress}
                                name={`${props.name}.streetAddress`}
                                label="Street address"
                                autoComplete="address-line1"
                            />
                        </Grid>
                    )}
                </Grid>
                {!hiddenFields.includes(`${props.name}.locality`) && (
                    <Grid container spacing={3} className={classes.gridRow}>
                        <Grid item xs>
                            <TextField
                                {...sharedProps}
                                required={requiredFields.locality}
                                name={`${props.name}.locality`}
                                label="City"
                                autoComplete="address-level2"
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={3} className={classes.gridRow}>
                    {!hiddenFields.includes(`${props.name}.administrativeAreaLevel1`) && (
                        <Grid item xs={8}>
                            <Autocomplete
                                {...sharedProps}
                                required={requiredFields.administrativeAreaLevel1}
                                name={`${props.name}.administrativeAreaLevel1`}
                                label="Region"
                                autoComplete={true}
                                options={regions}
                                groupBy={(option) => option && option.country}
                                getOptionLabel={(option) => (option && option.title) || ''}
                                getOptionValue={(option) => (option && option.title) || ''}
                                textFieldProps={{
                                    ...sharedProps,
                                    inputProps: {
                                        autoComplete: 'none',
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    {!hiddenFields.includes(`${props.name}.postalCode`) && (
                        <Grid item xs>
                            <TextField
                                {...sharedProps}
                                required={requiredFields.postalCode}
                                name={`${props.name}.postalCode`}
                                label="Post code"
                                autoComplete="postal-code"
                            />
                        </Grid>
                    )}
                </Grid>
                {!hiddenFields.includes(`${props.name}.country`) && (
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Autocomplete
                                {...sharedProps}
                                required={requiredFields.country}
                                name={`${props.name}.country`}
                                label="Country"
                                autoComplete={true}
                                options={regionData}
                                getOptionLabel={(option) => option.name || option}
                                getOptionSelected={(option, value) => (option.name || option) === value}
                                textFieldProps={{
                                    ...sharedProps,
                                    inputProps: {
                                        autoComplete: 'none',
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </NotchedOutlineContainer>
        )}
        <FormHelperText margin="dense" variant="outlined">
            <Link
                className={clsx(classes.toggleAddressModeButton, {[classes.disabled]: disabled})}
                onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    setAddressAutoComplete(!addressAutoComplete);
                }}
                component="button"
                disabled={disabled}
            >
                {addressAutoComplete ? (
                    <>Cant find your address? Enter it manually</>
                ) : (
                    <>Use address finder</>
                )}
            </Link>
        </FormHelperText>
        {helperText && (
            <FormHelperText>{helperText}</FormHelperText>
        )}
    </div>;
}

export default AddressInput;
