import {Box, CircularProgress} from '@material-ui/core';
import React, {ReactNode} from 'react';
import {QueryOptions, QueryResult, useQuery} from 'react-query';
import contentApi, {PageContentModel} from 'services/ContentApi';

export interface ContentPageProps {
    page: string;
    children: (page: PageContentModel, info: QueryResult<PageContentModel>) => ReactNode;
    loadingRenderer?: (info: QueryResult<PageContentModel>) => ReactNode;
    errorRenderer?: (error: Error, info: QueryResult<PageContentModel>) => ReactNode;
    queryOptions?: QueryOptions<PageContentModel>
}

export default function ContentPage({page, children, loadingRenderer, errorRenderer, queryOptions}: ContentPageProps) {
    const info = useQuery(page, () => contentApi.fetchPageContent(page), {refetchInterval: false, ...queryOptions});

    if (info.status === 'loading') {
        return <>{
            loadingRenderer ?
                loadingRenderer(info) :
                <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress/></Box>
        }</>;
    }

    if (info.status === 'error') {
        return <>{errorRenderer ? errorRenderer(info.error, info) : <span>Error: {info.error.message}</span>}</>;
    }
    return <>{children(info.data, info)}</>;
}
