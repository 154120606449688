import {DialogContent,} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {AccountTabForm} from 'components/AccountSettingsModal/components';
import {useAccountSettingsModalStore} from 'components/AccountSettingsModal/store/accountSettingsModalStore';
import {AccountTabStoreProvider} from 'components/AccountSettingsModal/store/accountTabStore';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            paddingTop: theme.spacing(2),
            width: 545
        },
        small: {
            width: theme.spacing(4),
            height: theme.spacing(4),

            minHeight: 'initial',
        },
    }));

function AccountTab() {
    const classes = useStyles();
    const settingStore = useAccountSettingsModalStore();
    return (
        <AccountTabStoreProvider settingsStore={settingStore}>
            <DialogContent classes={{root: classes.dialogContent}}>
                <AccountTabForm/>
            </DialogContent>
        </AccountTabStoreProvider>
    );
}

export default AccountTab;
