import {DealDiscountType} from '@jucy-nasse/types';

export function DealTypeLabel(type: DealDiscountType) {
    switch (type) {
        case DealDiscountType.AmountOff:
            return 'Amount off';
        // case DealType.fixed:
        //     return 'Fixed amount';
        case DealDiscountType.Other:
            return 'Other';
        case DealDiscountType.PercentOff:
            return 'Percent off';
        default:
            return '';
    }
}

export function DealTypeHelperText(type: DealDiscountType) {
    switch (type) {
        case DealDiscountType.AmountOff:
            return 'Enter your deals dollars off value in the field below';
        // case DealType.fixed:
        //     return 'Enter your deals fixed price amount in the field below';
        case DealDiscountType.Other:
            return 'Describe your deal within the description above';
        case DealDiscountType.PercentOff:
            return 'Enter your deals percent off in the field below';
        default:
            return '';
    }
}

export default DealDiscountType;
