import {Box, Button, FormHelperText, InputAdornment, PropTypes} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';
import AddressInput from 'components/AddressInput';
import ButtonCircularProgress from 'components/ButtonCircularProgress/ButtonCircularProgress';
import ImageUploadField from 'components/ImageUploadField/ImageUploadField';
import NotchedOutlineContainer from 'components/NotchedOutlineContainer/NotchedOutlineContainer';
import DealDiscountType, {DealTypeHelperText, DealTypeLabel} from 'data/DealDiscountType';
import regionData from 'data/regions.json';
import {DatePicker, Radios, TextField} from 'mui-rff';
import React from 'react';
import {FormRenderProps, FormSpy} from 'react-final-form';
import {DealDetails} from 'services/forms';
import {readFile} from 'utils/Files';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },

        buttons: {
            '& + button': {
                marginLeft: theme.spacing(3),
            }
        },
    }),
);

export interface DealDetailFormFieldsProps extends FormRenderProps<DealDetails.Fields> {
    dense?: boolean,
    formId?: string,
    hideActions?: boolean,
    children?: React.ReactNode
}

export default function DealDetailFormFields({dense, formId, hideActions, handleSubmit, children, submitting, submitFailed, hasValidationErrors, submitError, form}: DealDetailFormFieldsProps) {
    const sharedFieldProps: {
        fullWidth: boolean,
        variant: 'filled' | 'outlined' | 'standard'
        margin: PropTypes.Margin,
        disabled: boolean,
    } = {
        fullWidth: true,
        variant: 'outlined',
        margin: 'normal' as PropTypes.Margin,
        disabled: submitting,
    };
    const classes = useStyles();
    return (
        <form className={clsx({[classes.root]: !dense})} id={formId} onSubmit={handleSubmit} noValidate={true}>
            <TextField
                {...sharedFieldProps}
                label="Title"
                required={DealDetails.required.title}
                name="title"
                type="text"
            />
            <TextField
                {...sharedFieldProps}
                label="Description"
                required={DealDetails.required.description}
                name="description"
                type="text"
            />
            <ImageUploadField
                {...sharedFieldProps}
                label="Image"
                name="image"
                maxFileSize={3000000}
                filesLimit={1}
                onChange={async (files) => {
                    if (files && files.length) {
                        const result = await readFile(files[0]);
                        if (result) {
                            form.mutators.setFieldValue('previewUrl', result);
                        }
                    } else {
                        form.mutators.setFieldValue('previewUrl', null);
                    }
                }}
                helperText={<> Max image size: 3 MB<br/>
                    Accepted formats: jpg/png/webp<br/>
                    Recommended dimensions: 380px x 214px</>}
            />
            <NotchedOutlineContainer label="Discount type *" margin="normal" disabled={sharedFieldProps.disabled}>
                <Radios
                    required={DealDetails.required.discountType}
                    name="discountType"
                    disabled={sharedFieldProps.disabled}
                    radioGroupProps={{
                        classes: {
                            root: 'MuiFormGroup-row'
                        }
                    }}
                    data={Object.values(DealDiscountType).map(key => (
                        {value: key, label: DealTypeLabel(key)}
                    ))}
                />
                <FormSpy subscription={{values: true}}>
                    {({values}) => {
                        return (<>
                            <FormHelperText>
                                {DealTypeHelperText(values.discountType)}
                            </FormHelperText>
                            {values.discountType !== DealDiscountType.Other && (
                                <TextField
                                    {...sharedFieldProps}
                                    label={DealTypeLabel(values.discountType)}
                                    required={DealDetails.required.discountType}
                                    name="discountAmount"
                                    type="number"
                                    InputProps={{
                                        startAdornment: values.discountType === DealDiscountType.AmountOff ? (<InputAdornment position="start">$</InputAdornment>) : undefined,
                                        endAdornment: values.discountType === DealDiscountType.PercentOff ? (<InputAdornment position="end">%</InputAdornment>) : undefined
                                    }}
                                />
                            )}

                        </>);
                    }}
                </FormSpy>
            </NotchedOutlineContainer>
            <TextField
                {...sharedFieldProps}
                label="Deal URL"
                required={DealDetails.required.detailsLink}
                name="detailsLink"
                type="url"
            />
            <AddressInput
                {...sharedFieldProps}
                required={false}
                label="Product Physical Address"
                requiredFields={DealDetails.required.address}
                name="address"
                requestOptions={{
                    componentRestrictions: {
                        country: regionData.map(c => c.code)
                    }
                }}
            />

            <DatePicker
                {...sharedFieldProps}
                label="Expiry date"
                required={false}
                name="expiryDate"
                disablePast={true}
                autoOk={true}
                variant="inline"
                inputVariant={sharedFieldProps.variant}
            />
            {children}
            {!hideActions && (
                <Box textAlign="right">
                    <Button
                        className={classes.buttons}
                        disabled={submitting}
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Next <ButtonCircularProgress isSubmitting={submitting}/>
                    </Button>
                </Box>
            )}
            {submitError && (
                <FormHelperText error={true}>
                    Opps! We were unable to submit your request.<br/>
                    {submitError}
                </FormHelperText>
            )}
            {(submitFailed && hasValidationErrors) && !submitting && (
                <FormHelperText error={true}>
                    Opps! We were unable to submit your request, please correct any warnings above
                </FormHelperText>
            )}
        </form>
    );
}
