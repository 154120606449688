import config from 'services/config';
import urlJoin from 'url-join';
import parse from 'url-parse';

export function makeCdnUrl(url, options) {
    const parsedUrl = parse(url);
    const queryString = options ? `?${Object.entries(options)
        .map(ops => ops.join('='))
        .join('&')}` : '';

    return `/cdn${parsedUrl.pathname}${queryString}`;
}

export function formatDealImageUrl(url: string) {
    const baseUrl = window.location.hostname === 'localhost' ? 'https://deals.test.jucy.com/' : config.app.homePage;
    if (!url) {
        return url;
    }
    return url.startsWith('http') ? url : urlJoin(baseUrl, makeCdnUrl(url, {width: 360, height: 203}));
}
