import {Box, Button, Container, Snackbar} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import React from 'react';
import rootStore from 'stores';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    btnContainer: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    container: {
        paddingTop: theme.spacing(2),
    }
}));

function Dev() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Container className={classes.container} maxWidth="md">
            <div className={classes.root}>
                <Box className={classes.btnContainer}>
                    <Button variant="outlined" onClick={handleClick}>
                        Open success snackbar
                    </Button>
                    <Button variant="outlined" onClick={() => rootStore.notificationStore.error('error message', 'Error title')}>
                        Open error
                    </Button>
                    <Button variant="outlined" onClick={() => rootStore.notificationStore.warning('warning message', 'Warning title')}>
                        Open warning
                    </Button>
                    <Button variant="outlined" onClick={() => rootStore.notificationStore.info('info message', 'Info title')}>
                        Open info
                    </Button>
                    <Button variant="outlined" onClick={() => rootStore.notificationStore.success('success message', 'Success title')}>
                        Open success
                    </Button>
                </Box>

                <Alert severity="error">This is an error message!</Alert>
                <Alert severity="warning">This is a warning message!</Alert>
                <Alert severity="info">This is an information message!</Alert>
                <Alert severity="success">This is a success message!</Alert>

            </div>
            <Snackbar open={open} autoHideDuration={1000000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    success
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default Dev;
