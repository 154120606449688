import {Auth0Result, Auth0UserProfile} from 'auth0-js';
import firebase from 'firebase';
import {observable} from 'mobx';

export default class Credentials {
    @observable auth0Result: Auth0Result;
    @observable userCredential: firebase.auth.UserCredential;
    @observable userInfo: Auth0UserProfile;
    @observable expiresAt: number;
    @observable claims: Record<string, any>;

    constructor({auth0Result, userCredential, userInfo, claims}: { auth0Result: Auth0Result, userCredential: firebase.auth.UserCredential, userInfo: Auth0UserProfile; claims: Record<string, any> }) {
        this.expiresAt = auth0Result.expiresIn * 1000 + new Date().getTime();
        this.auth0Result = auth0Result;
        this.userCredential = userCredential;
        this.userInfo = userInfo;
        this.claims = claims;
    }

    get expiresIn() {
        const expiresAt = this.expiresAt || 0;
        return expiresAt - Date.now();
    }

    get expired() {
        return this.expiresIn < 0;
    }

    get valid() {
        return Boolean(this.auth0Result && this.userCredential && !this.expired);
    }

    async refreshClaims(force: boolean): Promise<Record<string, any>> {
        this.claims = await this.userCredential.user.getIdTokenResult(force).then(r => r.claims);
        return this.claims;
    }

}
