import {CircularProgress} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
    center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

function LaunchScreen({text}: { text?: string }) {
    const classes = useStyles();

    return (
        <div className={classes.center}>
            <CircularProgress/>
            {text ? text : 'no text'}
        </div>
    );
}

export default LaunchScreen;
