import {Avatar, Box, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Typography,} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import React from 'react';
import {FormRenderProps, FormSpy} from 'react-final-form';
import {DealDetails, FormProgress} from 'services/forms';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            width: 600,
            maxWidth: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        avatarComplete: {
            backgroundColor: theme.palette.secondary.main,
        },
        avatarBg: {
            transition: theme.transitions.create('background-color', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        itemText: {
            transition: theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        mainAvatar: {
            width: 70,
            height: 70,
            marginBottom: theme.spacing(2),

        },
        mainIcon: {
            width: 55,
            height: 55
        }
    }),
);

export interface DealDetailProgressState {
    isVerifying: boolean;
    hasVerified: boolean;
    isUploading: boolean;
    hasUploaded: boolean;
    isSaving: boolean;
    hasSaved: boolean;
    isComplete: boolean;
}

const defaultCalculateProgress = (progress: FormProgress): DealDetailProgressState => {
    return {
        isVerifying: !progress,
        hasVerified: Boolean(progress),
        isUploading: progress && progress.percent === 33,
        hasUploaded: progress && progress.percent > 33,
        isSaving: progress && progress.percent === 66,
        hasSaved: progress && progress.percent > 66,
        isComplete: progress && progress.percent === 100,
    };
};

export interface DealDetailProgressProps extends FormRenderProps<DealDetails.Fields> {
    progress: FormProgress,
    calculateProgress?: (progress: FormProgress) => DealDetailProgressState
    renderProgressItems?: (state: DealDetailProgressState, progress: FormProgress, children: React.ReactElement) => React.ReactNode
}

function ProgressItems({state, progress}: { state: DealDetailProgressState, progress: FormProgress }) {
    const classes = useStyles();
    return (<>
        <ListItem className={classes.itemText}>
            <ListItemAvatar>
                <Avatar className={clsx(classes.avatarBg, {[classes.avatarComplete]: state.hasVerified})}>
                    {state.hasVerified ? <CheckCircleIcon className="animated fadeIn"/> : state.isVerifying ? <CircularProgress className="animated fadeIn"/> : <span/>}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Verifying deal" secondary={state.isVerifying ? 'Verifying' : state.hasVerified ? 'Verified' : ''}/>
        </ListItem>
        <FormSpy subscription={{values: true}}>
            {({values}) => (<>
                {values.image ? (
                    <ListItem disabled={!state.hasVerified} className={classes.itemText}>
                        <ListItemAvatar>
                            <Avatar className={clsx({[classes.avatarComplete]: state.hasUploaded})}>
                                {state.hasUploaded ? <CheckCircleIcon className="animated fadeIn"/> : state.isUploading ? <CircularProgress className="animated fadeIn" variant="static"
                                    value={(progress.snapshot.bytesTransferred / progress.snapshot.totalBytes) * 100}/> :
                                    <span/>}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Uploading deal image" secondary={
                            state.isUploading ? `${progress.snapshot.bytesTransferred} of ${progress.snapshot.totalBytes} bytes transferred` : state.hasUploaded ? 'Image uploaded' : ''
                        }/>
                    </ListItem>
                ) : null}
                <ListItem disabled={!state.hasUploaded} className={classes.itemText}>
                    <ListItemAvatar>
                        <Avatar className={clsx({[classes.avatarComplete]: state.hasSaved})}>
                            {state.hasSaved ? <CheckCircleIcon className="animated fadeIn"/> : state.isSaving ? <CircularProgress className="animated fadeIn"/> : <span/>}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Saving deal" secondary={state.isSaving ? 'Saving' : state.hasSaved ? `${values.title} saved` : ''}/>
                </ListItem>
            </>)}
        </FormSpy>
    </>);
}

export default function DealDetailProgress({progress, calculateProgress, renderProgressItems}: DealDetailProgressProps) {
    const classes = useStyles();
    const state = calculateProgress ? calculateProgress(progress) : defaultCalculateProgress(progress);
    const progressItems = <ProgressItems state={state} progress={progress}/>;
    return (
        <form className={classes.root}>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Avatar className={clsx(classes.mainAvatar, {[classes.avatarComplete]: state.isComplete})}>
                    {!state.isComplete && (<CircularProgress size={70}/>)}
                    {state.isComplete && (<CheckCircleIcon className={clsx('animated fadeIn', classes.mainIcon)}/>)}
                </Avatar>
                <Typography variant="h6" component="span">
                    Saving your deal
                </Typography>
            </Box>
            <List>
                {renderProgressItems ? renderProgressItems(state, progress, progressItems) : progressItems}
            </List>
        </form>
    );
}
