import {Avatar, Badge, Box, Button, CircularProgress, Fab, Fade, Tooltip} from '@material-ui/core';
import {red} from '@material-ui/core/colors';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Close as CloseIcon, CloudUpload as CloudUploadIcon, Person as PersonIcon, Photo as PhotoIcon} from '@material-ui/icons';
import React, {ChangeEvent} from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    // dialogContent: {
    //     paddingTop: theme.spacing(2),
    //     width: 545
    // },

    badge: {
        top: theme.spacing(2),
        right: -theme.spacing(2),
    },

    loadingBadge: {
        top: '50%',
        right: '50%',
    },

    avatar: {
        marginRight: 'auto',
        marginLeft: 'auto',

        width: theme.spacing(14),
        height: theme.spacing(14),
    },

    // nameInitials: {
    //     cursor: 'default',
    // },

    personIcon: {
        fontSize: theme.spacing(7),
    },

    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),

        minHeight: 'initial',
    },
    removeBtn: {
        color: theme.palette.getContrastText(red[700]),
        backgroundColor: red[700],
        '&:hover': {
            backgroundColor: red[900],
        },
    }
}));

export interface AvatarEditorProps {
    picture?: string;
    pictureFile?: File;
    onRemove: () => void;
    onSave: () => void;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
    loading: boolean;
}

export default function AvatarEditor({picture, pictureFile, onRemove, onChange, onSave, disabled, loading}: AvatarEditorProps) {
    const classes = useStyles();
    return (<>
        <Box mb={1.5}>

            {picture && (
                <Badge
                    classes={{badge: classes.badge}}
                    badgeContent={
                        <Tooltip title="Remove">
                            <Fab
                                classes={{sizeSmall: classes.small}}
                                color="inherit"
                                disabled={disabled}
                                size="small"
                                onClick={onRemove}
                                className={classes.removeBtn}
                            >
                                <CloseIcon fontSize="small"/>
                            </Fab>
                        </Tooltip>
                    }
                >
                    {loading && (
                        <Badge
                            classes={{badge: classes.loadingBadge}}
                            badgeContent={
                                <Fade
                                    style={{transitionDelay: '1s'}}
                                    in={loading}
                                    unmountOnExit
                                >
                                    <CircularProgress size={120} thickness={1.8}/>
                                </Fade>
                            }
                        >
                            <Avatar
                                className={classes.avatar}
                                alt="Avatar"
                                src={picture}
                            />
                        </Badge>
                    )}

                    {!loading && (
                        <Avatar
                            className={classes.avatar}
                            alt="Avatar"
                            src={picture}
                        />
                    )}
                </Badge>
            )}

            {!picture && (
                <>
                    {picture && (
                        <Badge
                            classes={{badge: classes.badge}}
                            badgeContent={
                                <Tooltip title="Remove">
                                    <Fab
                                        classes={{sizeSmall: classes.small}}
                                        color="secondary"
                                        disabled={disabled}
                                        size="small"
                                        onClick={onRemove}
                                        className={classes.removeBtn}
                                    >
                                        <CloseIcon fontSize="small"/>
                                    </Fab>
                                </Tooltip>
                            }
                        >
                            {loading && (
                                <Badge
                                    classes={{badge: classes.loadingBadge}}
                                    badgeContent={
                                        <Fade
                                            style={{transitionDelay: '1s'}}
                                            in={loading}
                                            unmountOnExit
                                        >
                                            <CircularProgress
                                                size={120}
                                                thickness={1.8}
                                            />
                                        </Fade>
                                    }
                                >
                                    <Avatar
                                        className={classes.avatar}
                                        alt="Avatar"
                                        src={picture}
                                    />
                                </Badge>
                            )}

                            {!loading && (
                                <Avatar
                                    className={classes.avatar}
                                    alt="Avatar"
                                    src={picture}
                                />
                            )}
                        </Badge>
                    )}

                    {!picture && (
                        <>
                            {loading && (
                                <Badge
                                    classes={{badge: classes.loadingBadge}}
                                    badgeContent={
                                        <Fade
                                            style={{transitionDelay: '1s'}}
                                            in={loading}
                                            unmountOnExit
                                        >
                                            <CircularProgress
                                                size={120}
                                                thickness={1.8}
                                            />
                                        </Fade>
                                    }
                                >
                                    <Avatar className={classes.avatar} alt="Avatar">
                                        <PersonIcon className={classes.personIcon}/>
                                    </Avatar>
                                </Badge>
                            )}

                            {!loading && (
                                <Avatar className={classes.avatar} alt="Avatar">
                                    <PersonIcon className={classes.personIcon}/>
                                </Avatar>
                            )}
                        </>
                    )}
                </>
            )}
        </Box>
        {pictureFile && picture && (
            <Button
                color="primary"
                disabled={loading}
                startIcon={<CloudUploadIcon/>}
                variant="contained"
                onClick={onSave}
            >
                Upload
            </Button>
        )}

        {!pictureFile && !picture && (
            <>
                <input
                    name="pictureFile"
                    id="avatar-input"
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={onChange}
                />

                <label htmlFor="avatar-input">
                    <Button
                        color="primary"
                        component="span"
                        disabled={loading}
                        startIcon={<PhotoIcon/>}
                        variant="contained"
                    >
                        Choose...
                    </Button>
                </label>
            </>
        )}

        {false && !picture && (
            <>
                {loading && (
                    <Badge
                        classes={{badge: classes.loadingBadge}}
                        badgeContent={
                            <Fade
                                style={{transitionDelay: '1s'}}
                                in={loading}
                                unmountOnExit
                            >
                                <CircularProgress size={120} thickness={1.8}/>
                            </Fade>
                        }
                    >
                        <Avatar className={classes.avatar} alt="Avatar">
                            <PersonIcon className={classes.personIcon}/>
                        </Avatar>
                    </Badge>
                )}

                {!loading && (
                    <Avatar className={classes.avatar} alt="Avatar">
                        <PersonIcon className={classes.personIcon}/>
                    </Avatar>
                )}
            </>
        )}
    </>);
}
