import {LaunchScreen} from 'layouts';
import React, {useEffect} from 'react';
import rootStore from 'stores';

export default function Auth0SignOutHandler() {
    useEffect(() => {
        const returnTo = localStorage.getItem('sign-out-return-to');
        localStorage.removeItem('sign-out-return-to');
        rootStore.routerStore.redirectBack(returnTo);
    }, []);
    return <LaunchScreen text="Signing out"/>;
}
