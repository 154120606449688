import config from 'services/config';

export type PageContentModel = {
    parentID?: number;
    title: string;
    content: string;
    id: number;
};

class ContentApi {
    async fetchPageContent(path: string): Promise<PageContentModel> {
        return fetch(`${config.contentApi.baseUrl}${path}`).then(r => r.json());
    }
}

const contentApi = new ContentApi();
export default contentApi;
