import {Badge, CircularProgress, TablePagination, TablePaginationProps, Tooltip} from '@material-ui/core';
import {AddBox} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import HelpIcon from '@material-ui/icons/Help';
import {DealDetailProgressState} from 'components/DealDetailForm/components/DealDetailProgress';
import {onDealAdded, onDealRemoved} from 'components/DealGridForm/OperatorDealGrid';
import {FormApi, SubmissionErrors} from 'final-form';
import firebase from 'firebase';
import {Action, Column, Components, MTableBody, MTableHeader} from 'material-table';
import {action, IValueDidChange, observable, observe, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import cloudFunctions from 'services/CloudFunctions';
import {firestore as db} from 'services/firebase';
import {docToModel} from 'services/firestoreService';
import {DealDetails, FormProgress} from 'services/forms';
import {Looper} from 'utils';
import SaveDealProgress from 'views/Dashboard/Components/DealsDashboard/Components/SaveDealProgress';
import {DealImageCell, TableAction, TableGridBody, TableTitle, TableToolbar} from './../Components';

export class DealsDashboardStore {

    @observable operatorId;
    @observable dealsLoading = false;
    @observable publishedLoading = false;
    @observable dealsError?: Error;
    @observable publishedError?: Error;
    @observable deals: DealDetails.Fields[] = [];
    @observable publishedDeals: DealDetails.Fields[] = [];
    @observable dealDocs: firebase.firestore.QueryDocumentSnapshot[];
    @observable publishedDealDocs: firebase.firestore.QueryDocumentSnapshot[];
    @observable currentDeal?: DealDetails.Fields;
    @observable modalOpen = false;
    @observable requestPublish = false;
    @observable isSendingPublishRequest = false;
    @observable hasSentPublishRequest = false;
    @observable view: 'list' | 'grid' = 'grid';
    titleComponent: React.ReactElement;
    dealTableColumns: Column<DealDetails.Fields>[] = [
        {
            field: 'id',
            title: 'id',
            hidden: true,
        },
        {
            field: 'title',
            title: 'Title',
        },
        {
            field: 'imageUrl',
            title: 'Image',
            render: rowData => {
                return <DealImageCell imageUrl={rowData.previewUrl}/>;
            }
        },
        {
            field: 'status',
            title: 'Status',
            render: rowData => {
                if (this.publishedError) {
                    return <Tooltip title={this.publishedError.message}><HelpIcon/></Tooltip>;
                }
                if (!this.publishedDeals && this.publishedLoading) {
                    return <CircularProgress size={20}/>;
                }

                const publishedDealDoc = this.publishedDealDocs.find(p => p.id === rowData.id);
                if (publishedDealDoc) {
                    const dealDoc = this.dealDocs.find(p => p.id === rowData.id);
                    const published = publishedDealDoc.data().datePublished && moment(publishedDealDoc.data().datePublished.toDate());
                    let hideBadge = true;
                    let result = 'Published';
                    let tooltip = 'Published';
                    if (published) {
                        tooltip += ` on ${published.format('DD/MM/YYYY')}`;
                    }
                    if (dealDoc.data().dateUpdated && published) {
                        const updated = moment(dealDoc.data().dateUpdated.toDate());
                        if (updated > published) {
                            hideBadge = false;
                            tooltip = ` with changes made on ${published.format('DD/MM/YYYY')} in review`;
                        }
                    }
                    return <Tooltip title={tooltip}><Badge anchorOrigin={{vertical: 'top', horizontal: 'left',}} color="error" variant="dot" invisible={hideBadge}>
                        {result}
                    </Badge></Tooltip>;

                } else {
                    return 'In review';
                }
            },
        },
        {
            field: 'expiryDate',
            title: 'Expiry date',
            render: rowData => rowData.expiryDate ? rowData.expiryDate.format('DD/MM/YYYY') : <em>none</em>,
        },
        {
            field: 'address.formatted',
            title: 'Address',
        }
    ];
    editable = {
        isDeletable: deal => Boolean(deal.id),
        onRowDelete: async (deal) => {
            await onDealRemoved(deal, this.operatorId);
            await this.loadDeals();
        }
    };
    components: Components = {
        Action: (props) => {
            return <TableAction {...props}/>;
        },
        Toolbar: (props) => {
            return <TableToolbar {...props} />;
        },
        Header: observer((props) => {
            if (this.view === 'grid') {
                return null;
            } else {
                return <MTableHeader {...props}/>;
            }
        }),
        Body: observer(props => {
            if (this.view === 'grid') {
                return <TableGridBody {...props}/>;
            } else {
                return <MTableBody {...props}/>;
            }
        }),
        Pagination: observer((props: TablePaginationProps) => {
            if (this.view === 'grid') {
                return null;
            } else {
                return <TablePagination {...props}/>;
            }
        }),
    };
    actions: (Action<DealDetails.Fields> | ((rowData: DealDetails.Fields) => Action<DealDetails.Fields>))[] = [
        {
            icon: EditIcon,
            tooltip: 'Edit deal',
            onClick: action((event, rowData: DealDetails.Fields) => {
                this.modalOpen = true;
                this.currentDeal = rowData;
            })
        },
        {
            icon: AddBox,
            tooltip: 'Add deal',
            isFreeAction: true,
            onClick: () => {
                this.modalOpen = true;
            }
        }
    ];

    constructor(operatorId?: string) {
        this.titleComponent = <TableTitle/>;
        observe(this, 'operatorId', (change: IValueDidChange<string>) => {
            if (change.type === 'update' && change.oldValue !== change.newValue) {
                this.loadDeals().catch(e => this.dealsError = e);
                this.loadPublishedDeal().catch(e => this.publishedError = e);
            }
        }, true);

        observe(this, 'requestPublish', (change: IValueDidChange<boolean>) => {
            if (change.type === 'update') {
                this.hasSentPublishRequest = false;
                this.isSendingPublishRequest = false;
            }
        }, true);

        if (operatorId) {
            this.operatorId = operatorId;
        }
    }

    @action setOperatorId(operatorId: string) {
        if (operatorId && operatorId !== this.operatorId) {
            this.operatorId = operatorId;
            this.loadDeals().catch(e => this.dealsError = e);
            this.loadPublishedDeal().catch(e => this.publishedError = e);
        }
    }

    @action closeModal = () => {
        this.currentDeal = null;
        this.modalOpen = false;
    };

    @action
    async loadDeals() {
        this.dealsLoading = true;
        const operatorDealsSnap = await db.collection(`operators/${this.operatorId}/deals`).get();
        this.dealDocs = operatorDealsSnap.empty ? [] : operatorDealsSnap.docs;
        this.deals = await Promise.all(operatorDealsSnap.docs.map(docToModel).map(DealDetails.mapDealToFields));
        this.dealsLoading = false;
        return this.deals;
    }

    @action
    async loadPublishedDeal() {
        this.publishedLoading = true;
        const operatorPublishedDealsSnap = await db.collection('/deals').where('operatorId', '==', this.operatorId).get();
        this.publishedDealDocs = operatorPublishedDealsSnap.empty ? [] : operatorPublishedDealsSnap.docs;
        this.publishedDeals = await Promise.all(operatorPublishedDealsSnap.docs.map(docToModel).map(DealDetails.mapDealToFields));
        this.publishedLoading = false;
        return this.publishedDeals;
    }

    calculateSubmitProgress = (progress: FormProgress, store: DealsDashboardStore): DealDetailProgressState => {
        return {
            isVerifying: !progress,
            hasVerified: Boolean(progress),
            isUploading: progress && progress.percent === 33,
            hasUploaded: progress && progress.percent > 33,
            isSaving: progress && progress.percent === 66,
            hasSaved: progress && progress.percent > 66,
            isComplete: progress && store.hasSentPublishRequest && progress.percent === 100,
        };
    };

    renderProgressItems(state: DealDetailProgressState, progress: FormProgress, children: React.ReactElement) {
        return <SaveDealProgress state={state} progress={progress}>{children}</SaveDealProgress>;
    }

    onDealSaved = async (
        values: DealDetails.Fields,
        form: FormApi<DealDetails.Fields>,
        callback?: (errors?: SubmissionErrors) => void,
        onProgress?: (state: FormProgress) => void,
    ): Promise<SubmissionErrors | DealDetails.Fields> => {
        const deal = await onDealAdded(values, this.operatorId, onProgress);
        if (this.requestPublish) {
            runInAction(() => {
                this.requestPublish = false;
                this.isSendingPublishRequest = true;
            });
            await cloudFunctions.sendPublishRequest(this.operatorId, deal.id);
            runInAction(() => {
                this.isSendingPublishRequest = false;
                this.hasSentPublishRequest = true;
            });
            await Looper(async (t) => t === 2);
        }
        await this.loadDeals();
        return deal;
    };
}

export function createStore(operatorId?: string) {
    return new DealsDashboardStore(operatorId);
}

export type TStore = ReturnType<typeof createStore>;
