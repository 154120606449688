import {Avatar, CircularProgress, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import {DealDetailProgressState} from 'components/DealDetailForm/components/DealDetailProgress';
import {useObserver} from 'mobx-react-lite';
import React from 'react';
import {FormProgress} from 'services/forms';
import {useDealDashboardStore} from 'views/Dashboard/Components/DealsDashboard/store/operatorStore';

const useStyles = makeStyles((theme) => ({
    avatarComplete: {
        backgroundColor: theme.palette.secondary.main,
    },
    itemText: {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
        }),
    },
}));

interface SaveDealProgressProps {
    state: DealDetailProgressState,
    progress: FormProgress,
    children: React.ReactElement
}

export default function SaveDealProgress({state, progress, children}: SaveDealProgressProps) {
    const classes = useStyles();
    const store = useDealDashboardStore();
    return useObserver(() => (<>
        {children}
        {store.isSendingPublishRequest && (
            <ListItem disabled={!state.hasSaved} className={classes.itemText}>
                <ListItemAvatar>
                    <Avatar className={clsx({[classes.avatarComplete]: store.hasSentPublishRequest})}>
                        {store.hasSentPublishRequest ? <CheckCircleIcon className="animated fadeIn"/> : store.isSendingPublishRequest ? <CircularProgress className="animated fadeIn"/> : <span/>}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Request publication" secondary={'Sending publish request'}/>
            </ListItem>
        )}
    </>));
}
