import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {RouteWithLayout} from './components';
import {Dashboard as DashboardLayout, Main as MainLayout, Minimal as MinimalLayout} from './layouts';

import {
    Dashboard as DashboardView, Dev as DevView, NotFound as NotFoundView, OperatorDealsWizard as OperatorDealsWizardView, SignIn as SignInView, SignInLite as SignInLiteView, SignOut as SignOutView
} from './views';

const Routes = () => (
    <Switch>
        <Redirect
            exact
            from="/"
            to="/home"
        />
        <RouteWithLayout
            component={OperatorDealsWizardView}
            exact
            layout={MainLayout}
            path="/home"
        />

        <RouteWithLayout
            component={DevView}
            exact
            layout={MainLayout}
            path="/dev"
        />

        <RouteWithLayout
            component={SignInLiteView}
            exact
            layout={MinimalLayout}
            path="/email-sign-in"
        />
        <Route
            component={SignInView}
            path="/sign-in"
        />
        <Route
            component={SignOutView}
            path="/sign-out"
        />
        <RouteWithLayout
            component={NotFoundView}
            exact
            layout={MinimalLayout}
            path="/not-found"
        />
        <RouteWithLayout
            isProtected={true}
            component={DashboardView}
            layout={DashboardLayout}
            path="/dashboard"
        />
        <RouteWithLayout
            component={NotFoundView}
            layout={MinimalLayout}
        />
    </Switch>
);

export default Routes;
