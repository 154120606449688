import {Avatar, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        }

    });
});

export interface IconTitle {
    icon: React.ReactNode,
    title: React.ReactNode,
    subtitle?: React.ReactNode,
}

export default function IconTitle({icon, title, subtitle}: IconTitle) {
    const classes = useStyles();
    return (<div className={classes.root}>
        <Avatar className={classes.avatar}>
            {icon}
        </Avatar>
        <Typography component="h1" variant="h5">
            {title}
        </Typography>
        {subtitle && (
            <Typography component="h2" variant="subtitle1">
                {subtitle}
            </Typography>
        )}
    </div>);
}
