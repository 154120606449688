import {Operator} from '@jucy-nasse/types';
import {Box, CircularProgress, CssBaseline, Grid, Paper, useMediaQuery} from '@material-ui/core';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import firestoreService, {operatorCollection} from 'services/firestoreService';
import rootStore from 'stores/index';
import {Copyright, SignUpStepper, WelcomeCard} from './components';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            flexGrow: 1,
        },
        left: {
            flexGrow: 1,
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        right: {
            flexGrow: 0,
            width: 900,
        },
        image: {
            position: 'sticky',
            top: 75,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundImage: 'url(https://storage.googleapis.com/cms-assets-jucy-oconee-prod-28a1ed/public/NZ-LIFESTYLE-IMAGERY/3100-x-2074_COVER/CAMPERS/CHASER/couple-enjoy-scenery-against-chaser-in-winter.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            height: 'calc(100vh - 75px)'
        },
        paper: {
            padding: theme.spacing(4, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: '1 1 auto',
            overflow: 'auto',
            minHeight: '100%'
        },
        top: {
            width: '100%',
        },
        bottom: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginTop: 'auto'
        },
        infoContainer: {
            position: 'sticky',
            top: 75,
            zIndex: 1,
        },
    });
});

const OperatorDealsWizard = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const [operator, setOperator] = useState<Operator>(null);
    const [operatorId] = useState(rootStore.operatorStore.defaultOperatorId);
    const [loading, isLoading] = React.useState(Boolean(!operator && operatorId));

    useEffect(() => {
        if (rootStore.authStore.isAuthenticated && rootStore.operatorStore.defaultOperatorId) {
            rootStore.routerStore.history.replace(`/dashboard/operators/${rootStore.operatorStore.defaultOperatorId}/deals`);
        }
    }, []);
    useEffect(() => {
        const loadOperator = async () => {
            isLoading(true);
            try {
                const fetchedOperator = await firestoreService.get(operatorCollection, operatorId);
                if (fetchedOperator) {
                    setOperator(fetchedOperator);
                }
            } catch (error) {
                Sentry.captureException(error);
                console.error('Error getting document:', error);
            }
            isLoading(false);
        };
        if (operatorId && (!operator || get(operator, 'id') !== operatorId)) {
            loadOperator();
        }
    }, [operator, operatorId]);
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>

            <Grid item className={classes.left}>
                <Box display="flex" justifyContent="center" className={classes.infoContainer}>
                    <WelcomeCard/>
                </Box>
                {!isSm && (
                    <Grid item className={`${classes.left} ${classes.image}`}/>
                )}
            </Grid>
            <Grid item component={Paper} elevation={6} square className={classes.right}>
                <Box className={classes.paper} display="flex" overflow="auto">
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress/></Box>
                    ) : (
                        <Box className={classes.top}>
                            <SignUpStepper operator={operator} setOperator={setOperator}/>
                        </Box>
                    )}
                    <Box className={classes.bottom} mt={5}>
                        <Copyright/>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default OperatorDealsWizard;
