import {action, IObservableArray, observable} from 'mobx';
import {OptionsObject, SnackbarKey, SnackbarMessage} from 'notistack';
import {RootStore} from 'stores';

type Notification = {
    title?: string,
    message: SnackbarMessage,
    options?: OptionsObject
};

export default class NotificationStore {
    readonly notifications: IObservableArray<Notification> = observable.array([]);
    displayedNotifications: SnackbarKey[] = [];
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    addNotification(message: SnackbarMessage, title?: string, options?: OptionsObject)
    addNotification(message: SnackbarMessage, options?: OptionsObject);
    addNotification(message: SnackbarMessage, title?: string | OptionsObject, options?: OptionsObject) {
        const settings = typeof title === 'object' ? title : options;
        const hasSpecifiedKey = settings && (settings.key || settings.key === 0);
        const key = hasSpecifiedKey ? (settings.key as SnackbarKey) : new Date().getTime() + Math.random();
        const notification = {
            title: typeof title === 'string' ? title : undefined,
            message,
            options: {
                key,
                ...settings
            }
        };
        this.notifications.push(notification);
    }

    @action removeNotification(notification: Notification) {
        this.notifications.remove(notification);
    }

    error(message: SnackbarMessage, title?: string, options?: OptionsObject)
    error(message: SnackbarMessage, options?: OptionsObject);
    @action error(message: SnackbarMessage, title: string | OptionsObject, options?: OptionsObject) {
        const heading = typeof title === 'string' ? title : undefined;
        const settings = typeof title === 'object' ? title : options;
        this.addNotification(message, heading, {variant: 'error', ...settings});
    }

    warning(message: SnackbarMessage, title?: string, options?: OptionsObject)
    warning(message: SnackbarMessage, options?: OptionsObject);
    @action warning(message: SnackbarMessage, title: string | OptionsObject, options?: OptionsObject) {
        const heading = typeof title === 'string' ? title : undefined;
        const settings = typeof title === 'object' ? title : options;
        this.addNotification(message, heading, {variant: 'warning', ...settings});
    }

    info(message: SnackbarMessage, title?: string, options?: OptionsObject)
    info(message: SnackbarMessage, options?: OptionsObject);
    @action info(message: SnackbarMessage, title: string | OptionsObject, options?: OptionsObject) {
        const heading = typeof title === 'string' ? title : undefined;
        const settings = typeof title === 'object' ? title : options;
        this.addNotification(message, heading, {variant: 'info', ...settings});
    }

    success(message: SnackbarMessage, title?: string, options?: OptionsObject)
    success(message: SnackbarMessage, options?: OptionsObject);
    @action success(message: SnackbarMessage, title: string | OptionsObject, options?: OptionsObject) {
        const heading = typeof title === 'string' ? title : undefined;
        const settings = typeof title === 'object' ? title : options;
        this.addNotification(message, heading, {variant: 'success', ...settings});
    }

    @action showError(message: SnackbarMessage, title: string | OptionsObject, options?: OptionsObject) {
        const heading = typeof title === 'string' ? title : undefined;
        const settings = typeof title === 'object' ? title : options;
        this.error(message, heading, {...settings});
    }

}
