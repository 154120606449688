import {Deal} from '@jucy-nasse/types';
import {Box, CircularProgress} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import DealGridForm, {DealGridFormProps} from 'components/DealGridForm/DealGridForm';
import firebase from 'firebase';
import React from 'react';
import {useQuery} from 'react-query';
import {storage} from 'services/firebase';
import firestoreService, {operatorCollection} from 'services/firestoreService';
import {DealDetails, FormProgress} from 'services/forms';
import {fileExists} from 'services/storage';
import * as typesaurus from 'typesaurus';
import {collection} from 'typesaurus';

function uploadImage(storageRef: firebase.storage.Reference, file: File, onProgress?: (snapshot: firebase.storage.UploadTaskSnapshot) => void) {
    return new Promise((resolve, reject) => {
        const uploadTask = storageRef.put(file);
        uploadTask.on('state_changed', onProgress, (error) => reject(error), () => resolve(uploadTask.snapshot.ref));
    });
}

export async function onDealAdded(fields: DealDetails.Fields, operatorId: string, onProgress: (state: FormProgress) => void = () => undefined): Promise<DealDetails.Fields> {
    const operatorDealCollection = collection<Deal>(`operators/${operatorId}/deals`);
    const deal = DealDetails.mapFieldsToDeal({
        ...fields,
        operatorId
    });
    if (fields.image && fields.image.length) {
        const image = fields.image[0];
        const imagePath = image && image.name && `images/operator/${operatorId}/deals/${image.name}`;
        if (imagePath) {
            const dealImageRef = storage.ref().child(imagePath);
            if (!await fileExists(dealImageRef)) {
                await uploadImage(dealImageRef, image, (snapshot => onProgress({status: 'upload', percent: 33, snapshot})));
            }
            deal.imageUrl = `/${imagePath}`;
        }
    }

    if (!deal.address.formatted) {
        const operator = await firestoreService.get(operatorCollection, operatorId);
        deal.address = operator.address;
    }

    if (deal.id) {
        onProgress({status: 'updating', percent: 66});
        deal.dateUpdated = new Date();
        await firestoreService.update(operatorDealCollection, deal.id, deal);
    } else {
        deal.dateCreated = new Date();
        onProgress({status: 'creating', percent: 66});
        const saveResult = await firestoreService.add(operatorDealCollection, deal);
        deal.id = saveResult.id;
    }

    // } catch (e) {
    //     console.error(e);
    // }
    onProgress({status: 'complete', percent: 100});

    return DealDetails.mapDealToFields(deal);
}

export async function onDealRemoved(fields: DealDetails.Fields, operatorId: string): Promise<void> {
    if (fields.id) {
        const doc = await typesaurus.get(collection<Deal>(`operators/${operatorId}/deals`), fields.id);
        if (doc && doc.ref.id) {
            const operations = [];
            if (doc.data.imageUrl) {
                try {
                    const ref = storage.refFromURL(doc.data.imageUrl);
                    if (ref) {
                        operations.push(ref.delete());
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            operations.push(firestoreService.remove(doc.ref));
            await Promise.all(operatorId);
        }
    }
}

export interface OperatorDealGridProps extends Omit<DealGridFormProps, 'deals' | 'onDealAdded' | 'onDealRemoved'> {
    operatorId: string;
}

async function loadOperatorDeals(operatorId: string): Promise<DealDetails.Fields[]> {
    const deals = operatorId ? await firestoreService.all(collection<Deal>(`operators/${operatorId}/deals`))
        .then(deals => Promise.all(deals.map(DealDetails.mapDealToFields)))
        : [];
    return deals;
}

function OperatorDealGrid({operatorId, ...gridProps}: OperatorDealGridProps) {
    const info = useQuery(`operator-deals-${operatorId}`, () => loadOperatorDeals(operatorId), {refetchInterval: false});

    if (!operatorId) {
        return (
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Operator id invalid
            </Alert>
        );
    }

    if (info.status === 'loading') {
        return (
            <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
                <CircularProgress/>
            </Box>
        );
    }

    if (info.status === 'error') {
        return <> <span>Error: {info.error.message}</span></>;
    }

    return <DealGridForm
        deals={info.data}
        onDealAdded={(deal, form, callback, onProgress) => onDealAdded(deal, operatorId, onProgress)}
        onDealRemoved={(deal => onDealRemoved(deal, operatorId))}
        {...gridProps}
    />;

}

export default OperatorDealGrid;
