import {Dialog as MuiDialog, DialogTitle as MuiDialogTitle, IconButton, Tooltip, Typography,} from '@material-ui/core';
import {DialogProps} from '@material-ui/core/Dialog/Dialog';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {Close as CloseIcon,} from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    children: React.ReactNode;
    onClose: (event?: {}, reason?: string) => void;
}

function DialogTitle({onClose, children}: DialogTitleProps) {
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography={true}>
            <Typography variant="h6">{children}</Typography>
            {onClose && (
                <Tooltip title="Close">
                    <IconButton className={classes.closeButton} onClick={() => onClose()}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            )}
        </MuiDialogTitle>
    );
}

export interface TitledDialogProps extends Omit<DialogProps, 'title'> {
    title: React.ReactNode;
}

export default function TitledDialog({title, children, ...props}: TitledDialogProps) {
    return (
        <MuiDialog {...props}>
            <DialogTitle onClose={props.onClose}>{title}</DialogTitle>
            {children}
        </MuiDialog>
    );
}
