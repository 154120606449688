import Image from 'material-ui-image';
import React from 'react';
import {formatDealImageUrl} from 'utils/cdn';

interface DealImageColumnProps {
    imageUrl: string,
}

const DealImageCell: React.FC<DealImageColumnProps> = React.memo(({imageUrl}) => {
    const formattedImageUrl = formatDealImageUrl(imageUrl);
    return formattedImageUrl ? <Image
        aspectRatio={1.777}
        src={formattedImageUrl}
        disableSpinner={true}
        disableTransition={true}
    /> : null;
});
DealImageCell.displayName = 'DealImageCell';

export default DealImageCell;
