import {Checkbox, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useAccountTabStore} from 'components/AccountSettingsModal/store/accountTabStore';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemPotato: {
            padding: theme.spacing(0, 2)
        },
        itemIcon: {
            minWidth: 0,
            padding: 0
        },
        itemCheck: {
            padding: 0,
            marginRight: theme.spacing(1)
        },
        itemText: {}
    }));

function ProfileCompletionCheckListItem({title, completed}: { title: string, completed: boolean }) {
    const classes = useStyles();
    return (
        <ListItem className={classes.itemPotato} role={undefined} dense>
            <ListItemIcon className={classes.itemIcon}>
                <Checkbox
                    className={classes.itemCheck}
                    edge="start"
                    checked={completed}
                    tabIndex={-1}
                    disableRipple
                />
            </ListItemIcon>
            <ListItemText className={classes.itemText} primary={title}/>
        </ListItem>
    );
}

export default function ProfileCompletionCheckList() {
    const store = useAccountTabStore();
    return (
        <List>
            {store.completenessProps.map(p => (
                <ProfileCompletionCheckListItem key={p} title={store.getLabelFor(p)} completed={Boolean(store[p])}/>
            ))}
        </List>
    );
}
