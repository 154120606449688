import {Box, Grid, Hidden, Tooltip, Typography} from '@material-ui/core';
import AvatarEditor from 'components/AccountSettingsModal/components/AvatarEditor';
import ProfileCompletionCheckList from 'components/AccountSettingsModal/components/ProfileCompletionCheckList';
import {useAccountTabStore} from 'components/AccountSettingsModal/store/accountTabStore';
import React from 'react';

export default function AccountTabHeader() {
    const store = useAccountTabStore();
    return (
        <Box mb={2}>
            <Hidden xsDown>
                <Grid alignItems="center" container>
                    <Grid item xs>
                        <Box textAlign="center">
                            <AvatarEditor
                                onRemove={store.removeAvatar}
                                onSave={store.uploadAvatar}
                                onChange={store.handleAvatarChange}
                                disabled={store.performingAction}
                                loading={store.loadingPicture}
                                picture={store.picture}
                                pictureFile={store.pictureFile}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box textAlign="center">
                            <Tooltip title={<ProfileCompletionCheckList/>}>
                                <div>
                                    <Typography variant="body1">Profile completion</Typography>
                                    {store.profileCompletion === 0 && (
                                        <Typography color="error" variant="h5">
                                            {store.profileCompletion}%
                                        </Typography>
                                    )}

                                    {store.profileCompletion === 100 && (
                                        <Typography color="primary" variant="h5">
                                            {store.profileCompletion}%
                                        </Typography>
                                    )}

                                    {store.profileCompletion !== 0 && store.profileCompletion !== 100 && (
                                        <Typography color="secondary" variant="h5">
                                            {store.profileCompletion}%
                                        </Typography>
                                    )}
                                </div>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box textAlign="center">
                            <Typography variant="body1">Security rating</Typography>
                            {store.securityRating === 0 && (
                                <Typography color="error" variant="h5">
                                    {store.securityRating}%
                                </Typography>
                            )}

                            {store.securityRating === 100 && (
                                <Typography color="primary" variant="h5">
                                    {store.securityRating}%
                                </Typography>
                            )}

                            {store.securityRating !== 0 && store.securityRating !== 100 && (
                                <Typography color="secondary" variant="h5">
                                    {store.securityRating}%
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Hidden>
        </Box>
    );
}
