import {Operator} from '@jucy-nasse/types';
import firebase from 'firebase';
import * as typesaurus from 'typesaurus';
import {collection} from 'typesaurus';
import {AddModel} from 'typesaurus/add';
import {Ref} from 'typesaurus/ref';

export function refToModel<Model>(ref: typesaurus.Ref<Model>, data: Model): Model {
    return {
        ...data as Model,
        id: ref.id,
    };
}

export function docToModel<Model>(doc: typesaurus.Doc<Model> | firebase.firestore.QueryDocumentSnapshot): Model {
    return doc ? {
        ...(typeof doc.data === 'function' ? (doc as firebase.firestore.QueryDocumentSnapshot).data() : doc.data) as Model,
        id: doc.ref.id,
    } : null;
}

export const operatorCollection = collection<Operator>('operators');

const firestoreService = {
    add: function add<Model>(collection: typesaurus.Collection<Model>, data: AddModel<Model>): Promise<Model> {
        return typesaurus.add<Model>(collection, data)
            .then(r => refToModel(r, data as Model));
    },
    set: typesaurus.set,
    update: typesaurus.update,
    get: function get<Model>(collection: typesaurus.Collection<Model>, id: string): Promise<Model> {
        return typesaurus.get<Model>(collection, id).then(docToModel);
    },
    all: function all<Model>(collection: typesaurus.Collection<Model>): Promise<Model[]> {
        return typesaurus.all<Model>(collection).then(r => r.map(docToModel));
    },
    query: function query<Model>(collection: typesaurus.Collection<Model> | typesaurus.CollectionGroup<Model>, queries: typesaurus.Query<Model, keyof Model>[]): Promise<Model[]> {
        return typesaurus.query<Model>(collection, queries).then(r => r.map(docToModel));
    },
    remove: function query<Model>(ref: Ref<Model>): Promise<void> {
        return typesaurus.remove<Model>(ref);
    },
};

export default firestoreService;
