import {AppBar, LinearProgress, Link as MuiLink, Toolbar} from '@material-ui/core';
import {AppBarProps} from '@material-ui/core/AppBar/AppBar';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';
import rootStore from 'stores';

const useStyles = makeStyles((theme) => ({
    root: {},
    homeLink: {
        color: theme.palette.secondary.main,
        display: 'flex',
        textDecoration: 'none',
        alignItems: 'center',
        flexGrow: 1,
    },
    logo: {
        marginRight: 5,
        height: 38,

    },
    progressIndicator: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
    }
}));

const AppProgressIndicator = observer(function AppProgressIndicatorComponent() {
    const classes = useStyles();
    return rootStore.appStateStore.hasBackgroundAction ? <LinearProgress className={classes.progressIndicator} color="secondary"/> : null;
});

interface TopbarProps extends AppBarProps {
}

const Topbar: React.FunctionComponent<TopbarProps> = ({className, ...rest}) => {

    const classes = useStyles();

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="default"
            position="fixed"
        >
            <Toolbar>
                <Link className={classes.homeLink} title={`${process.env.REACT_APP_TITLE} v${process.env.REACT_APP_VERSION}`} to="/">
                    <img
                        className={classes.logo}
                        src="https://www.jucy.com/_resources/themes/jucy/images/logo.svg?m=1584570131"
                        alt={`${process.env.REACT_APP_TITLE} v${process.env.REACT_APP_VERSION}`}/>
                </Link>
                <MuiLink
                    variant="subtitle1"
                    href="https://www.jucy.com/"
                >
                    Back to JUCY website
                </MuiLink>
            </Toolbar>
            <AppProgressIndicator/>
        </AppBar>
    );
};

export default Topbar;
