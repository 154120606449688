import ContentConfig from 'services/config/ContentConfig';
import RentalsAPIConfig from 'services/config/RentalsAPIConfig';
import {Property} from 'ts-convict';
import AppConfig from './AppConfig';
import Auth0Config from './Aut0Config';
import FirebaseConfig from './FirebaseConfig';
import SentryConfig from './SentryConfig';

export class MainConfig implements config.MainConfig {
    @Property(AppConfig)
    app: AppConfig;

    @Property(SentryConfig)
    sentry: SentryConfig;

    @Property(FirebaseConfig)
    firebase: FirebaseConfig;

    @Property(Auth0Config)
    auth0: Auth0Config;

    @Property(ContentConfig)
    contentApi: ContentConfig;

    @Property(RentalsAPIConfig)
    rentalsApi: RentalsAPIConfig;

}
