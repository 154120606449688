import {Schema} from 'convict';
import {MainConfig} from 'services/config/MainConfig';
import config from '../config';

export default class DefaultsLayer extends MainConfig implements config.IConfigLayer {
    name: string;
    priority: number;

    schema: Schema<MainConfig>;

    constructor({name, priority = 0}: { name: string, priority: number }) {
        super();
        this.name = name;
        this.priority = priority;
        this.init();
    }

    getValue(name) {
        const schemaItem = this.schema[name];
        return schemaItem && schemaItem;
    }

    private init() {
        Object.assign(this, {...config});
    }
}

