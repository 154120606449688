import {UserProfile} from '@jucy-nasse/types';
import {Avatar, ListItemAvatar} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';

import {AccountCircle as AccountCircleIcon, Person as PersonIcon,} from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        nameInitials: {
            cursor: 'default',
        },
    }));

interface UserAvatarProps {
    context?: 'standalone' | 'list',
    profile: UserProfile;
    defaultCursor?: boolean,
}

function UserAvatar({context = 'standalone', profile, defaultCursor}: UserAvatarProps) {
    // Styling
    const classes = useStyles();

    // Properties
    if (context === 'standalone') {
        if (!profile) {
            return <AccountCircleIcon/>;
        }

        const photoUrl = profile.picture;

        if (photoUrl) {
            return <Avatar alt="Avatar" src={photoUrl}/>;
        }

        if (profile.initials) {
            return (
                <Avatar alt="Avatar">
                    <span className={defaultCursor && classes.nameInitials}>
                        {profile.initials}
                    </span>
                </Avatar>
            );
        }

        return <AccountCircleIcon/>;
    }

    if (context === 'list') {
        if (!profile) {
            return (
                <ListItemAvatar>
                    <Avatar>
                        <PersonIcon/>
                    </Avatar>
                </ListItemAvatar>
            );
        }

        const photoUrl = profile.picture;

        if (photoUrl) {
            return (
                <ListItemAvatar>
                    <Avatar alt="Avatar" src={photoUrl}/>
                </ListItemAvatar>
            );
        }

        if (profile.initials) {
            return (
                <ListItemAvatar>
                    <Avatar alt="Avatar">
                        <span className={defaultCursor && classes.nameInitials}>
                            {profile.initials}
                        </span>
                    </Avatar>
                </ListItemAvatar>
            );
        }

        return (
            <ListItemAvatar>
                <Avatar>
                    <PersonIcon/>
                </Avatar>
            </ListItemAvatar>
        );
    }

    return null;
}

export default UserAvatar;
