import {AlertContainer} from 'components/Notifier/components';
import {autorun} from 'mobx';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import React from 'react';
import rootStore from 'stores';

const Notifier: React.FunctionComponent = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    React.useEffect(() => {
        autorun(() => {
            rootStore.notificationStore.notifications.forEach((notification) => {
                if (rootStore.notificationStore.displayedNotifications.includes(notification.options.key)) return;
                if ((notification.options.variant && notification.options.variant !== 'default') && !notification.options.content) {
                    notification.options.content = <AlertContainer title={notification.title} message={notification.message} variant={notification.options.variant}/>;
                }
                enqueueSnackbar(notification.message, notification.options);
                rootStore.notificationStore.displayedNotifications.push(notification.options.key);
                rootStore.notificationStore.removeNotification(notification);
            });
        });
    }, [enqueueSnackbar]);

    return null;
});

export default Notifier;
