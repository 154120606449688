import {Card, Fab} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import React, {MouseEventHandler} from 'react';

const useStyles = makeStyles((theme) => ({
    placeHolder: {
        // width: 380,
        minHeight: 320,
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

interface DealPlaceHolderCardProps {
    onClick?: MouseEventHandler<HTMLButtonElement>
    className?: string
}

export default function DealPlaceHolderCard({onClick, className}: DealPlaceHolderCardProps) {
    const classes = useStyles();
    return (
        <Card className={clsx(classes.placeHolder, className)}>
            <Fab color="secondary" variant="extended" onClick={onClick}>
                <AddIcon className={classes.extendedIcon}/>
                Add Deal
            </Fab>
        </Card>
    );
}
