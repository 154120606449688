import {createStyles, makeStyles} from '@material-ui/core/styles';
import {Alert, AlertTitle, Color} from '@material-ui/lab';
import {SharedProps, SnackbarMessage, VariantType} from 'notistack';
import React from 'react';

const useStyles = makeStyles(() => createStyles({
    root: {minWidth: '100%'},
}), {name: 'AlertContainer'});

function VariantToColor(variant: VariantType): Color | undefined {
    switch (variant) {
        case 'default':
            return undefined;
        default:
            return variant;
    }
}

interface AlertContainerProps extends Pick<SharedProps, 'variant'> {
    title?: string
    message: SnackbarMessage
}

const AlertContainer = React.forwardRef<HTMLDivElement, AlertContainerProps>(({title, message, variant}, ref) => {
    const color = VariantToColor(variant);
    const classes = useStyles();
    return (
        <div ref={ref} className={classes.root}>
            <Alert variant={'filled'} severity={color}>
                {title && (<AlertTitle key={Date.now()}>{title}</AlertTitle>)}
                {message}
            </Alert>
        </div>
    );
});

export default AlertContainer;
