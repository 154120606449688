import {Box, Button, CircularProgress, Divider, Hidden, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip,} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
    AccessTime as AccessTimeIcon, Check as CheckIcon, Edit as EditIcon, Email as EmailIcon, Person as PersonIcon, PersonOutline as PersonOutlineIcon, Save as SaveIcon, Warning as WarningIcon
} from '@material-ui/icons';
import clsx from 'clsx';
import {useAccountTabStore} from 'components/AccountSettingsModal/store/accountTabStore';
import {Observer} from 'mobx-react-lite';
import moment from 'moment';
import {TextField} from 'mui-rff';
import {TEXT_FIELD_TYPE} from 'mui-rff/dist/TextField';
import React from 'react';
import {FormSpy} from 'react-final-form';
import {Profile} from 'services/forms';

const useStyles = makeStyles((theme: Theme) => createStyles({
    buttonSuccess: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
    },
    buttonWithProgress: {
        // position: 'relative',
        // zIndex: 1,
    },
    buttonProgress: {
        zIndex: 1,
        position: 'absolute',
        display: 'flex',
    },
    inputAdornment: {
        position: 'relative'
    }

}));

interface AccountTabFieldProps {
    icon: React.ReactNode;
    name: string;
    type?: TEXT_FIELD_TYPE;
    autoComplete?: string
    warnings?: React.ReactNode;
    secondaryActions?: React.ReactNode;
}

function AccountTabField({icon, name, type = 'text', autoComplete, warnings, secondaryActions}: AccountTabFieldProps) {
    const classes = useStyles();
    const store = useAccountTabStore();
    const label = store.getLabelFor(name);
    if (!warnings && !store.has(name)) {
        warnings = (
            <Tooltip title={`No ${label.toLowerCase()}`}>
                <WarningIcon color="error"/>
            </Tooltip>
        );
    }
    return <Observer>{() => (
        <ListItem>
            <Hidden xsDown>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
            </Hidden>
            {store.showingField !== name && warnings && (
                <ListItemIcon>
                    {warnings}
                </ListItemIcon>
            )}
            {store.showingField === name && (
                <TextField
                    fullWidth={true}
                    variant={'outlined'}
                    margin={'normal'}
                    disabled={store.performingAction}
                    autoFocus={true}
                    onBlur={store.onFieldBlur}
                    onKeyDown={store.handleKeyDown}
                    helperText={
                        store.errors[name]
                            ? store.errors[name][0]
                            : `Press Enter to change your ${label.toLowerCase()}`
                    }
                    label={label}
                    required={Profile.required[name]}
                    name={name}
                    type={type}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <FormSpy subscription={{values: true}}>
                                    {({values}) => (
                                        <IconButton
                                            id="save-field-button"
                                            aria-label="save changes"
                                            className={
                                                clsx(classes.buttonWithProgress, {[classes.buttonSuccess]: store.showSuccess})
                                            }
                                            onClick={() => {
                                                store.onFieldChanged(name, values[name]);
                                            }}
                                        >
                                            {store.showSuccess ? (
                                                <CheckIcon/>
                                            ) : (
                                                <SaveIcon/>
                                            )}
                                        </IconButton>
                                    )}
                                </FormSpy>
                                {store.performingAction && (
                                    <div className={classes.buttonProgress}>
                                        <CircularProgress size={48} thickness={2}/>
                                    </div>
                                )}
                            </InputAdornment>
                        )
                    }}
                    inputProps={{
                        autoComplete: autoComplete
                    }}
                />
            )}

            {store.showingField !== name && (
                <>
                    <ListItemText
                        primary={label}
                        secondary={
                            store.has(name)
                                ? store[name]
                                : `You don’t have a ${label.toLowerCase()} set`
                        }
                    />
                    {secondaryActions && (
                        <Box clone mr={7}>
                            <ListItemSecondaryAction>
                                {secondaryActions}
                            </ListItemSecondaryAction>
                        </Box>
                    )}
                    <ListItemSecondaryAction>
                        {store.has(name) && (
                            <Tooltip title={`Edit ${label.toLowerCase()}`}>
                                <div>
                                    <IconButton
                                        disabled={store.performingAction}
                                        onClick={() => store.showField(name)}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        )}

                        {!store.has(name) && (
                            <Button
                                color="primary"
                                disabled={store.performingAction}
                                variant="contained"
                                onClick={() => store.showField(name)}
                            >
                                Add
                            </Button>
                        )}
                    </ListItemSecondaryAction>
                </>
            )}
        </ListItem>
    )}</Observer>;
}

function AccountTabFields() {
    const store = useAccountTabStore();

    const verifyEmail = () => {
        console.log('incomplete');
    };

    return <Observer>{() => (
        <List disablePadding>
            <AccountTabField icon={<PersonIcon/>} name="firstName" autoComplete="given-name"/>
            <AccountTabField icon={<PersonIcon/>} name="lastName" autoComplete="family-name"/>
            <AccountTabField icon={<PersonOutlineIcon/>} name="nickname" autoComplete="nickname"/>
            <AccountTabField
                icon={<EmailIcon/>}
                name="email"
                autoComplete="email"
                type="email"
                warnings={<>
                    {store.email && (<>
                        {store.emailVerified && (
                            <Tooltip title="Verified">
                                <CheckIcon color="primary"/>
                            </Tooltip>
                        )}

                        {!store.emailVerified && (
                            <Tooltip title="Not verified">
                                <WarningIcon color="error"/>
                            </Tooltip>
                        )}
                    </>)}

                    {!store.email && (
                        <ListItemIcon>
                            <Tooltip title="No e-mail address">
                                <WarningIcon color="error"/>
                            </Tooltip>
                        </ListItemIcon>
                    )}
                </>}
                secondaryActions={<>
                    <Tooltip title="Verify">
                        <div>
                            <IconButton
                                color="secondary"
                                disabled={store.performingAction || store.sentVerificationEmail}
                                onClick={verifyEmail}
                            >
                                <CheckIcon/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </>}
            />
            <ListItem>
                <Box mt={1} mb={1}>
                    <Divider light/>
                </Box>
            </ListItem>
            <ListItem>
                <Hidden xsDown>
                    <ListItemIcon>
                        <AccessTimeIcon/>
                    </ListItemIcon>
                </Hidden>

                <Hidden xsDown>
                    <ListItemText
                        primary="Last signed in"
                        secondary={moment(store.lastSignIn).format('LLLL')}
                    />
                </Hidden>

                <Hidden smUp>
                    <ListItemText
                        primary="Last signed in"
                        secondary={moment(store.lastSignIn).format('llll')}
                    />
                </Hidden>
            </ListItem>


            {/*<ListItem>*/}
            {/*    <Hidden xsDown>*/}
            {/*        <ListItemIcon>*/}
            {/*            <DeleteForeverIcon/>*/}
            {/*        </ListItemIcon>*/}
            {/*    </Hidden>*/}

            {/*    <ListItemText*/}
            {/*        primary="Delete account"*/}
            {/*        secondary="Accounts can’t be recovered"*/}
            {/*    />*/}

            {/*    <ListItemSecondaryAction>*/}
            {/*        <Button*/}
            {/*            color="secondary"*/}
            {/*            disabled={state.performingAction}*/}
            {/*            variant="contained"*/}
            {/*            onClick={() => alert('Currently disabled')}*/}
            {/*        >*/}
            {/*            Delete*/}
            {/*        </Button>*/}
            {/*    </ListItemSecondaryAction>*/}
            {/*</ListItem>*/}
        </List>
    )}</Observer>;
}

export default AccountTabFields;
