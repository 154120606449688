import {Property} from 'ts-convict';

export default class SentryConfig implements config.SentryConfig {
    @Property({
        default: '',
        format: String,
        env: 'SENTRY_DSN'
    })
    dsn: string;

}
