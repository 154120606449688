import {Button, CircularProgress, Collapse, DialogActions, DialogContent,} from '@material-ui/core';
import {DialogProps} from '@material-ui/core/Dialog/Dialog';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import Alert from '@material-ui/lab/Alert';
import DealDetailForm from 'components/DealDetailForm';
import {DealDetailProgressState} from 'components/DealDetailForm/components/DealDetailProgress';
import TitledDialog from 'components/TitledDialog';
import {FormApi, SubmissionErrors} from 'final-form';
import React, {useEffect} from 'react';
import {FormSpy} from 'react-final-form';
import {DealDetails, FormProgress} from 'services/forms';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actions: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        notification: {
            position: 'absolute',
            right: theme.spacing(3),
            left: theme.spacing(1),
            marginTop: theme.spacing(1),
            top: '100%',
            zIndex: theme.zIndex.snackbar,
            boxShadow: theme.shadows[3],
            borderRadius: theme.shape.borderRadius
        },
    }),
);

const formId = 'deal-detail-form';

function submitDealDetailForm() {
    document
        .getElementById(formId)
        .dispatchEvent(new Event('submit', {cancelable: true}));
}

export interface EditDealModalPropsSimple extends Omit<DialogProps, 'onSubmit' | 'onClose'> {
    onSubmit: (
        values: DealDetails.Fields,
        form: FormApi<DealDetails.Fields>,
        callback?: (errors?: SubmissionErrors) => void,
        onProgress?: (state: FormProgress) => void,
    ) => Promise<SubmissionErrors | DealDetails.Fields>;
    deal?: DealDetails.Fields;
    onClose: () => void;
}

export interface RenderActionsProps extends EditDealModalPropsSimple {
    submitting: boolean,
    dirty: boolean,
    success: boolean,
    submit: () => void,
    btnEndIcons: React.ReactNode,
    deal: DealDetails.Fields
}

export interface EditDealModalProps extends EditDealModalPropsSimple {
    renderActions?: (props: RenderActionsProps) => React.ReactElement
    progressProps?: {
        calculateProgress?: (progress: FormProgress) => DealDetailProgressState
        renderProgressItems?: (state: DealDetailProgressState, progress: FormProgress, children: React.ReactElement) => React.ReactNode
    }
}

export default function EditDealModal({onSubmit, deal, renderActions, progressProps, ...props}: EditDealModalProps) {
    const classes = useStyles();
    const [currentDeal, setCurrentDeal] = React.useState(deal);
    const [errors, setErrors] = React.useState<SubmissionErrors>();
    const [success, setSuccess] = React.useState(false);
    const [dirty, setDirty] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [btnEndIcons, setBtnEndIcons] = React.useState(null);
    const reset = React.useCallback(() => {
        setShowNotification(false);
        setBtnEndIcons(null);
        setSubmitting(false);
        setSuccess(false);
    }, []);
    useEffect(() => {
        setCurrentDeal(deal);
    }, [deal]);

    useEffect(() => {
        if (!props.open) {
            setCurrentDeal(null);
        }
        reset();
    }, [props.open, reset]);
    return (
        <TitledDialog title={currentDeal && currentDeal.id ? 'Edit deal' : 'Create deal'} {...props}>
            <Collapse in={showNotification} className={classes.notification}>
                <Alert onClose={() => setShowNotification(false)} severity={success ? 'success' : 'error'}>
                    {errors && (<>
                        <strong>Opps! We were unable to submit your request.</strong>
                        {Object.entries(errors).map(([k, v]) => <p key={k}>{v}</p>)}
                    </>)}
                </Alert>
            </Collapse>
            <DialogContent dividers={true}>
                {props.open && (
                    <DealDetailForm
                        onSubmit={async (values, form, callback, onProgress) => {
                            setSubmitting(true);
                            setBtnEndIcons(<CircularProgress size={20}/>);
                            try {
                                const result: any = await onSubmit(values, form, callback, onProgress);
                                if ('title' in result) {
                                    setCurrentDeal(result as DealDetails.Fields);
                                } else if (result) {
                                    return result;
                                }
                                setSuccess(true);
                                setErrors(null);
                                setBtnEndIcons(<CheckIcon/>);
                                setTimeout(() => props.onClose(), 700);
                            } catch (e) {
                                setSuccess(false);
                                setShowNotification(true);
                                setErrors([e.message]);
                                setBtnEndIcons(null);
                                throw e;
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                        deal={currentDeal}
                        dense={true}
                        formId={formId}
                        hideActions={true}
                        progressProps={progressProps}
                    >
                        <FormSpy
                            subscription={{dirtySinceLastSubmit: true, dirty: true, modifiedSinceLastSubmit: true, modified: true}}
                            onChange={({dirty: formDirty}) => {
                                if (formDirty) {
                                    reset();
                                }
                                if (formDirty !== dirty) {
                                    setDirty(formDirty);
                                }
                            }}
                        />
                    </DealDetailForm>
                )}
            </DialogContent>
            <DialogActions className={classes.actions}>
                {renderActions ?
                    renderActions({
                        onSubmit,
                        deal,
                        submitting,
                        dirty,
                        success,
                        submit: submitDealDetailForm,
                        btnEndIcons,
                        ...props
                    })
                    : (
                        <>
                            <Button onClick={props.onClose} color="default" disabled={submitting}>
                                {success && !dirty ? 'Close' : 'Cancel'}
                            </Button>
                            <Button color={success ? 'secondary' : 'primary'} type="submit" onClick={submitDealDetailForm} endIcon={btnEndIcons} disabled={!dirty || submitting}>
                                {currentDeal && currentDeal.id ? 'Update' : 'Save'}{success && !dirty && 'd'}
                            </Button>
                        </>
                    )}
            </DialogActions>
        </TitledDialog>
    );
}
