import React, {PropsWithChildren, useEffect} from 'react';
import {createStore, DealsDashboardStore} from './createStore';

const StoreContext = React.createContext<DealsDashboardStore | null>(null);

interface DealDashboardStoreProviderProvider extends PropsWithChildren<{ operatorId: string }> {

}

export const DealDashboardStoreProvider = ({operatorId, children}: DealDashboardStoreProviderProvider) => {
    const store = React.useMemo(() => createStore(operatorId), [operatorId]);
    useEffect(() => {
        if (operatorId && store.operatorId !== operatorId) {
            store.setOperatorId(operatorId);
        }
    }, [operatorId, store]);
    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

export const useDealDashboardStore = () => {
    const store = React.useContext(StoreContext);
    return store;
};
