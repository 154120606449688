import {Button, ButtonGroup, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewListIcon from '@material-ui/icons/ViewList';
import {action} from 'mobx';
import {Observer} from 'mobx-react-lite';
import React from 'react';
import {useDealDashboardStore} from 'views/Dashboard/Components/DealsDashboard/store/operatorStore';

const useStyles = makeStyles(() => ({
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    viewMode: {
        marginLeft: 'auto'
    },

}));

const TableTitle: React.FC = () => {
    const classes = useStyles();
    const store = useDealDashboardStore();
    return <Observer>{() => (<>
        <Typography variant="h6" className={classes.title}>
            Deals
        </Typography>
        <div className={classes.viewMode}>
            <ButtonGroup variant="contained" size="small">
                <Button color={store.view === 'grid' ? 'primary' : 'default'} variant={store.view === 'grid' ? 'contained' : 'outlined'} aria-label="Grid view"
                    onClick={action(() => store.view = 'grid')}>
                    <ViewComfyIcon/>
                </Button>
                <Button color={store.view === 'list' ? 'primary' : 'default'} variant={store.view === 'list' ? 'contained' : 'outlined'} aria-label="List view"
                    onClick={action(() => store.view = 'list')}>
                    <ViewListIcon/>
                </Button>
            </ButtonGroup>
        </div>
    </>)}
    </Observer>;
};

export default TableTitle;
