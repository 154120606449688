import {Button, Icon, IconButton, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {SvgIconComponent} from '@material-ui/icons';
import {Action} from 'material-table';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
}));

interface TableActionProps<RowData extends object> {
    action?: Action<RowData> | ((rowData: RowData) => Action<RowData>);
    data?: any | any[]
    disabled?: boolean;
    size?: 'small' | 'medium'
}

function TableAction<RowData extends object>({action, data, disabled: isDisabled, size}: TableActionProps<RowData>) {
    const classes = useStyles();
    let rowAction: Action<RowData>;
    if (typeof action === 'function') {
        rowAction = action(data);
        if (!rowAction) {
            return null;
        }
    } else if (typeof (action as any).action === 'function') {
        rowAction = (action as any).action(data);
        if (!rowAction) {
            return null;
        }
    } else {
        rowAction = action;
    }

    if (rowAction.hidden) {
        return null;
    }

    const disabled = rowAction.disabled || isDisabled;

    const handleOnClick = (event) => {
        if (rowAction.onClick) {
            rowAction.onClick(event, data);
            event.stopPropagation();
        }
    };

    let icon: React.ReactNode;

    if (typeof rowAction.icon === 'string') {
        icon = <Icon {...rowAction.iconProps}>{rowAction.icon}</Icon>;
    } else if (typeof rowAction.icon === 'function') {
        icon = rowAction.icon({...rowAction.iconProps, disabled: disabled} as any);
    } else {
        const IconComponent: SvgIconComponent = rowAction.icon as SvgIconComponent;
        icon = <IconComponent/>;
    }
    const button = (rowAction.tooltip === 'Add deal') ? (
        <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={icon}
            className={classes.button}
            disabled={disabled}
            onClick={handleOnClick}
        >
            {rowAction.tooltip}
        </Button>
    ) : (
        <IconButton
            size={size}
            color="secondary"
            disabled={disabled}
            onClick={handleOnClick}
        >
            {icon}
        </IconButton>
    );

    if (rowAction.tooltip) {
        // fix for issue #1049
        // https://github.com/mbrn/material-table/issues/1049
        return disabled ? (
            <Tooltip title={rowAction.tooltip}>
                <span>{button}</span>
            </Tooltip>
        ) : (
            <Tooltip title={rowAction.tooltip}>{button}</Tooltip>
        );
    } else {
        return button;
    }
}

export default TableAction;
