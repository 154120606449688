import {makeStyles} from '@material-ui/core/styles';
import DealGridForm from 'components/DealGridForm';
import {onDealRemoved} from 'components/DealGridForm/OperatorDealGrid';
import {toJS} from 'mobx';
import {useObserver} from 'mobx-react-lite';
import React from 'react';
import EditDealActionButtons from 'views/Dashboard/Components/DealsDashboard/Components/EditDealActionButtons';
import {useDealDashboardStore} from 'views/Dashboard/Components/DealsDashboard/store/operatorStore';

const useStyles = makeStyles(() => ({
    gridForm: {
        width: 'auto'
    },
    dealInfoCard: {
        maxWidth: '100%'
    },
    dealGridItem: {
        maxWidth: 360
    },
}));

export interface TableGridBody {

}

export const TableGridBody: React.FC = React.memo((props: any) => {
    const classes = useStyles();
    const store = useDealDashboardStore();
    return useObserver(() => (<tbody>
        <tr>
            <td>
                <DealGridForm
                    className={classes.gridForm}
                    deals={toJS(props.renderData)}
                    onDealAdded={store.onDealSaved}
                    onDealRemoved={(deal => onDealRemoved(deal, store.operatorId))}
                    onSubmit={() => undefined}
                    dealInfoCardProps={{className: classes.dealInfoCard}}
                    gridItemProps={{className: classes.dealGridItem}}
                    renderActions={({onClose, submitting, success, dirty, submit, btnEndIcons,}) => (
                        <EditDealActionButtons submitting={submitting} dirty={dirty} success={success} submit={submit} btnEndIcons={btnEndIcons} onClose={onClose}/>
                    )}
                    progressProps={{
                        calculateProgress: (progress) => store.calculateSubmitProgress(progress, store),
                        renderProgressItems: store.renderProgressItems
                    }}
                />
            </td>
        </tr>
    </tbody>));
});
TableGridBody.displayName = 'TableGridBody';

export default TableGridBody;
