import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {Auth0SignInHandler, SetPasswordPage, SignInPageAuth0} from 'views/SignIn/components';

const SignInPage = () => {
    let {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <SignInPageAuth0/>
            </Route>
            <Route path={`${path}/auth0/callback`}>
                <Auth0SignInHandler/>
            </Route>
            <Route path={`${path}/auth0/passwordless`}>
                <Auth0SignInHandler/>
            </Route>
            <Route path={`${path}/set-password`}>
                <SetPasswordPage/>
            </Route>
        </Switch>
    );
};

export default SignInPage;
