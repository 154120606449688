import firebase from 'firebase';
import {observable, runInAction} from 'mobx';
import {auth} from 'services/firebase';
import RootStore from './RootStore';

export default class FirebaseAuthStore {
    onAuthStateChangedObserver: firebase.Unsubscribe;
    userDocumentSnapshotListener?: () => void;
    @observable isLoading = true;
    @observable isAuthenticating = true;
    rootStore: RootStore;
    private userMetadataRef: firebase.database.Reference | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.onAuthStateChangedObserver = auth.onAuthStateChanged(this.onAuthStateChanged);
    }

    private userMetadataCallback: (snapshot: firebase.database.DataSnapshot) => void | Promise<void> = () => undefined;
    private onAuthStateChanged = async (user: firebase.User | null) => {
        if (this.userMetadataCallback && this.userMetadataRef) {
            this.userMetadataRef.off('value', this.userMetadataCallback);
        }
        // The user is not signed in or doesn’t have a user ID.
        if (!user || !user.uid) {
            // this.reset();
            if (this.userDocumentSnapshotListener) {
                this.userDocumentSnapshotListener();
            }
            this.isLoading = false;
            return;
        }
        if (!this.rootStore.authStore.isLoading && !this.rootStore.authStore.isSigningIn && !this.rootStore.authStore.isAuthenticated) {
            await auth.signOut();
        }
        runInAction(() => {
            this.isAuthenticating = true;
        });

        this.userMetadataRef = firebase.database().ref(`metadata/${user.uid}/refreshTime`);
        this.userMetadataCallback = async () => {
            if (this.rootStore.authStore.credentials) {
                // Force refresh to pick up the latest custom claims changes.
                // Note this is always triggered on first call. Further optimization could be
                // added to avoid the initial trigger when the token is issued and already contains
                // the latest claims.
                runInAction(() => this.isAuthenticating = true);
                try {
                    await user.getIdToken(true);
                    this.rootStore.authStore.credentials.claims = await user.getIdTokenResult().then(r => r.claims);
                } catch (e) {
                    console.error('Unable to refresh idTokenResult', `metadata/${user.uid}/refreshTime`, e.message);
                }
                runInAction(() => this.isAuthenticating = true);
            }
        };
        // Subscribe new listener to changes on that node.
        this.userMetadataRef.on('value', this.userMetadataCallback);

        runInAction(() => {
            this.isLoading = false;
            this.isAuthenticating = true;
        });
    };

}
