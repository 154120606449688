import { UserProfile } from '@jucy-nasse/types';
import { merge } from 'lodash';
import config from 'services/config';
import { functions } from 'services/firebase';
import urlJoin from 'url-join';

const operatorSignUpComplete = functions.httpsCallable('operatorSignUpComplete');
const userExists = functions.httpsCallable('userExists');
const sendPublishRequest = functions.httpsCallable('sendPublishRequest');

export class CloudFunctions {
    apiBaseUrl = urlJoin(config.app.homePage, 'api');

    async userExists(email: string): Promise<boolean> {
        const result = await userExists({ email });
        return result.data.exists;
    }

    async operatorSignupComplete(operatorId: string): Promise<{}> {
        const result = await operatorSignUpComplete({ operatorId });
        return result.data;
    }

    getProfile(idToken: string) {
        return this.tokenRequest(idToken, 'profile');
    }

    updateProfile(idToken: string, data: Partial<UserProfile> | { password?: string, confirmPassword?: string }) {
        return this.tokenRequest(idToken, 'profile', {
            method: 'PATCH',
            body: JSON.stringify({ data }),
        });
    }

    async createToken(idToken: string): Promise<string> {
        const result = await this.tokenRequest(idToken, 'token');
        return (result.status === 200) ? (await result.json()).firebaseToken : null;
    }

    tokenRequest(idToken: string, path: string, options?: RequestInit) {
        let defaultsOptions = {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        };
        const requestOptions = merge(defaultsOptions, options);
        const url = (config.firebase.functionEmulator) ? `http://${config.firebase.functionEmulator}/jucy-nasse-dev/us-central1/createToken` : urlJoin(this.apiBaseUrl, path);
        return fetch(url, requestOptions);
    }

    async sendPublishRequest(operatorId: string, dealId: string): Promise<{}> {
        const result = await sendPublishRequest({ operatorId, dealId });
        return result.data;
    }
}

const cloudFunctions = new CloudFunctions();
export default cloudFunctions;
