import {CircularProgress, CircularProgressProps, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';

interface ButtonCircularProgressProps extends CircularProgressProps {
    isSubmitting?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: theme.spacing(1),
        display: 'flex'
    }
}));

export default function ButtonCircularProgress({isSubmitting = false, ...props}: ButtonCircularProgressProps) {
    const classes = useStyles();
    return isSubmitting ? <span className={classes.root}><CircularProgress size={20} {...props}/></span> : null;
}
