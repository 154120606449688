export interface Address {
    streetNumber?: string;
    streetAddress?: string;
    street?: string;
    city?: string;
    state?: string;
    postCode?: string;
    country?: string;
}

export default class GmapUtils {

    /**
     *
     * @param {google.maps.places.PlaceResult} place
     * @returns {{country: undefined, streetNumber: undefined, city: undefined, street: undefined, postCode: undefined, state: undefined}}
     */
    static placeToAddress(place: google.maps.places.PlaceResult): Address {
        const result: Address = {
            streetNumber: undefined,
            streetAddress: undefined,
            street: undefined,
            city: undefined,
            state: undefined,
            postCode: undefined,
            country: undefined,
        };
        if (place && place.address_components) {
            place.address_components.forEach(component => {
                component.types.forEach(type => {
                    if (type === 'street_number') {
                        result.streetNumber = component.long_name;
                    }
                    if (type === 'route') {
                        result.street = component.long_name;
                    }
                    if (type === 'locality') {
                        result.city = component.long_name;
                    }
                    if (type === 'administrative_area_level_1') {
                        result.state = component.long_name;
                    }
                    if (type === 'country') {
                        result.country = component.long_name;
                    }
                    if (type === 'postal_code') {
                        result.postCode = component.long_name;
                    }
                });
                result.streetAddress = `${result.streetNumber ? `${result.streetNumber} ` : ''}${result.street}`;
            });
        }
        return result;
    }

    /**
     *
     * @param {google.maps.places.PlaceResult} place
     * @returns {string}
     */
    static formatPlaceAddress(place: google.maps.places.PlaceResult): string {
        const address = place && GmapUtils.placeToAddress(place);
        let result = '';

        if (address) {
            if (address.streetNumber) {
                result += `${address.streetNumber} `;
            }
            if (address.street) {
                result += address.street;
            }
            if (address.city) {
                result += `${result ? ', ' : ''}${address.city}`;
            }
        }
        if (!result) {
            result = place.name;
        }
        return result || '';
    }

}
