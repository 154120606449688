import {Box,} from '@material-ui/core';
import React from 'react';
import {useParams} from 'react-router-dom';
import {DealDashboardStoreProvider} from 'views/Dashboard/Components/DealsDashboard/store/operatorStore';
import {DealDataTable, EditDealModal} from './Components';

const DealsDashboard: React.FC = () => {
    let {operatorId} = useParams();
    return (
        <DealDashboardStoreProvider operatorId={operatorId}>
            <Box padding={0}>
                <DealDataTable/>
                <EditDealModal/>
            </Box>
        </DealDashboardStoreProvider>
    );
};
DealsDashboard.displayName = 'DealsDashboard';
export default DealsDashboard;
