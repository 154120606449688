import React from 'react';
import {AccountSettingsModalTabStore, createSettingsModalTabStore} from './createSettingsModalTabStore';

const StoreContext = React.createContext<AccountSettingsModalTabStore | null>(null);

export interface AccountSettingsModalStoreProviderProps {
    children: React.ReactNode
}

export const AccountSettingsModalStoreProvider = ({children}: AccountSettingsModalStoreProviderProps) => {
    const store = React.useMemo(() => createSettingsModalTabStore(), []);
    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

export const useAccountSettingsModalStore = () => {
    const store = React.useContext(StoreContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useAccountSettingsModalStore must be used within a AccountSettingsModalStoreProvider.');
    }
    return store;
};
