import ConfigStore from 'stores/ConfigStore';
import FirebaseAuthStore from 'stores/FirebaseAuthStore';
import JucyAgentsStore from 'stores/JucyAgentStore';
import NotificationStore from 'stores/NotificationStore';
import AppStateStore from './AppStateStore';
import AuthStore from './AuthStore';
import JucyOperatorsStore from './JucyOperatorsStore';
import RouterStore from './RouterStore';

export default class RootStore {
    configStore: ConfigStore;
    authStore: AuthStore;
    appStateStore: AppStateStore;
    routerStore: RouterStore;
    notificationStore: NotificationStore;
    operatorStore: JucyOperatorsStore;
    agentsStore: JucyAgentsStore;
    firebaseAuthStore: FirebaseAuthStore;

    constructor() {
        this.configStore = new ConfigStore(this);
        this.authStore = new AuthStore(this);
        this.notificationStore = new NotificationStore(this);
        this.appStateStore = new AppStateStore(this);
        this.routerStore = new RouterStore(this);
        this.operatorStore = new JucyOperatorsStore(this);
        this.agentsStore = new JucyAgentsStore(this);
        this.firebaseAuthStore = new FirebaseAuthStore(this);

    }
}
