import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import parse from 'autosuggest-highlight/parse';
import Autocomplete from 'components/Autocomplete';
import throttle from 'lodash/throttle';
import {AutocompleteProps} from 'mui-rff';
import React, {ChangeEvent} from 'react';
import {useField} from 'react-final-form';
import {Address} from 'services/forms';
import MapService from 'stores/MapService';

const autocompleteService = {current: null};

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },

}));

interface GoogleMapsAutocompleteProps extends Omit<AutocompleteProps, 'options' | 'getOptionLabel'> {
    name: string;
    label: string;
    requestOptions?: Partial<google.maps.places.AutocompletionRequest>;
}

export default function GoogleAddressAutoComplete({requestOptions, ...props}: GoogleMapsAutocompleteProps) {
    const classes = useStyles();
    const [value, setValue] = React.useState<google.maps.places.AutocompletePrediction | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [lastInputValue, setLastInputValue] = React.useState('');
    const [options, setOptions] = React.useState<google.maps.places.AutocompletePrediction[]>([]);
    const [loading, setLoading] = React.useState(false);
    const field = useField(props.name);
    React.useEffect(() => {
        if (!MapService.autocompleteService && typeof google !== 'undefined' && typeof google.maps !== 'undefined') {
            MapService.init();
        }

    }, []);

    const fetch = React.useMemo(
        () =>
            throttle((request: google.maps.places.AutocompletionRequest, callback: (results?: google.maps.places.AutocompletePrediction[]) => void) => {
                MapService.autocompleteService
                    .getPlacePredictions({...requestOptions, ...request})
                    .then(callback)
                    .catch((e) => {
                        console.error(e);
                        callback();
                    });
            }, 200),
        [requestOptions],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            const emptyOptionValues = value ? [value] : [];
            if (options.length && options[0] !== value) {
                setLoading(false);
                setOptions(emptyOptionValues);
            }
            return undefined;
        }

        if (inputValue === lastInputValue && options.length) {
            return undefined;
        }
        setLoading(true);
        fetch({input: inputValue}, (results?: google.maps.places.AutocompletePrediction[]) => {
            setLastInputValue(inputValue);
            if (active) {
                setLoading(false);
                let newOptions = [] as google.maps.places.AutocompletePrediction[];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, lastInputValue, options]);

    return (
        <Autocomplete
            {...props}
            noOptionsText={inputValue ? 'No locations found' : 'Enter text to search'}
            name={`${props.name}-autocomplete`}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description || option.formatted)}
            filterOptions={(x) => x}
            options={options}
            includeInputInList={true}
            getOptionSelected={(option: google.maps.places.AutocompletePrediction, value: Address.Fields) => {
                const optionPlaceId = option && option.place_id;
                const valuePlaceId = value && value.placeId;
                return optionPlaceId && valuePlaceId && optionPlaceId === valuePlaceId;
            }}
            onBeforeChange={async (_e: ChangeEvent<{}>, prediction: google.maps.places.AutocompletePrediction) => {
                let result = Address.initialValues;
                if (prediction && prediction.place_id) {
                    setLoading(true);
                    const placeResult = await MapService.placesService.getDetailsById(prediction.place_id);
                    result = Address.mapGooglePlaceResult(placeResult);
                    setLoading(false);
                }
                field.input.onChange(result);
                setValue(prediction);
                return result;
            }}
            loading={loading}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}

            renderOption={(option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                );

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon}/>
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                    {part.text}
                                </span>
                            ))}
                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}

        />
    );
}
