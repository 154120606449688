import MomentUtils from '@date-io/moment';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {useLoadScript} from '@react-google-maps/api';
import FatalError from 'components/FatalError';
import Notifier from 'components/Notifier';
import {LaunchScreen} from 'layouts';
import {Observer} from 'mobx-react-lite';
import moment from 'moment';
import {SnackbarProvider} from 'notistack';
import React, {useEffect} from 'react';
import {Router} from 'react-router';
import {createLogger} from 'services/logger';
import rootStore, {RootStore} from 'stores';

const logger = createLogger({name: 'AppStateProvider'});

interface GmapsConnectorProps {
    googleMapsApiKey: string,
    googleMapsApiLibraries: string[],
}

function GmapsConnector({googleMapsApiKey, googleMapsApiLibraries}: GmapsConnectorProps) {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
        libraries: googleMapsApiLibraries
    });
    if (loadError) {
        console.error(loadError);
    }
    useEffect(() => {
        rootStore.appStateStore.googleMapsReady = isLoaded;
    }, [isLoaded]);

    return null;
}

interface AppStateProviderProps {
    rootStore: RootStore,
    googleMapsApiKey?: string,
    googleMapsApiLibraries: string[],
    children: React.ReactNode
}

export default function AppStateProvider({rootStore, googleMapsApiKey, googleMapsApiLibraries, children}: AppStateProviderProps) {
    return (
        <>
            {googleMapsApiKey && <GmapsConnector googleMapsApiKey={googleMapsApiKey} googleMapsApiLibraries={googleMapsApiLibraries}/>}
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'en-nz'}>
                <Router history={rootStore.routerStore.history}>
                    <Observer>{() => (
                        <MuiThemeProvider theme={rootStore.appStateStore.theme}>
                            {logger.debug(`Loading: ${rootStore.appStateStore.ready ? 'false' : 'true'}`) && null}
                            <SnackbarProvider maxSnack={3}>
                                <Notifier/>
                                {rootStore.appStateStore.fatalError ? (
                                    <FatalError title="Whoops! A fatal error has occurred" message={rootStore.appStateStore.fatalError}/>
                                ) : rootStore.appStateStore.ready ? (
                                    children
                                ) : (
                                    <LaunchScreen text="Loading app"/>
                                )}
                            </SnackbarProvider>
                        </MuiThemeProvider>
                    )}</Observer>
                </Router>
            </MuiPickersUtilsProvider>
        </>
    );
}
