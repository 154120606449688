import {AccountSettingsModalTabStore} from 'components/AccountSettingsModal/store/createSettingsModalTabStore';
import React from 'react';
import {AccountTabStore, createAccountTabStore} from './createAccountTabStore';

const StoreContext = React.createContext<AccountTabStore | null>(null);

export interface AccountTabStoreProviderProps {
    settingsStore: AccountSettingsModalTabStore;
    children: React.ReactNode;
}

export const AccountTabStoreProvider = ({settingsStore, children}: AccountTabStoreProviderProps) => {
    const store = React.useMemo(() => createAccountTabStore(settingsStore), [settingsStore]);

    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

export const useAccountTabStore = () => {
    const store = React.useContext(StoreContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useAccountTabStore must be used within a AccountTabStoreProvider.');
    }
    return store;
};
