import {UserProfile} from '@jucy-nasse/types';
import {LinearProgress, Tab, Tabs,} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {AccountCircle as AccountCircleIcon, Security as SecurityIcon,} from '@material-ui/icons';
import AccountTab from 'components/AccountSettingsModal/AccountTab';
import SecurityTab from 'components/AccountSettingsModal/SecurityTab';
import {useAccountSettingsModalStore} from 'components/AccountSettingsModal/store/accountSettingsModalStore';
import {action} from 'mobx';
import {Observer} from 'mobx-react-lite';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles(() => createStyles({
    tabs: {
        display: 'initial',
    },
    progressIndicator: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    }
}));

export interface AccountSettingsTabsProps {
    profile: UserProfile;
}

function AccountSettingsTabs({profile}: AccountSettingsTabsProps) {
    const store = useAccountSettingsModalStore();
    const classes = useStyles();
    return <Observer>{() => (<>
        {store.showProgressIndicator && <LinearProgress className={classes.progressIndicator} color="secondary"/>}
        <Tabs
            classes={{root: classes.tabs}}
            style={{overflow: 'initial', minHeight: 'initial'}}
            indicatorColor="primary"
            textColor="primary"
            value={store.selectedTab}
            variant="fullWidth"
            onChange={action((e, v) => store.selectedTab = v)}
        >
            <Tab icon={<AccountCircleIcon/>} label="Account"/>;
            <Tab icon={<SecurityIcon/>} label="Security"/>;
        </Tabs>
        <SwipeableViews
            index={store.selectedTab}
            onChangeIndex={action((i) => store.selectedTab = i)}
        >
            <AccountTab/>
            <SecurityTab profile={profile}/>
        </SwipeableViews>
    </>)}</Observer>;
}

export default AccountSettingsTabs;
