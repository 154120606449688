import {Divider, Drawer, MenuItem, Select, Typography} from '@material-ui/core';
import {DrawerProps} from '@material-ui/core/Drawer/Drawer';
import {makeStyles} from '@material-ui/core/styles';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import LoupeIcon from '@material-ui/icons/Loupe';
import clsx from 'clsx';
import Page from 'layouts/Dashboard/components/Sidebar/Page';
import {useOperatorStore} from 'layouts/Dashboard/store/operatorStore';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useRouteMatch} from 'react-router';
import {ExtendedTheme} from 'services/appearance';
import rootStore from 'stores';
import {SidebarNav} from './components';

const useStyles = makeStyles((theme: ExtendedTheme) => ({
    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.appBarHeight,
            height: `calc(100% - ${theme.appBarHeight}px)`
        }
    },
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2)
    }
}));

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement>, Pick<DrawerProps, 'onClose' | 'open' | 'variant'> {
}

const Sidebar: React.FunctionComponent<SidebarProps> = observer(({open, variant, onClose, className, ...rest}) => {

    const classes = useStyles();
    let {url} = useRouteMatch();
    // let {path, url} = useRouteMatch();
    const {current, operators, setCurrent} = useOperatorStore();

    const handleOperatorChange = useCallback((e: React.ChangeEvent<{ value: string }>,) => {
        const selectedId = e.target.value;
        const selectedOperator = operators.find(o => o.id === selectedId);
        if (selectedOperator) {
            const {pathname} = rootStore.routerStore.location;
            const newRoute = pathname.replace(current.id, selectedOperator.id);
            if (newRoute !== pathname) {
                rootStore.routerStore.history.replace(newRoute);
            }
            setCurrent(selectedOperator);
        }
    }, [setCurrent, operators, current]);

    const pages: Page[] = [
        // {
        //     title: 'Dashboard',
        //     href: path,
        //     icon: <DashboardIcon/>
        // },

    ];
    if (rootStore.agentsStore.defaultAgentId) {
        pages.push({
            title: 'Agent details',
            href: `${url}/agents/${rootStore.agentsStore.defaultAgentId}`,
            icon: <LoupeIcon/>
        });
    }

    if (operators.length) {
        pages.push({
            title: operators.length > 1 ? 'Companies' : 'Company',
            href: `${url}/operators`,
            icon: <LoupeIcon/>
        });
    }
    if (current && current.id) {
        pages.push({
            title: 'Deals',
            href: `${url}/operators/${current.id}/deals`,
            icon: <LocalActivityIcon/>
        });
    }
    return (
        <Drawer
            anchor="left"
            classes={{paper: classes.drawer}}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                {operators.length > 1 ? (
                    <Select value={current.id} onChange={handleOperatorChange}>
                        {operators.map(o => (
                            <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
                        ))}
                    </Select>
                ) : (
                    current && (<Typography variant="body2" align="center">{current.name}</Typography>)
                )}
                {rootStore.agentsStore.defaultAgentDetails && (<Typography variant="body2" align="center">{rootStore.agentsStore.defaultAgentDetails.Name}</Typography>)}
                <Divider className={classes.divider}/>
                <SidebarNav
                    className={classes.nav}
                    pages={pages}
                />
            </div>
        </Drawer>
    );
});

export default Sidebar;
