import {faThumbsUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography} from '@material-ui/core';
import ContentPage from 'components/ContentPage/ContentPage';
import React from 'react';
import IconTitle from './IconTitle';

export default function ThankYouPage() {
    return <>
        <ContentPage page="/partners/thank-you">
            {(page) => (
                <div className="animated fadeIn">
                    <IconTitle icon={<FontAwesomeIcon icon={faThumbsUp}/>} title={page.title}/>
                    <Typography align="center" variant="body2" component="div" dangerouslySetInnerHTML={{__html: page.content}}/>
                </div>
            )}
        </ContentPage>
    </>;
}
