import { UserProfile } from '@jucy-nasse/types';
import { Auth0Result, Auth0UserProfile, DbSignUpOptions } from 'auth0-js';
import auth0 from 'services/auth0';
import Credentials from 'services/authentication/Credentials';
import cloudFunctions from 'services/CloudFunctions';
import config from 'services/config';
import firebase, { auth } from 'services/firebase';
import { createLogger } from 'services/logger';

const logger = createLogger({ name: 'Authentication' });

class authentication {
    private tokenRenewalTimeout: number;
    private _credentials: Credentials;

    get credentials() {
        return this._credentials;
    }

    set credentials(credentials: Credentials) {
        this._credentials = credentials;
        const { expiresIn } = credentials;
        if (expiresIn > 0) {
            logger.debug(`scheduleRenewal(${expiresIn})`);
            this.tokenRenewalTimeout = window.setTimeout(() => {
                logger.debug(`tokenRenewal (${expiresIn}) tokenRenewal`);
                this.checkSession().then();
            }, expiresIn);
        }
    }

    signupAndAuthorize = ({ email, password, name, firstName, lastName }: {
        email: string; password: string; name?: string, firstName?: string,
        lastName?: string
    }): Promise<Credentials> => {
        if (!email) {
            throw new Error('Missing required field email address');
        }
        if (!password) {
            throw new Error('Missing required field password');
        }
        if (auth.currentUser) {
            throw new Error('Already logged in');
        }
        return new Promise((resolve, reject) => {
            const options = {
                email,
                password,
                scope: 'openid profile email',
                connection: config.auth0.connection,
                userMetadata: { name },
            } as DbSignUpOptions;

            auth0.webAuth.signupAndAuthorize(options, async (err, auth0Result: Auth0Result) => {
                if (err) return reject(err);
                try {
                    this.credentials = await this.setAuth0Result(auth0Result);
                    await this.updateProfile({ nickname: name, firstName, lastName });
                } catch (e) {
                    return reject(e);
                }
                this.credentials.userInfo.nickname = name;
                this.credentials.userInfo.given_name = firstName;
                this.credentials.userInfo.family_name = lastName;
                resolve(this.credentials);
            });
        });
    };

    parseHash(): Promise<Credentials> {
        return new Promise((resolve, reject) => {
            auth0.webAuth.parseHash(async (err, auth0Result) => {
                window.location.hash = '';
                if (err) return reject(err);
                this.credentials = await this.setAuth0Result(auth0Result);
                resolve(this.credentials);
            });
        });
    }

    checkSession(): Promise<Credentials> {
        return new Promise((resolve, reject) => {
            auth0.webAuth.checkSession({
                responseType: 'token id_token',
                usePostMessage: true,
            }, async (err, auth0Result) => {
                if (err) {
                    if (auth.currentUser) {
                        await auth.signOut();
                    }
                    return reject(err);
                }
                this.credentials = await this.setAuth0Result(auth0Result);
                resolve(this.credentials);
            });
        });
    }

    emailExists = async (email: string): Promise<boolean> => {
        let result = false;
        try {
            result = await cloudFunctions.userExists(email);
        } catch (e) {
            console.error(e);
        }
        return result;
    };

    async updateProfile(data: Partial<UserProfile> | { password?: string, confirmPassword?: string }): Promise<UserProfile> {
        let result: UserProfile = null;
        if (this.credentials.auth0Result.idToken) {
            const updateProfileResponse = await cloudFunctions.updateProfile(this.credentials.auth0Result.idToken, data);
            const responseData = await updateProfileResponse.json();
            if (updateProfileResponse.status === 200) {
                result = UserProfile.fromPlain(responseData);
            } else {
                throw Error(responseData.error?.message || responseData.message);
            }
        }
        return result;
    }

    changePassword(data: { password?: string, confirmPassword?: string }): Promise<UserProfile> {
        return this.updateProfile(data);
    }

    async getClaims(forceRefresh?: boolean): Promise<firebase.auth.IdTokenResult | null> {
        let result: firebase.auth.IdTokenResult | null = null;
        if (auth.currentUser) {
            result = await auth.currentUser.getIdTokenResult(forceRefresh);
            logger.debug(`getClaims(${forceRefresh ? 'true' : false})`);
            logger.debug(result);

        } else {
            logger.info('Get claims without user');
        }
        return result;
    }

    getOperators = async (forceRefresh?: boolean): Promise<string[]> => {
        const idTokenResult = await this.getClaims(forceRefresh);
        return idTokenResult?.claims?.operators || [];
    };

    signOut = async (backLink?: string) => {
        await auth.signOut();
        await auth0.webAuth.logout({ returnTo: backLink });
    };

    passwordlessLogin(email: string): Promise<void> {
        return new Promise((resolve, reject) => {
            auth0.webAuth.passwordlessStart({
                email,
                connection: 'email',
                send: 'link',
                authParams: {
                    clientID: config.auth0.clientId,
                    responseType: 'token id_token',
                    redirectUri: `${config.app.homePage}sign-in/auth0/callback?type=passwordless`,
                },
            }, async (err, auth0UserProfile) => {
                if (err) return reject(err);
                resolve(auth0UserProfile);
            });
        });
    }

    async setAuth0Result(auth0Result: Auth0Result): Promise<Credentials> {
        const [token, userInfo] = await Promise.all([
            cloudFunctions.createToken(auth0Result.idToken),
            new Promise<Auth0UserProfile>((resolve, reject) => {
                auth0.authentication.userInfo(auth0Result.accessToken, async (err, auth0UserProfile) => {
                    if (err) return reject(err);
                    resolve(auth0UserProfile);
                });
            }),
        ]);

        const userCredential = token ? await auth.signInWithCustomToken(token) : null;
        if (userCredential && userCredential?.user.email !== userInfo.email) {
            await userCredential.user.updateEmail(userInfo.email);
        }

        const claims = userCredential ? await userCredential.user.getIdTokenResult().then(r => r.claims) : null;
        return new Credentials({ auth0Result, userCredential, userInfo, claims });
    }
}

export default new authentication();
