import {action, computed, observable} from 'mobx';
import {OptionsObject, SnackbarMessage} from 'notistack';
import appearance, {ExtendedTheme} from 'services/appearance';
import {createLogger} from 'services/logger';
import {RootStore} from 'stores';

const logger = createLogger({name: 'AppState'});

type Notification = {
    message: SnackbarMessage, options?: OptionsObject
};

export default class AppStateStore {
    @observable backgroundActionCount = 0;
    @observable googleMapsReady = false;
    @observable performingAction = false;
    @observable profileOpen = false;
    @observable fatalError = '';
    @observable theme: ExtendedTheme = appearance.defaultTheme;
    isDev = process.env.NODE_ENV === 'development';
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @computed get authReady() {
        return !this.rootStore.authStore.isLoading;
    }

    @computed get ready() {
        logger.debug('Ready?');
        logger.debug({authReady: this.authReady, googleMapsReady: this.googleMapsReady});
        return this.authReady && this.googleMapsReady;
    }

    @computed get hasBackgroundAction() {
        return this.backgroundActionCount > 0;
    }

    @action addBackgroundAction() {
        this.backgroundActionCount++;
    }

    @action setFatalError(message: string) {
        this.fatalError = message;
    }

    @action removeBackgroundAction() {
        if (this.backgroundActionCount > 0) {
            this.backgroundActionCount--;
        }
    }

    reset() {
        this.theme = appearance.defaultTheme;
    }
}
