import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, GridProps,} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {DealDetailProgressState} from 'components/DealDetailForm/components/DealDetailProgress';
import {RenderActionsProps} from 'components/DealGridForm/components/EditDealModal';
import DealInfoCard from 'components/DealInfoCard';
import {DealInfoCardProps} from 'components/DealInfoCard/DealInfoCard';
import {FormApi, SubmissionErrors} from 'final-form';
import arrayMutators from 'final-form-arrays';
import React from 'react';
import {Field, Form, FormProps} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {DealDetails, FormProgress} from 'services/forms';
import {rffHelper} from 'utils/index';
import * as Yup from 'yup';
import {DealEditActions, DealPlaceHolderCard, EditDealModal} from './components';

const useFormStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
            marginBottom: theme.spacing(5),
            width: '100%'
        },
        dealGridItem: {
            display: 'flex',
            flexGrow: 1,
            marginBottom: theme.spacing(2),
        },
    }),
);

interface Fields {
    deals: DealDetails.Fields[]
}

const {initialValues, validate} = rffHelper<Fields>({
    initialValues: {
        deals: []
    },
    schema: {
        deals: Yup.array<DealDetails.Fields>().required('At least one deal is required'),
    }
});

export interface DealGridFormProps extends Omit<FormProps<DealDetails.Fields>, 'onSubmit' | 'initialValues' | 'validate' | 'render'> {
    onSubmit: (deals: DealDetails.Fields[]) => void;
    onDealAdded: (
        values: DealDetails.Fields,
        form: FormApi<DealDetails.Fields>,
        callback?: (errors?: SubmissionErrors) => void,
        onProgress?: (state: FormProgress) => void,
    ) => Promise<SubmissionErrors | DealDetails.Fields>;
    onDealRemoved: (deal: DealDetails.Fields) => Promise<void>,
    deals?: DealDetails.Fields[],
    className?: string,
    children?: React.ReactNode;
    dealInfoCardProps?: Omit<DealInfoCardProps, 'deal'>,
    gridItemProps?: GridProps,
    renderActions?: (props: RenderActionsProps) => React.ReactElement,
    progressProps?: {
        calculateProgress?: (progress: FormProgress) => DealDetailProgressState
        renderProgressItems?: (state: DealDetailProgressState, progress: FormProgress, children: React.ReactElement) => React.ReactNode
    }
}

function DealGridForm({onSubmit, deals, onDealAdded, onDealRemoved, children, dealInfoCardProps, className, gridItemProps, renderActions, progressProps, ...props}: DealGridFormProps) {
    const classes = useFormStyles();
    const [dealFormOpen, setDealFormOpen] = React.useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [activeDeal, setActiveDeal] = React.useState<DealDetails.Fields>(null);
    const [activeIndex, setActiveIndex] = React.useState<number | undefined>(undefined);

    function toggleDealFrom() {
        if (dealFormOpen) {
            setActiveDeal(null);
        }
        setDealFormOpen(!dealFormOpen);
    }

    function openDealForm(deal?: DealDetails.Fields, activeIndex?: number) {
        if (!deal) {
            setActiveDeal(null);
            setActiveIndex(null);
        } else {
            setActiveDeal(deal);
            setActiveIndex(activeIndex);
        }
        setDealFormOpen(true);
    }

    function openConfirmDeleteForm(deal?: DealDetails.Fields, activeIndex?: number) {
        if (!deal) {
            setActiveDeal(null);
            setActiveIndex(null);
        } else {
            setActiveDeal(deal);
            setActiveIndex(activeIndex);
        }
        setConfirmDeleteOpen(true);
    }

    function closeConfirmDeleteForm() {
        setConfirmDeleteOpen(false);
        setActiveDeal(null);
        setActiveIndex(null);
    }

    return (
        <Form
            {...props}
            initialValues={{deals: deals || initialValues}}
            onSubmit={onSubmit}
            mutators={{...arrayMutators}}
            validate={validate}
            subscription={{}}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className={clsx(classes.root, className)}>
                    <FieldArray name="deals">
                        {({fields}) => (<>
                            <Grid container={true} spacing={2}>
                                {fields.map((name, index) => (
                                    <Grid key={name} item={true} xs={6} className={classes.dealGridItem} {...gridItemProps}>
                                        <Field name={`${name}`}>
                                            {({input: {value: deal}}) => (
                                                <DealInfoCard
                                                    className="animated zoomIn"
                                                    deal={deal}
                                                    actions={<Box ml="auto">
                                                        <DealEditActions
                                                            index={index}
                                                            deal={deal}
                                                            onEditClick={(deal, index) => {
                                                                openDealForm(deal, index);
                                                            }}
                                                            onDeleteClick={() => {
                                                                openConfirmDeleteForm(deal, index);
                                                            }}
                                                        />
                                                    </Box>}
                                                    {...dealInfoCardProps}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                ))}
                                <Grid item={true} xs={6} className={classes.dealGridItem} {...gridItemProps}>
                                    <DealPlaceHolderCard className={dealInfoCardProps?.className} onClick={() => openDealForm()}/>
                                    <EditDealModal
                                        onSubmit={async (deal, form, callback, onProgress) => {
                                            const updatedDeal = await onDealAdded(deal, form, callback, onProgress);
                                            if (activeIndex === null) {
                                                fields.push(updatedDeal);
                                            } else {
                                                fields.update(activeIndex, updatedDeal);
                                            }
                                            return updatedDeal;
                                        }}
                                        deal={activeDeal}
                                        open={dealFormOpen}
                                        onClose={toggleDealFrom}
                                        renderActions={renderActions}
                                        progressProps={progressProps}
                                    />
                                </Grid>
                            </Grid>
                            <Dialog
                                disableBackdropClick
                                disableEscapeKeyDown
                                maxWidth="xs"
                                aria-labelledby="confirmation-dialog-title"
                                open={confirmDeleteOpen}
                            >
                                {activeDeal && (<>
                                    <DialogTitle id="confirmation-dialog-title">Confirm delete</DialogTitle>
                                    <DialogContent dividers>
                                        Are you sure you want to delete {activeDeal.title}?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button autoFocus onClick={closeConfirmDeleteForm} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={() => {
                                            onDealRemoved(activeDeal).then(() => {
                                                fields.remove(activeIndex);
                                                closeConfirmDeleteForm();
                                            });
                                        }} color="primary">
                                            Ok
                                        </Button>
                                    </DialogActions>
                                </>)}
                            </Dialog>
                        </>)}
                    </FieldArray>
                    {children}


                </form>
            )}
        />
    );
}

export default DealGridForm;
