import {FieldMetaState} from 'react-final-form';

export interface showErrorProps {
    meta: FieldMetaState<any>;
}

export function showError({meta: {submitError, dirtySinceLastSubmit, error, touched, modified, data}}: showErrorProps) {
    return !!(((submitError && !dirtySinceLastSubmit) || error || data.error) && (touched || modified));
}

export function showValid({meta: {valid, touched, modified}}: showErrorProps) {
    return !!((valid) && (touched || modified));
}

