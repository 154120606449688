import {DealDetailFormFields, DealDetailProgress} from 'components/DealDetailForm/components';
import {DealDetailProgressState} from 'components/DealDetailForm/components/DealDetailProgress';
import Form from 'components/Form';
import {FORM_ERROR, FormApi, MutableState, SubmissionErrors} from 'final-form';
import React, {useEffect, useState} from 'react';
import {FormProps} from 'react-final-form';
import {DealDetails, FormProgress} from 'services/forms';

const setError = ([fieldName, error]: [string, string], state: MutableState<DealDetails.Fields>) => {
    const {fields} = state;
    const touched = !!error;
    const field = fields[fieldName];
    field.data = {...field.data};
    field.data.error = error;
    field.touched = touched;
};

const setFieldValue = ([fieldName, value], state, tools) => {
    tools.changeValue(state, fieldName, () => value);
};

export interface DealDetailFormProps extends Omit<FormProps<DealDetails.Fields>, 'onSubmit'> {
    onSubmit: (
        values: DealDetails.Fields,
        form: FormApi<DealDetails.Fields>,
        callback?: (errors?: SubmissionErrors) => void,
        onProgress?: (state: FormProgress) => void,
    ) =>
        | SubmissionErrors
        | Promise<SubmissionErrors | undefined>
        | undefined
        | void
    deal?: DealDetails.Fields,
    dense?: boolean,
    formId?: string,
    hideActions?: boolean,
    progressProps?: {
        calculateProgress?: (progress: FormProgress) => DealDetailProgressState
        renderProgressItems?: (state: DealDetailProgressState, progress: FormProgress, children: React.ReactElement) => React.ReactNode
    }
}

function DealDetailForm({onSubmit, deal, progressProps, ...props}: DealDetailFormProps) {
    const [submissionProgress, setSubmissionProgress] = useState<FormProgress>(null);
    const [showProgress, setShowProgress] = useState(false);
    const [initialValues, setInitialValues] = useState(deal || DealDetails.initialValues);

    useEffect(() => {
        setInitialValues(deal || DealDetails.initialValues);
    }, [deal]);

    return (
        <Form
            mutators={{setError, setFieldValue}}
            onSubmit={async (values, form, callback) => {
                try {
                    setShowProgress(true);
                    return await onSubmit(values, form as FormApi<DealDetails.Fields>, callback, (formProgress: FormProgress) => {
                        setSubmissionProgress(formProgress);
                    });
                } catch (e) {
                    setShowProgress(false);
                    console.error(e);
                    return {[FORM_ERROR]: (e && e.message)};
                }
            }}
            initialValues={initialValues}
            validate={DealDetails.validate}
            subscription={{submitting: true, submitFailed: true, hasValidationErrors: true, submitError: true}}
            render={(form) => {
                return showProgress ? <DealDetailProgress progress={submissionProgress} {...form} {...progressProps}/> : <DealDetailFormFields {...props} {...form}/>;
            }}
        />
    );
}

export default DealDetailForm;
