import {Operator} from '@jucy-nasse/types';
import {action, observable, reaction} from 'mobx';

export class OperatorStore {
    @observable current?: Operator;
    @observable operators: Operator[] = [];
    @observable isFetching;
    @observable error: Error | null = null;

    constructor() {
        reaction(() => this.operators, (r) => {
            if (r && r.length && !this.current) {
                this.setCurrent(r[0]);
            }
        });
    }

    @action setCurrent = (operator: Operator) => {
        this.current = operator;
    };

    refresh: ({force, throwOnError}: { force?: boolean; throwOnError?: boolean }) => Promise<Operator[]> = async () => [];

}

export function createStore() {
    return new OperatorStore();
}

export type TStore = ReturnType<typeof createStore>;
