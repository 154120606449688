import {Property} from 'ts-convict';

export default class AppConfig implements config.AppConfig {
    @Property({
        doc: 'The name of the thing',
        default: null,
        format: Number,
        env: 'REACT_APP_NAME'
    })
    name: string;

    @Property({
        default: null,
        format: String,
        env: 'REACT_APP_VERSION'
    })
    version: string;

    @Property({
        default: null,
        format: String,
        env: 'REACT_APP_DESCRIPTION'
    })
    description: string;

    @Property({
        default: null,
        format: String,
        env: 'REACT_APP_HOMEPAGE'
    })
    homePage: string;

    @Property({
        default: null,
        format: String,
        env: 'REACT_APP_TITLE'
    })
    title: string;

    @Property({
        default: '',
        format: String,
        env: 'REACT_APP_RECAPTCHA_SITE_KEY'
    })
    recaptchaKey?: string;

}
